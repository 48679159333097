import { formatAccountNumber } from "utils/CommonUtils";

export const thiefColumnsData = (t) => [
  {
    Header: t("headers.name"),
    accessor: "name",
    disableSortBy: true,
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.bankName"),
    accessor: "bankName",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: t("headers.bankAccount"),
    accessor: "bankAccount",
    textAlign: "left",
    justifyContent: "flex-start",
    disableSortBy: true,
    Cell: ({ cell }) => formatAccountNumber(cell.value),
  },
];

import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { formatDateWithoutMeridiem } from "utils/DateformatUtils";

import { formatAccountNumber } from "../../../utils/CommonUtils";
import { banks } from "../../bank";
import { StatusBadge } from "../transaction-report/withdrawTransactionReportColumnsData";

export const memberWithdrawTransactionReportColumnsData = (t) => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      Cell: ({ cell }) => <StatusBadge status={cell.value} t={t} />,
    },
    {
      Header: "ยอดถอน",
      accessor: "amount",
    },
    {
      Header: "เข้าบัญชี",
      accessor: "accountNumber",
      Cell: ({ row }) => (
        <Flex alignItems="center">
          <Box mr={2}>
            <Image
              src={`${banks[row.original.bankCode]?.logo}`}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
              padding="5px"
            />
          </Box>
          <Text>{formatAccountNumber(row.original.accountNumber)}</Text>
        </Flex>
      ),
    },
    {
      Header: "วันเวลาทำรายการ",
      accessor: "createdDate",
      Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
    {
      Header: "สาเหตุ",
      accessor: "reason",
    },
  ];
};

import { Badge, Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/Icons";
import {
  formatAccountNumber,
  getTransactionStatusBackground,
} from "utils/CommonUtils";
import { formatDateWithoutMeridiem } from "utils/DateformatUtils";
import ActionButtons from "views/transaction-report/withdraw/components/ActionButtons";

import { banks } from "../../bank";
import { getTxnStatus } from "../../system";

export const StatusBadge = ({ status, t }) => {
  return (
    <Flex align="center">
      <Badge
        bg={getTransactionStatusBackground(status)}
        px={3}
        h="28px"
        speed="0.8s"
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {
          <Text
            color={status === "PENDING" ? "#373F48" : "#fff"}
            fontWeight="normal"
          >
            {t(getTxnStatus(status), { ns: "withdrawReport" })}
          </Text>
        }
      </Badge>
    </Flex>
  );
};

export const withdrawTransactionColumnsData = (handleModalOpen, t) => {
  return [
    {
      Header: t("headers.status"),
      accessor: "status",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => <StatusBadge status={cell.value} t={t} />,
    },
    {
      Header: t("headers.username"),
      accessor: "username",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.fullName"),
      accessor: "fullName",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.amount"),
      accessor: "amount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.realAmount"),
      accessor: "realAmount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.accountNumber"),
      accessor: "accountNumber",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => formatAccountNumber(cell.value),
    },
    {
      Header: t("headers.beforeAmount"),
      accessor: "beforeAmount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.afterAmount"),
      accessor: "afterAmount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.bank"),
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankCode]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankCode]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            padding="5px"
          />
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: t("headers.tools"),
      minWidth: "295px",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => (
        <ActionButtons row={row} handleModalOpen={handleModalOpen} t={t} />
      ),
      disableSortBy: true,
    },
    // {
    //   Header: t("headers.remainBalance"),
    //   accessor: "remainBalance",
    //   textAlign: "right",
    //   justifyContent: "flex-end",
    //   Cell: ({ value }) => (
    //     <Text color="#fff" fontWeight="bold">
    //       {value}
    //     </Text>
    //   ),
    // },
    // {
    //   Header: t("headers.beforeTransfer"),
    //   accessor: "beforeTransfer",
    //   textAlign: "right",
    //   justifyContent: "flex-end",
    //   Cell: ({ value }) => (
    //     <Text color="#fff" fontWeight="bold">
    //       {value}
    //     </Text>
    //   ),
    // },
    // {
    //   Header: t("headers.afterTransfer"),
    //   accessor: "afterTransfer",
    //   textAlign: "right",
    //   justifyContent: "flex-end",
    //   Cell: ({ value }) => (
    //     <Text color="#fff" fontWeight="bold">
    //       {value}
    //     </Text>
    //   ),
    // },
    {
      Header: t("headers.createdBy"),
      accessor: "createdBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.updatedBy"),
      accessor: "updatedBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.createdDate"),
      accessor: "createdDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
    {
      Header: t("headers.updatedDate"),
      accessor: "updatedDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
    {
      Header: t("headers.edit"),
      Cell: ({ row }) =>
        row.original.status !== "SUCCESS" && ( // Correct the typo here
          <IconButton
            variant="solid"
            aria-label="edit"
            size="sm"
            borderRadius="28px"
            w="40px"
            h="30px"
            bg="green.500"
            icon={<EditIcon h="18px" w="18px" color="#fff" />}
            onClick={() =>
              handleModalOpen(
                "updateWithdrawTransactionStatusModal",
                row.original,
              )
            }
          />
        ),
      disableSortBy: true,
    },
  ];
};

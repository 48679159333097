import { Badge, Flex, IconButton } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/Icons";
import { formatDate } from "utils/DateformatUtils";

import { formatPhoneNumber } from "../../../utils/CommonUtils";

export const staffSettingColumnsData = (handleModalOpen, t) => [
  {
    Header: t("headers.status"),
    accessor: "active",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={cell.value === 1 ? "green.500" : "red.500"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="normal"
        >
          {cell.value === 1 ? t("badges.active") : t("badges.inactive")}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: t("headers.fullName"),
    accessor: "fullName",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.username"),
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.tel"),
    accessor: "tel",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (cell.value ? formatPhoneNumber(cell.value) : "-"),
  },
  {
    Header: t("headers.role"),
    accessor: "role",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.createdDate"),
    accessor: "createdDate",
    Cell: ({ value }) => formatDate(value),
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "",
    accessor: "edit",
    textAlign: "right",
    justifyContent: "flex-end",
    disableSortBy: true,
    Cell: ({ row }) => (
      <IconButton
        variant="solid"
        aria-label="edit"
        size="sm"
        borderRadius="28px"
        w="40px"
        h="30px"
        bg="green.500"
        icon={<EditIcon h="18px" w="18px" color="#fff" />}
        onClick={() => handleModalOpen("manageStaffModal", row.original)}
      />
    ),
  },
];

export const switchStyles = {
  components: {
    Switch: {
      baseStyle: {
        track: {
          bg: "#373F48",
          _checked: {
            bg: "#09483D",
          },
          _focus: {
            boxShadow: "none",
          },
        },
        thumb: {
          bg: "#1D2229",
          _checked: {
            bg: "#31AA93",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
};

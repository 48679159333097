import { Flex, Icon, Image, Input, Stack, Text } from "@chakra-ui/react";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";

import { useSuccessHandler } from "../../hooks/common/useSuccessHandler";
import { fileUpload } from "../../utils/APIUtils";
import { ERROR_MESSAGE } from "../../variables/system";
import CardBody from "../Card/CardBody";

const ImageUploader = ({ initialImage, onUpload }) => {
  const [imageSrc, setImageSrc] = useState(initialImage);

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  useEffect(() => {
    setImageSrc(initialImage);
  }, [initialImage]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const data = new FormData();
      data.append("file", file);

      fileUpload("/api/file/uploadFile", data)
        .then((response) => {
          if (response.status === 200) {
            handleSuccess(response.data.message);
            const imageUri = response.data.data.fileDownloadUri;
            setImageSrc(imageUri);
            onUpload(imageUri);
          }
        })
        .catch((error) => {
          handleError(error, ERROR_MESSAGE);
        });
    }
  };

  return (
    <CardBody>
      <Flex
        align="center"
        justify="center"
        border="1px dashed rgba(55, 63, 72, 1)"
        borderRadius="10px"
        w="100%"
        minH="250px"
        cursor="pointer"
        position="relative"
        overflow="hidden"
        maxH="250px"
        bg="rgba(30, 37, 44, 1)"
      >
        <Input
          type="file"
          accept="image/*"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          opacity="0"
          cursor="pointer"
          onChange={handleImageUpload}
        />
        {imageSrc ? (
          <Image src={imageSrc} alt="Uploaded image" maxH="80%" />
        ) : (
          <Stack align="center">
            <Icon as={FiUpload} w="47px" h="47px" color="green.500" />
            <Text color="gray.400" fontWeight="bold" fontSize="lg">
              อัพโหลดรูป
            </Text>
            <Text color="gray.400" fontSize="xs">
              เลือกไฟล์รูปภาพหรือลากวางไฟล์ที่นี่
            </Text>
          </Stack>
        )}
      </Flex>
    </CardBody>
  );
};

export default ImageUploader;

import { Flex, Image } from "@chakra-ui/react";
import avatar8 from "assets/img/avatars/avatar8.png";
import { formatDate } from "utils/DateformatUtils";
import EditButtons from "views/transaction-report/invalid/components/EditButtons";

import { banks } from "../../bank";

export const invalidTransactionReportColumnsData = (handleModalOpen, t) => {
  return [
    {
      Header: t("headers.amount"),
      accessor: "amount",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.bonus"),
      accessor: "bonus",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.bank"),
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          {row.original.bankCode ? (
            <Image
              src={`${banks[row.original.bankCode]?.logo}`}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
              padding="5px"
            />
          ) : (
            <Image
              src={avatar8}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          )}
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: t("headers.createdBy"),
      accessor: "createdBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.createdDate"),
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.remark"),
      accessor: "remark",
      minWidth: "295px",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.edit"),
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => (
        <Flex align="center" w="100%">
          <EditButtons row={row} handleModalOpen={handleModalOpen} t={t} />
        </Flex>
      ),
    },
  ];
};

import {
  DashboardIcon,
  FinanceSettingIcon,
  MemberManagementIcon,
  MemberReportIcon,
  SystemSettingIcon,
  ThiefIcon,
  TransactionReportIcon,
  WebsiteReportIcon,
  WebsiteSettingIcon,
} from "./components/Icons/Icons";
import Dashboard from "./views/dashboard";
import BanksSetting from "./views/finance-setting/banks";
import PaymentGatewaySetting from "./views/finance-setting/payment-gateway";
import TrueWalletSetting from "./views/finance-setting/true-wallet";
import MemberLevel from "./views/member-management/level";
import Members from "./views/member-management/members";
import MemberTag from "./views/member-management/tag";
import AffiliateReport from "./views/member-report/affiliate";
import CommissionReport from "./views/member-report/commission";
import MemberDepositReport from "./views/member-report/deposit";
// import MemberFirstDepositReport from "./views/member-report/first-deposit";
import MemberLevelUpReport from "./views/member-report/level-up";
import MemberNotDepositReport from "./views/member-report/not-deposit";
import PromotionReport from "./views/member-report/promotion";
import MemberReceivePromotion from "./views/member-report/receive-promotion";
import NewMemberTodayReport from "./views/member-report/today";
import MemberTransactionReport from "./views/member-report/transaction";
import MemberTurnoverReport from "./views/member-report/turnover";
import AffiliateSetting from "./views/system-setting/affiliate";
import CommissionSetting from "./views/system-setting/comission";
import ConditionSetting from "./views/system-setting/condition";
import GameSetting from "./views/system-setting/game";
import GameRecommendSetting from "./views/system-setting/game-recommend";
import JackpotSetting from "./views/system-setting/jackpot";
import MarketingSetting from "./views/system-setting/marketing";
import PromotionSetting from "./views/system-setting/promotion";
import RoleSetting from "./views/system-setting/role";
import SpinnerSetting from "./views/system-setting/spinner";
import StaffSetting from "./views/system-setting/staff";
import Thief from "./views/theif";
import DepositTransactionReport from "./views/transaction-report/deposit";
import InvalidTransactionReport from "./views/transaction-report/invalid";
import WithdrawTransactionReport from "./views/transaction-report/withdraw";
import DailySummaryReport from "./views/website-report/daily-summary";
import JackpotReport from "./views/website-report/jackpot";
import MarketingReport from "./views/website-report/marketing";
import MemberLoginReport from "./views/website-report/member-login";
// import ProfitLossReport from "./views/website-report/profit-loss";
import SpinnerReport from "./views/website-report/spinner";
import StaffCreditReport from "./views/website-report/staff-credit";
import StaffLoginReport from "./views/website-report/staff-login";
import ActivityImageSetting from "./views/website-setting/activity-image";
import BannerSetting from "./views/website-setting/banner";
// import CanonicalSetting from "./views/website-setting/canonical";
import FooterSetting from "./views/website-setting/footer";
import LiveChatSetting from "./views/website-setting/live-chat";
import PopupSetting from "./views/website-setting/popup";
import SeoSetting from "./views/website-setting/seo";

// import WebSetting from "./views/website-setting/web";

const allRoutes = [
  {
    menuId: 1,
    name: "menus.dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    collapse: false,
    items: [],
    component: (props) => <Dashboard {...props} pageName="menus.dashboard" />,
    layout: "/admin",
  },
  {
    menuId: 2,
    name: "menus.thiefList",
    path: "/thief",
    icon: <ThiefIcon />,
    collapse: false,
    items: [],
    component: (props) => <Thief {...props} pageName="menus.thiefList" />,
    layout: "/admin",
  },
  {
    name: "menus.memberManagement.title",
    path: "/member-management",
    icon: <MemberManagementIcon />,
    collapse: true,
    items: [
      {
        menuId: 3,
        name: "menus.memberManagement.submenus.allMembers",
        path: "/member-management/members",
        component: (props) => (
          <Members
            {...props}
            pageName="menus.memberManagement.submenus.allMembers"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 4,
        name: "menus.memberManagement.submenus.memberLevels",
        path: "/member-management/level",
        component: (props) => (
          <MemberLevel
            {...props}
            pageName="menus.memberManagement.submenus.memberLevels"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 5,
        name: "menus.memberManagement.submenus.memberTags",
        path: "/member-management/tag",
        component: (props) => (
          <MemberTag
            {...props}
            pageName="menus.memberManagement.submenus.memberTags"
          />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "menus.memberReports.title",
    path: "/member-report",
    icon: <MemberReportIcon />,
    collapse: true,
    items: [
      {
        menuId: 6,
        name: "menus.memberReports.submenus.newMembersToday",
        path: "/member-report/today",
        component: (props) => (
          <NewMemberTodayReport
            {...props}
            pageName="menus.memberReports.submenus.newMembersToday"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 7,
        name: "menus.memberReports.submenus.membersWithDeposits",
        path: "/member-report/deposit",
        component: (props) => (
          <MemberDepositReport
            {...props}
            pageName="menus.memberReports.submenus.membersWithDeposits"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 8,
        name: "menus.memberReports.submenus.registeredWithoutDeposits",
        path: "/member-report/not-deposit",
        component: (props) => (
          <MemberNotDepositReport
            {...props}
            pageName="menus.memberReports.submenus.registeredWithoutDeposits"
          />
        ),
        layout: "/admin",
      },
      // {
      //   menuId: 9,
      //   name: "menus.memberReports.submenus.firstDepositMembers",
      //   path: "/member-report/first-deposit",
      //   component: (props) => (
      //     <MemberFirstDepositReport
      //       {...props}
      //       pageName="menus.memberReports.submenus.firstDepositMembers"
      //     />
      //   ),
      //   layout: "/admin",
      // },
      {
        menuId: 47, // to change
        name: "menus.memberReports.submenus.memberTurnoverReport",
        path: "/member-report/turnover",
        component: (props) => (
          <MemberTurnoverReport
            {...props}
            pageName="menus.memberReports.submenus.memberTurnoverReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 10,
        name: "menus.memberReports.submenus.memberTransactions",
        path: "/member-report/transaction",
        component: (props) => (
          <MemberTransactionReport
            {...props}
            pageName="menus.memberReports.submenus.memberTransactions"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 48, // to change
        name: "menus.memberReports.submenus.memberLevelUpReport",
        path: "/member-report/level-up",
        component: (props) => (
          <MemberLevelUpReport
            {...props}
            pageName="menus.memberReports.submenus.memberLevelUpReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 46,
        name: "menus.memberReports.submenus.memberPromotionReceipts",
        path: "/member-report/receive-promotion",
        component: (props) => (
          <MemberReceivePromotion
            {...props}
            pageName="menus.memberReports.submenus.memberPromotionReceipts"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 11,
        name: "menus.memberReports.submenus.promotionReport",
        path: "/member-report/promotion-summary",
        component: (props) => (
          <PromotionReport
            {...props}
            pageName="menus.memberReports.submenus.promotionReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 12,
        name: "menus.memberReports.submenus.commissionReport",
        path: "/member-report/commission",
        component: (props) => (
          <CommissionReport
            {...props}
            pageName="menus.memberReports.submenus.commissionReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 13,
        name: "menus.memberReports.submenus.affiliateReport",
        path: "/member-report/affiliate",
        component: (props) => (
          <AffiliateReport
            {...props}
            pageName="menus.memberReports.submenus.affiliateReport"
          />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "menus.transactionReports.title",
    path: "/transaction-report",
    icon: <TransactionReportIcon />,
    collapse: true,
    items: [
      {
        menuId: 14,
        name: "menus.transactionReports.submenus.depositReport",
        path: "/transaction-report/deposit",
        component: (props) => (
          <DepositTransactionReport
            {...props}
            pageName="menus.transactionReports.submenus.depositReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 15,
        name: "menus.transactionReports.submenus.withdrawReport",
        path: "/transaction-report/withdraw",
        component: (props) => (
          <WithdrawTransactionReport
            {...props}
            pageName="menus.transactionReports.submenus.withdrawReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 16,
        name: "menus.transactionReports.submenus.invalidTransactions",
        path: "/transaction-report/invalid",
        component: (props) => (
          <InvalidTransactionReport
            {...props}
            pageName="menus.transactionReports.submenus.invalidTransactions"
          />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "menus.websiteReports.title",
    path: "/website-report",
    icon: <WebsiteReportIcon />,
    collapse: true,
    items: [
      // {
      //   menuId: 17,
      //   name: "menus.websiteReports.submenus.profitLossReport",
      //   path: "/website-report/profit-loss",
      //   component: (props) => (
      //     <ProfitLossReport
      //       {...props}
      //       pageName="menus.websiteReports.submenus.profitLossReport"
      //     />
      //   ),
      //   layout: "/admin",
      // },
      {
        menuId: 17,
        name: "menus.websiteReports.submenus.dailySummaryReport",
        path: "/website-report/daily-summary",
        component: (props) => (
          <DailySummaryReport
            {...props}
            pageName="menus.websiteReports.submenus.dailySummaryReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 18,
        name: "menus.websiteReports.submenus.marketingReport",
        path: "/website-report/marketing",
        component: (props) => (
          <MarketingReport
            {...props}
            pageName="menus.websiteReports.submenus.marketingReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 19,
        name: "menus.websiteReports.submenus.spinnerReport",
        path: "/website-report/spinner",
        component: (props) => (
          <SpinnerReport
            {...props}
            pageName="menus.websiteReports.submenus.spinnerReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 20,
        name: "menus.websiteReports.submenus.jackpotReport",
        path: "/website-report/jackpot",
        component: (props) => (
          <JackpotReport
            {...props}
            pageName="menus.websiteReports.submenus.jackpotReport"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 21,
        name: "menus.websiteReports.submenus.memberLoginHistory",
        path: "/website-report/member-login-history",
        component: (props) => (
          <MemberLoginReport
            {...props}
            pageName="menus.websiteReports.submenus.memberLoginHistory"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 22,
        name: "menus.websiteReports.submenus.staffLoginHistory",
        path: "/website-report/staff-login-history",
        component: (props) => (
          <StaffLoginReport
            {...props}
            pageName="menus.websiteReports.submenus.staffLoginHistory"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 23,
        name: "menus.websiteReports.submenus.staffCreditHistory",
        path: "/website-report/staff-credit-history",
        component: (props) => (
          <StaffCreditReport
            {...props}
            pageName="menus.websiteReports.submenus.staffCreditHistory"
          />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "menus.websiteSettings.title",
    path: "/website-setting",
    icon: <WebsiteSettingIcon />,
    collapse: true,
    items: [
      // {
      //   menuId: 24,
      //   name: "ตั้งค่าหน้าเว็บ",
      //   path: "/website-setting/web",
      //   component: (props) => (
      //     <WebSetting {...props} pageName="ตั้งค่าหน้าเว็บ" />
      //   ),
      //   layout: "/admin",
      // },
      {
        menuId: 25,
        name: "menus.websiteSettings.submenus.activityImageSettings",
        path: "/website-setting/activity-image",
        component: (props) => (
          <ActivityImageSetting
            {...props}
            pageName="menus.websiteSettings.submenus.activityImageSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 26,
        name: "menus.websiteSettings.submenus.seoSettings",
        path: "/website-setting/seo",
        component: (props) => (
          <SeoSetting
            {...props}
            pageName="menus.websiteSettings.submenus.seoSettings"
          />
        ),
        layout: "/admin",
      },
      // {
      //   menuId: 27,
      //   name: "menus.websiteSettings.submenus.canonicalSettings",
      //   path: "/website-setting/canonical",
      //   component: (props) => (
      //     <CanonicalSetting
      //       {...props}
      //       pageName="menus.websiteSettings.submenus.canonicalSettings"
      //     />
      //   ),
      //   layout: "/admin",
      // },
      {
        menuId: 28,
        name: "menus.websiteSettings.submenus.footerSettings",
        path: "/website-setting/footer",
        component: (props) => (
          <FooterSetting
            {...props}
            pageName="menus.websiteSettings.submenus.footerSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 29,
        name: "menus.websiteSettings.submenus.liveChatSettings",
        path: "/website-setting/live-chat",
        component: (props) => (
          <LiveChatSetting
            {...props}
            pageName="menus.websiteSettings.submenus.liveChatSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 30,
        name: "menus.websiteSettings.submenus.bannerSettings",
        path: "/website-setting/banner",
        component: (props) => (
          <BannerSetting
            {...props}
            pageName="menus.websiteSettings.submenus.bannerSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 31,
        name: "menus.websiteSettings.submenus.popupSettings",
        path: "/website-setting/popup",
        component: (props) => (
          <PopupSetting
            {...props}
            pageName="menus.websiteSettings.submenus.popupSettings"
          />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "menus.financeSettings.title",
    path: "/finance-setting",
    icon: <FinanceSettingIcon />,
    collapse: true,
    items: [
      {
        menuId: 43,
        name: "menus.financeSettings.submenus.bankSettings",
        path: "/finance-setting/bank",
        component: (props) => (
          <BanksSetting
            {...props}
            pageName="menus.financeSettings.submenus.bankSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 44,
        name: "menus.financeSettings.submenus.trueWalletSettings",
        path: "/finance-setting/true-wallet",
        component: (props) => (
          <TrueWalletSetting
            {...props}
            pageName="menus.financeSettings.submenus.trueWalletSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 45,
        name: "menus.financeSettings.submenus.paymentGatewaySettings",
        path: "/finance-setting/payment-gateway",
        component: (props) => (
          <PaymentGatewaySetting
            {...props}
            pageName="menus.financeSettings.submenus.paymentGatewaySettings"
          />
        ),
        layout: "/admin",
      },
    ],
  },
  {
    name: "menus.systemSettings.title",
    path: "/system-setting",
    icon: <SystemSettingIcon />,
    collapse: true,
    items: [
      {
        menuId: 32,
        name: "menus.systemSettings.submenus.websiteConditions",
        path: "/system-setting/condition",
        component: (props) => (
          <ConditionSetting
            {...props}
            pageName="menus.systemSettings.submenus.websiteConditions"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 33,
        name: "menus.systemSettings.submenus.promotionSettings",
        path: "/system-setting/promotions",
        component: (props) => (
          <PromotionSetting
            {...props}
            pageName="menus.systemSettings.submenus.promotionSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 34,
        name: "menus.systemSettings.submenus.commissionSettings",
        path: "/system-setting/commission",
        component: (props) => (
          <CommissionSetting
            {...props}
            pageName="menus.systemSettings.submenus.commissionSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 35,
        name: "menus.systemSettings.submenus.affiliateSettings",
        path: "/system-setting/affiliate",
        component: (props) => (
          <AffiliateSetting
            {...props}
            pageName="menus.systemSettings.submenus.affiliateSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 36,
        name: "menus.systemSettings.submenus.marketingSettings",
        path: "/system-setting/marketing",
        component: (props) => (
          <MarketingSetting
            {...props}
            pageName="menus.systemSettings.submenus.marketingSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 37,
        name: "menus.systemSettings.submenus.staffSettings",
        path: "/system-setting/staff",
        component: (props) => (
          <StaffSetting
            {...props}
            pageName="menus.systemSettings.submenus.staffSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 38,
        name: "menus.systemSettings.submenus.roleSettings",
        path: "/system-setting/roles",
        component: (props) => (
          <RoleSetting
            {...props}
            pageName="menus.systemSettings.submenus.roleSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 39,
        name: "menus.systemSettings.submenus.gameSettings",
        path: "/system-setting/game",
        component: (props) => (
          <GameSetting
            {...props}
            pageName="menus.systemSettings.submenus.gameSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 40,
        name: "menus.systemSettings.submenus.recommendedGameSettings",
        path: "/system-setting/recommend-game",
        component: (props) => (
          <GameRecommendSetting
            {...props}
            pageName="menus.systemSettings.submenus.recommendedGameSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 41,
        name: "menus.systemSettings.submenus.spinnerSettings",
        path: "/system-setting/spinner",
        component: (props) => (
          <SpinnerSetting
            {...props}
            pageName="menus.systemSettings.submenus.spinnerSettings"
          />
        ),
        layout: "/admin",
      },
      {
        menuId: 42,
        name: "menus.systemSettings.submenus.jackpotSettings",
        path: "/system-setting/jackpot",
        component: (props) => (
          <JackpotSetting
            {...props}
            pageName="menus.systemSettings.submenus.jackpotSettings"
          />
        ),
        layout: "/admin",
      },
    ],
  },
];

export default allRoutes;

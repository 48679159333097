import { useQuery } from "react-query";
import {
  fetchLast20DepositHistory,
  fetchLast20MarketingDepositHistory,
} from "services/dashboard/dashboardService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useLast20Deposit = (role, mid = null) => {
  const handleError = useErrorHandler();

  const fetchLastDeposit = async () => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchLast20DepositHistory()
        : await fetchLast20MarketingDepositHistory({ mid: mid });
    return response.data.data.filter((data) => data.type !== "REFUNDWINLOSE");
  };

  const { data: lastDeposit, isLoading: isLastDepositLoading } = useQuery(
    QueryKeys.LAST_20_DEPOSIT,
    fetchLastDeposit,
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  return { lastDeposit, isLastDepositLoading };
};

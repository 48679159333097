import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import SearchTable from "components/Tables/SearchTable";
import { useMemberLoginReport } from "hooks/business/website-report/useMemberLoginReport";
import React from "react";
import { useTranslation } from "react-i18next";
import { memberLoginHistoryColumnsData } from "variables/columns/website-report/memberLoginHistoryColumnsData";

const MemberLoginReport = ({ pageName }) => {
  const { t } = useTranslation("memberLoginReport");
  const { data: memberLoginHistoryData, isLoading } = useMemberLoginReport();
  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", xl: "0px" }}
              color="#fff"
            >
              {t("memberLoginReport")}
            </Text>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTable
              tableData={memberLoginHistoryData}
              columnsData={memberLoginHistoryColumnsData(t)}
              isLoading={isLoading}
            />
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default MemberLoginReport;

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {
  DashboardIcon,
  FinanceSettingIcon,
  MemberManagementIcon,
  MemberReportIcon,
  SystemSettingIcon,
  ThiefIcon,
  TransactionReportIcon,
  WebsiteReportIcon,
  WebsiteSettingIcon,
} from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { useSuccessHandler } from "hooks/common/useSuccessHandler";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchRoleById,
  saveRole,
} from "services/system-setting/roleSettingService";
import { groupPermissionsByMenuGroup } from "utils/RoleUtils";
import { ERROR_MESSAGE } from "variables/system";

const ManageRoleView = ({ pageName }) => {
  const { t } = useTranslation("roleSetting");
  const { roleId } = useParams();
  const history = useHistory();
  const [roleData, setRoleData] = useState({ roleName: "", permissions: {} });
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const [permissionsData, setPermissionsData] = useState([]);
  const [updateArray, setUpdateArray] = useState([]);

  const getMenuGroupIcon = (menuGroup) => {
    switch (menuGroup) {
      case "menus.dashboard":
        return <DashboardIcon w="24px" h="24px" color="green.500" />;
      case "menus.thiefList":
        return <ThiefIcon w="24px" h="24px" color="green.500" />;
      case "menus.memberManagement.title":
        return <MemberManagementIcon w="24px" h="24px" color="green.500" />;
      case "menus.memberReports.title":
        return <MemberReportIcon w="24px" h="24px" color="green.500" />;
      case "menus.transactionReports.title":
        return <TransactionReportIcon w="24px" h="24px" color="green.500" />;
      case "menus.websiteReports.title":
        return <WebsiteReportIcon w="24px" h="24px" color="green.500" />;
      case "menus.websiteSettings.title":
        return <WebsiteSettingIcon w="24px" h="24px" color="green.500" />;
      case "menus.systemSettings.title":
        return <SystemSettingIcon w="24px" h="24px" color="green.500" />;
      case "menus.financeSettings.title":
        return <FinanceSettingIcon w="24px" h="24px" color="green.500" />;
    }
  };

  const fetchPermissionData = () => {
    fetchRoleById(1)
      .then((response) => {
        let masterPermissions = response.data.data.menuList;

        if (roleId) {
          fetchRoleById(roleId)
            .then((roleResponse) => {
              let selectedRolePermissions = roleResponse.data.data.menuList;

              if (roleResponse.data.data.roleName === "MARKETING") {
                masterPermissions = masterPermissions.filter((permission) => {
                  const allowedMenuIds = [1, 6, 7, 8, 9, 11, 14, 15, 16, 3, 17];
                  return allowedMenuIds.includes(permission.menuId);
                });
              }

              const groupedMasterPermissions =
                groupPermissionsByMenuGroup(masterPermissions);
              setPermissionsData(groupedMasterPermissions);

              const groupedRolePermissions = groupPermissionsByMenuGroup(
                selectedRolePermissions,
              );
              setRoleData({
                ...roleResponse.data.data,
                permissions: groupedRolePermissions,
              });

              setUpdateArray(selectedRolePermissions);
            })
            .catch((error) => {
              handleError(error, ERROR_MESSAGE);
            });
        } else {
          const groupedMasterPermissions =
            groupPermissionsByMenuGroup(masterPermissions);
          setPermissionsData(groupedMasterPermissions);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchPermissionData();
  }, [roleId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const handlePermissionChange = (menuGroup, menu) => {
    setRoleData((prevData) => {
      const updatedPermissions = { ...prevData.permissions };

      if (!updatedPermissions[menuGroup]) {
        updatedPermissions[menuGroup] = [];
      }

      if (
        updatedPermissions[menuGroup].some(
          (perm) => perm.menuId === menu.menuId,
        )
      ) {
        updatedPermissions[menuGroup] = updatedPermissions[menuGroup].filter(
          (perm) => perm.menuId !== menu.menuId,
        );
        setUpdateArray((prevArray) =>
          prevArray.filter((item) => item.menuId !== menu.menuId),
        );
      } else {
        updatedPermissions[menuGroup].push(menu);
        setUpdateArray((prevArray) => [...prevArray, menu]);
      }

      return { ...prevData, permissions: updatedPermissions };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      roleId: roleId ? parseInt(roleId) : null,
      roleName: roleData.roleName,
      menuList: updateArray,
    };

    saveRole(payload)
      .then((response) => {
        handleSuccess(response.data.message);
        history.push("/admin/system-setting/roles");
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card w="100%">
        <CardHeader pb="12px" pt="12px">
          <Flex direction="column">
            <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
              {t("roleAndPermissionSettings")}
            </Text>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.6s" delay="0.5s">
          <CardBody>
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                xl: "repeat(2, 1fr)",
              }}
              gap="24px"
              w="100%"
            >
              <GridItem colSpan={{ base: 2, xl: 1 }}>
                <FormControl>
                  <Text fontWeight="normal">{t("roleName")}</Text>
                  <Input
                    name="roleName"
                    fontSize="sm"
                    value={roleData.roleName}
                    onChange={handleChange}
                    isReadOnly={!!roleId}
                    backgroundColor={roleId ? "#373F48" : "#1E252C"}
                    placeholder={t("roleNamePlaceholder")}
                  />
                </FormControl>
              </GridItem>
              <GridItem display={{ base: "none", xl: "grid" }} />
              {Object.keys(permissionsData).map((menuGroup, index) => (
                <GridItem key={index} colSpan={2}>
                  <Card boxShadow="none" border="1px solid #373F48" p={4}>
                    <Flex
                      justifyContent="space-between"
                      direction={{ base: "column", xl: "row" }}
                    >
                      <Flex flex="1" alignItems="center">
                        {getMenuGroupIcon(menuGroup)}
                        <Text
                          fontWeight="bold"
                          fontSize="16px"
                          color="#fff"
                          ml={{ base: 6, xl: 8 }}
                        >
                          {t(menuGroup, { ns: "shared" })}
                        </Text>
                      </Flex>
                      <SimpleGrid
                        columns={{ base: 2, xl: 4 }}
                        spacing={3}
                        flex="3"
                        width="100%"
                        align="start"
                        mt={{ base: 4, xl: 2 }}
                      >
                        {permissionsData[menuGroup].map((menu) => (
                          <Checkbox
                            colorScheme="green"
                            key={menu.menuId}
                            isChecked={
                              roleData.permissions[menuGroup]?.some(
                                (perm) => perm.menuId === menu.menuId,
                              ) || false
                            }
                            onChange={() =>
                              handlePermissionChange(menuGroup, menu)
                            }
                            sx={{
                              "& .chakra-checkbox__control": {
                                _checked: {
                                  bg: "green.500",
                                  borderColor: "green.500",
                                  color: "black",
                                },
                              },
                            }}
                          >
                            <Text fontSize="sm">
                              {t(menu.menuCode, { ns: "shared" })}
                            </Text>
                          </Checkbox>
                        ))}
                      </SimpleGrid>
                    </Flex>
                  </Card>
                </GridItem>
              ))}
            </Grid>
          </CardBody>
        </Animated>
        <Animated justify="flex-start" duration={0.4} delay={0.6} mt={6} mb={6}>
          <Button
            fontSize="14px"
            fontWeight="normal"
            bg="#373F48"
            color="#8C9592"
            width={{ base: "100%", xl: "auto" }}
            minW={{ base: "none", xl: "175px" }}
            borderRadius="33px"
            mr={2}
            onClick={() => history.push("/admin/system-setting/roles")}
          >
            {t("buttons.cancel")}
          </Button>
          <Button
            background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
            width={{ base: "100%", xl: "auto" }}
            minW={{ base: "none", xl: "175px" }}
            mt={{ base: "10px", xl: "0px" }}
            borderRadius="33px"
            fontSize="14px"
            fontWeight="normal"
            onClick={handleSubmit}
          >
            {t("buttons.save")}
          </Button>
        </Animated>
      </Card>
    </Flex>
  );
};

export default ManageRoleView;

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";

import { TransactionReceipt } from "./TransactionReceipt";

const RefreshWithdrawErrorTransactionModal = ({
  isOpen,
  onClose,
  transaction,
  handleSubmit,
  t,
}) => {
  return (
    <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              {t("refreshWithdrawError.title")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              withdrawId: transaction?.id,
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Text
                  fontSize="16px"
                  fontWeight="bold"
                  pt={2}
                  pb={4}
                  color="green.500"
                >
                  {t("refreshWithdrawError.title")}
                </Text>
                <TransactionReceipt transaction={transaction} />
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    fontWeight="normal"
                    type="submit"
                    isLoading={props.isSubmitting}
                    fontSize="14px"
                  >
                    {t("refreshWithdrawError.submitButton")}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RefreshWithdrawErrorTransactionModal;

import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import Card from "../../../../components/Card/Card";

export const MemberSummaryCard = ({ title, date, amount, count }) => {
  return (
    <Card bg="#262D35" mb="12px" borderRadius="8px">
      <Flex align="center" justifyContent="space-between">
        <Flex align="center">
          <Flex direction="column">
            <Text color="#fff" fontSize="16px" mt="0px" fontWeight="bold">
              {title}
            </Text>
            <Text color="#8C9592" fontSize="14px">
              {date}
            </Text>
          </Flex>
        </Flex>
        {title === "กำไร" ? (
          <Text>{`${amount}`}</Text>
        ) : (
          <Text>{`${amount} (${count} รายการ)`}</Text>
        )}
      </Flex>
    </Card>
  );
};

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Switch,
  Text,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "components/Icons/Icons";
import React from "react";
import { getDeviceText } from "utils/CommonUtils";

const BannerCard = ({ banner, onEdit, onDelete, t }) => {
  return (
    <Box borderRadius="10px" overflow="hidden" p={6} bg="#1E252C">
      <Image
        src={banner.image || "https://via.placeholder.com/1040"}
        alt={`${banner.name} image`}
        width="100%"
        height="150px"
        objectFit="cover"
        borderRadius="10px"
      />

      <Box pt={4} pb={4}>
        <Text fontSize="16px" fontWeight="bold" color="#fff">
          {banner.name}
        </Text>
        <Text fontSize="14px" mt={2} mb={4}>
          {banner.content}
        </Text>
        <Box border="1px solid #373F48" borderRadius="6px" px={4} bg="#252d35">
          <Flex
            justify="space-between"
            p={3}
            borderBottom="1px solid #373F48"
            mx={-4}
          >
            <Text fontWeight="normal" fontSize="14px">
              {t("form.sortOrder")}
            </Text>
            <Text fontSize="14px">{banner.sort}</Text>
          </Flex>
          <Flex
            justify="space-between"
            p={3}
            borderBottom="1px solid #373F48"
            mx={-4}
          >
            <Text fontWeight="normal" fontSize="14px">
              {t("form.device")}
            </Text>
            <Text fontSize="14px">{getDeviceText(banner.device)}</Text>
          </Flex>
          <Flex
            justify="space-between"
            p={3}
            borderBottom="1px solid #373F48"
            mx={-4}
          >
            <Text fontWeight="normal" fontSize="14px">
              {t("form.language")}
            </Text>
            <Text fontSize="14px">{banner.language}</Text>
          </Flex>
          <Flex justify="space-between" p={3} mx={-4}>
            <Text fontWeight="normal" fontSize="14px">
              {t("form.status")}
            </Text>
            <Switch isChecked={banner.status === "true"} readOnly />
          </Flex>
        </Box>

        <Flex justify="space-between" align="center" pt={4}>
          <Text fontSize="14px">{t("manageBannerText")}</Text>
          <Flex mt={4}>
            <IconButton
              icon={<Icon as={EditIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              color="#fff"
              bg="green.500"
              variant="solid"
              onClick={onEdit}
              mr={2}
            />
            <IconButton
              icon={<Icon as={DeleteIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              variant="solid"
              color="#fff"
              bg="red.500"
              size="sm"
              onClick={onDelete}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default BannerCard;

import { Box, Flex, Grid, GridItem, Select, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BarChart from "components/Charts/BarChart";
import DonutChart from "components/Charts/DonutChart";
import FileExportDropdown from "components/FileExportDropdown/FileExportDropdown";
import PageHeader from "components/Layout/PageHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { useDailySummaryReport } from "hooks/business/website-report/useDailySummaryReport";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { dailySummaryReportColumnsData } from "variables/columns/website-report/dailySummaryReportColumnsData";

const DailySummaryReport = ({ pageName }) => {
  const { t } = useTranslation("marketingReport");

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const [search, setSearch] = useState({
    month: currentMonth,
    year: currentYear,
    page: 0,
    pageSize: 31,
    sort: "A",
    sortField: "id",
  });

  const handleMonthChange = (e) => {
    const newMonth = parseInt(e.target.value, 10);
    setSearch((prev) => ({ ...prev, month: newMonth }));
  };

  const handleYearChange = (e) => {
    const newYear = parseInt(e.target.value, 10);
    setSearch((prev) => ({ ...prev, year: newYear }));
  };

  const { data: dailySummaryData, isLoading } = useDailySummaryReport(search);

  const monthOptions = [
    { label: "มกราคม", val: 1 },
    { label: "กุมภาพันธ์", val: 2 },
    { label: "มีนาคม", val: 3 },
    { label: "เมษายน", val: 4 },
    { label: "พฤษภาคม", val: 5 },
    { label: "มิถุนายน", val: 6 },
    { label: "กรกฎาคม", val: 7 },
    { label: "สิงหาคม", val: 8 },
    { label: "กันยายน", val: 9 },
    { label: "ตุลาคม", val: 10 },
    { label: "พฤศจิกายน", val: 11 },
    { label: "ธันวาคม", val: 12 },
  ];

  const yearOptions = [2020, 2021, 2022, 2023, 2024, 2025];

  const barChartSeries = [
    {
      name: "Series A",
      data: [12, 15, 8, 19, 14, 20, 25, 18, 17, 13, 16, 11],
    },
    {
      name: "Series B",
      data: [10, 5, 14, 8, 15, 25, 11, 14, 12, 7, 9, 20],
    },
    {
      name: "Series C",
      data: [9, 14, 18, 12, 17, 3, 16, 18, 22, 14, 8, 6],
    },
    {
      name: "Series D",
      data: [22, 18, 14, 5, 11, 6, 7, 18, 15, 14, 10, 5],
    },
    {
      name: "Series E",
      data: [12, 16, 5, 9, 7, 12, 23, 14, 9, 18, 4, 9],
    },
    {
      name: "Series F",
      data: [16, 14, 12, 10, 4, 7, 12, 16, 20, 22, 11, 13],
    },
    {
      name: "Series G",
      data: [10, 12, 13, 14, 16, 7, 11, 19, 23, 9, 18, 21],
    },
    {
      name: "Series H",
      data: [14, 7, 9, 22, 5, 16, 23, 14, 7, 20, 18, 10],
    },
    {
      name: "Series I",
      data: [11, 12, 14, 16, 18, 20, 19, 15, 14, 9, 8, 10],
    },
    {
      name: "Series J",
      data: [17, 9, 6, 3, 14, 16, 22, 7, 13, 18, 16, 15],
    },
  ];

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      foreColor: "#adb1b8",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ["transparent"] },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: { text: "Value" },
    },
    fill: { opacity: 1 },
    legend: { show: true },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const pieChartData = [44, 55, 41, 17, 15];
  const pieChartOptions = {
    labels: [
      "Category A",
      "Category B",
      "Category C",
      "Category D",
      "Category E",
    ],
    chart: {
      type: "pie",
    },
    colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
    legend: {
      position: "bottom",
      fontSize: "14px",
      labels: {
        colors: "#fff",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " units";
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Grid templateColumns={{ sm: "1fr", xl: "1.8fr 1.2fr" }} gap="24px">
        <GridItem>
          <Card h="100%">
            <CardHeader>
              <Flex
                direction={{ base: "column", xl: "row" }}
                justifyContent="space-between"
              >
                <Text
                  color="white"
                  fontSize="18px"
                  fontWeight="bold"
                  pb={{ base: "14px", xl: "0px" }}
                >
                  สรุปยอด
                </Text>
                <Select
                  placeholder="เดือน"
                  w="235px"
                  mr="12px"
                  value={search.month}
                  onChange={() => console.log("hello")}
                >
                  {monthOptions.map((m) => (
                    <option key={m} value={m.val}>
                      {m.label}
                    </option>
                  ))}
                </Select>
              </Flex>
            </CardHeader>
            <Box h="365px">
              <BarChart
                chartData={barChartSeries}
                chartOptions={barChartOptions}
              />
            </Box>
          </Card>
        </GridItem>
        <GridItem>
          <Card h="100%">
            <Flex
              direction={{ base: "column", xl: "row" }}
              justifyContent="space-between"
            >
              <Text
                color="white"
                fontSize="18px"
                fontWeight="bold"
                pb={{ base: "14px", xl: "0px" }}
              >
                ทั้งหมด
              </Text>
              <Select
                placeholder="เดือน"
                w="235px"
                mr="12px"
                value={search.month}
                onChange={() => console.log("hello")}
              >
                {monthOptions.map((m) => (
                  <option key={m} value={m.val}>
                    {m.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Box h="385px">
              <DonutChart
                chartData={pieChartData}
                chartOptions={pieChartOptions}
              />
            </Box>
          </Card>
        </GridItem>
      </Grid>
      <Card mt="24px" pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              color="white"
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", xl: "0px" }}
            >
              รายงานตามรายวัน
            </Text>
            <Flex>
              <Select
                placeholder="เดือน"
                w="235px"
                mr="12px"
                value={search.month}
                onChange={handleMonthChange}
              >
                {monthOptions.map((m) => (
                  <option key={m} value={m.val}>
                    {m.label}
                  </option>
                ))}
              </Select>

              <Select
                placeholder="ปี"
                w="235px"
                mr="12px"
                value={search.year}
                onChange={handleYearChange}
              >
                {yearOptions.map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </Select>
              <FileExportDropdown />
            </Flex>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.5s">
          <CardBody>
            <SimpleTable
              tableData={dailySummaryData}
              columnsData={dailySummaryReportColumnsData()}
              showExport={false}
              filename={pageName}
              isLoading={isLoading}
            />
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default DailySummaryReport;

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Stack,
  Switch, // Image,
  Text,
} from "@chakra-ui/react";
import { SystemSettingIcon } from "components/Icons/Icons";
import React from "react";
import { paymentGateway } from "variables/paymentGateway";

const PaymentGatewayCard = ({ paymentGateway: pg, onEdit, t }) => {
  return (
    <Box borderRadius="10px" overflow="hidden" bg="#1E252C">
      <Box p={6}>
        <Flex align="flex-start" pb={4} justifyContent="space-between">
          <Flex>
            <Image
              src={`${paymentGateway[pg.payment]?.logo}`}
              backgroundColor={`${paymentGateway[pg.payment]?.color}`}
              // padding="5px"
              boxSize="50px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
            <Stack spacing={1} align="flex-start" ml={6}>
              <Text fontWeight="normal" fontSize="14px">
                {t("form.provider")}
              </Text>
              <Text fontSize="18px" color="green.500" fontWeight="bold">
                {paymentGateway[pg.payment]?.nice_name}
              </Text>
            </Stack>
          </Flex>
          <Flex>
            <IconButton
              icon={
                <Icon
                  as={SystemSettingIcon}
                  h="24px"
                  w="24px"
                  color="green.500"
                />
              }
              w={"35px"}
              h={"35px"}
              cursor="pointer"
              variant="solid"
              color="#fff"
              bg="#262D35"
              size="sm"
              onClick={onEdit}
            />
          </Flex>
        </Flex>
        <Box
          border="1px solid #373F48"
          borderRadius="6px"
          px={4}
          bg="#252d35"
          mt={2}
        >
          <Flex justify="space-between" p={3} mx={-4}>
            <Text fontWeight="medium" fontSize="sm">
              {t("form.status")}
            </Text>
            <Switch isChecked={pg?.active} readOnly />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentGatewayCard;

import { Box, useToast } from "@chakra-ui/react";

export const useSuccessHandler = () => {
  const toast = useToast();
  return (message) => {
    console.log(message);
    toast({
      // title: SUCCESS_MESSAGE,
      position: "top-right",
      // description: message,
      status: "success",
      isClosable: true,
      render: () => (
        <Box color="white" p={5} bg="green.500" borderRadius="0.5rem">
          {message}
        </Box>
      ),
    });
  };
};

import { Text } from "@chakra-ui/react";
import { formatDate } from "utils/DateformatUtils";

export const spinnerReportColumnsData = (t) => [
  {
    Header: t("headers.name"),
    accessor: "name",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => (
      <Text color="green.500" fontsize="14px" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: t("headers.username"),
    accessor: "username",
    disableSortBy: true,
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.amount"),
    accessor: "amount",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: t("headers.beforeAmount"),
    accessor: "beforeAmount",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: t("headers.afterAmount"),
    accessor: "afterAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: t("headers.turnOver"),
    accessor: "turnOver",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: t("headers.type"),
    accessor: "type",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.createdDate"),
    accessor: "createdDate",
    Cell: ({ value }) => formatDate(value),
    textAlign: "left",
    justifyContent: "flex-start",
  },
];

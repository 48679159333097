export const buildBasePayload = (
  page,
  pageSize,
  search = null,
  sort = "D",
  sortField = "id",
  buildPayload,
) => {
  const basePayload = {
    page: page + 1,
    pageSize: pageSize,
    sort: sort,
    sortField: sortField,
    ...search,
  };

  return buildPayload(basePayload);
};

export const buildFetchMemberPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    typeUser: 0,
    username: "",
    // type: "username",
    // bankCode: "",
    // bankValue: "",
    // searchValue: "",
    ...basePayload,
  };
};

export const buildMemberFetchMemberDepositPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",
    typeUser: "1",

    ...basePayload,
  };
};

export const buildMemberFirstDepositPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",
    typeUser: "3",

    ...basePayload,
  };
};

export const buildMemberNotDepositPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",
    typeUser: "2",

    ...basePayload,
  };
};

export const buildFetchJackpotHistoryPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",

    ...basePayload,
  };
};

export const buildFetchSpinnerHistoryPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",

    ...basePayload,
  };
};

export const buildMemberTransactionPayload = (basePayload) => {
  return {
    listDateFrom: "",
    listDateTo: "",
    username: "",

    ...basePayload,
  };
};

export const buildThiefPayload = (basePayload) => {
  return {
    name: "",
    bankAccount: "",
    bankName: "",

    ...basePayload,
  };
};

export const buildFetchStaffLoginReportPayload = (basePayload) => {
  return {
    listDateFrom: "",
    listDateTo: "",
    username: "",

    ...basePayload,
  };
};

export const buildFetchDepositHistoryPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",
    amount: "",

    ...basePayload,
  };
};

export const buildFetchInvalidTransactionPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",
    status: "NOT_SURE",
    amount: "",

    ...basePayload,
  };
};

export const buildFetchWithdrawHistoryPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    username: "",
    status: "",
    isAuto: "",

    ...basePayload,
  };
};

export const buildCommissionSettingPayload = (
  type,
  values,
  commissionAffiliateData,
  commissionWinloseData,
  commissionTurnOverData,
  commissionId,
) => {
  switch (type) {
    case "commissionAffiliate":
      return {
        id: commissionId,
        commissionAffiliate: values.commissions,
        commissionWinlose: commissionWinloseData,
        commissionTurnOver: commissionTurnOverData,
      };
    case "commissionWinlose":
      return {
        id: commissionId,
        commissionAffiliate: commissionAffiliateData,
        commissionWinlose: values.commissions,
        commissionTurnOver: commissionTurnOverData,
      };
    case "commissionTurnOver":
      return {
        id: commissionId,
        commissionAffiliate: commissionAffiliateData,
        commissionWinlose: commissionWinloseData,
        commissionTurnOver: values.commissions,
      };
    default:
      return {};
  }
};

export const buildFetchPromotionReportPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    ...basePayload,
  };
};

export const buildMemberTransferLogReportPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    ...basePayload,
  };
};

export const buildMemberHaveTurnoverReportPayload = (basePayload) => {
  return {
    type: "CHECKTURN",
    createdDateFrom: "",
    createdDateTo: "",
    ...basePayload,
  };
};

export const buildMemberLevelUpReportPayload = (basePayload) => {
  return {
    createdDateFrom: "",
    createdDateTo: "",
    ...basePayload,
  };
};

import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#1E252C",
    borderColor: "#373F48",
    color: "#8C9592",
    fontSize: "14px",
    minWidth: "266px",
    borderRadius: "33px",
    height: "42px",
    "&:hover": {
      borderColor: "#373F48",
    },
    paddingLeft: "6px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "rgb(29, 34, 41)",
    color: "#8C9592",
    zIndex: 9999,
    fontSize: "14px",
    borderRadius: "0.375rem",
    border: "1px solid rgba(55, 63, 72, 1)",
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "gray.700",
    color: "#8C9592",
    zIndex: 9999,
    fontSize: "14px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#8C9592",
    fontSize: "14px",
  }),
  input: (provided) => ({
    ...provided,
    color: "#8C9592",
    fontSize: "14px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const SearchableDropdown = ({
  options,
  isSearchable,
  placeholder,
  onChange,
}) => {
  console.log(placeholder);
  return (
    <Select
      options={options}
      isSearchable={isSearchable}
      placeholder={placeholder}
      styles={customStyles}
      onChange={onChange}
    />
  );
};

export default SearchableDropdown;

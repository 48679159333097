import { Flex, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { useMarketingReport } from "hooks/business/website-report/useMarketingReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { useTranslation } from "react-i18next";

import { marketingReportColumnsData } from "../../../variables/columns/website-report/marketingReportColumnsData";

const MarketingReport = ({ pageName }) => {
  const { t } = useTranslation("marketingReport");
  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const { search, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    data: marketingReportData,
    isLoading,
    downloadableData,
  } = useMarketingReport(search, marketingReportColumnsData(t));

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              color="white"
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", xl: "0px" }}
            >
              {t("marketingReport")}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.5s">
          <CardBody>
            <SimpleTable
              tableData={marketingReportData}
              columnsData={marketingReportColumnsData(t)}
              showExport={true}
              downloadableData={downloadableData}
              filename={pageName}
              isLoading={isLoading}
            />
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default MarketingReport;

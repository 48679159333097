import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { NotFoundIcon, SearchIcon } from "../Icons/Icons";
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";

function SearchTable(props) {
  const {
    columnsData,
    tableData,
    isSum,
    showSearchableDropdown = false,
    showAction = false,
    searchOptions,
    actionButtonIcon,
    actionButtonText,
    handleModalOpen,
    modalName,
    onChange,
    isLoading,
    isSearchable = true,
    noDataBoxH = "425px",
  } = props;

  const { t, i18n } = useTranslation();
  const columns = useMemo(() => columnsData || [], [columnsData, t]);
  const data = useMemo(() => tableData || [], [tableData]);

  const [forceRenderKey, setForceRenderKey] = useState(0);

  React.useEffect(() => {
    const onLanguageChange = () => {
      setForceRenderKey((prevKey) => prevKey + 1);
    };

    i18n.on("languageChanged", onLanguageChange);

    return () => {
      i18n.off("languageChanged", onLanguageChange);
    };
  }, [i18n]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    // nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];
    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;

  const stackAlignment = useBreakpointValue({ base: "center", xl: "flex-end" });
  const stackMarginStart = useBreakpointValue({ base: "0", xl: "auto" });

  const sums = columnsData.reduce((acc, column) => {
    if (column.isSum) {
      acc[column.accessor] = data.reduce(
        (sum, row) => sum + row[column.accessor],
        0,
      );
    }
    return acc;
  }, {});

  const [pageChunk, setPageChunk] = useState(0);
  const pagesPerChunk = 5;

  return (
    <Flex direction="column" w="100%" key={forceRenderKey}>
      {(isSearchable || showAction || showSearchableDropdown) && (
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          pb="22px"
          direction={{ base: "column", xl: "row" }}
          gap={{ base: "12px", xl: "0" }}
        >
          {isSearchable && (
            <InputGroup w={{ base: "100%", xl: "266px" }}>
              <Input
                type="text"
                placeholder={t("search")}
                fontSize="14px"
                borderRadius="33px"
                color="gray.500"
                backgroundColor="#1E252C"
                _focus={{ borderColor: "none" }}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <InputRightElement
                pointerEvents="none"
                children={<SearchIcon color="gray.500" h="18px" w="18px" />}
                right="8px"
              />
            </InputGroup>
          )}
          {showAction && (
            <Button
              alignSelf="flex-start"
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              color="#fff"
              mr="8px"
              borderRadius="33px"
              fontSize="14px"
              pl={6}
              pr={6}
              onClick={() => handleModalOpen(modalName)}
            >
              {actionButtonIcon != null && (
                <Icon
                  as={actionButtonIcon}
                  mr="5px"
                  h="24px"
                  w="24px"
                  justifyContent="center"
                />
              )}
              {actionButtonText}
            </Button>
          )}
          {showSearchableDropdown && (
            <Box w={{ base: "100%", xl: "auto" }}>
              <SearchableDropdown
                options={searchOptions}
                isSearchable={false}
                onChange={onChange}
              />
            </Box>
          )}
        </Flex>
      )}
      <Box
        overflowX="auto"
        w="100%"
        borderRadius="6px"
        border="1px solid rgba(55, 63, 72, 1)"
        bg={page.length === 0 || isLoading ? "rgba(33, 39, 46, 1)" : ""}
      >
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
          w="100%"
        >
          <Thead backgroundColor="#1D2229">
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="0px"
                    key={index}
                    pt={3}
                    pb={3}
                    pl={4}
                    pr={4}
                    fontSize="14px"
                    textAlign={column.textAlign || "center"}
                    whiteSpace="nowrap"
                    // minW="175px"
                    fontFamily="'LINESeedSansTH'"
                    fontWeight="normal"
                  >
                    <Flex
                      justify={column.justifyContent || "center"}
                      align="center"
                      fontSize="sm"
                      color="gray.500"
                      minW={column.minWidth || "75px"}
                      textTransform="none"
                    >
                      {column.render("Header")}
                      {!column.disableSortBy && (
                        <Icon
                          w={{ sm: "10px", xl: "14px" }}
                          h={{ sm: "10px", xl: "14px" }}
                          color={column.isSorted ? "gray.500" : "gray.400"}
                          float="right"
                          as={
                            column.isSorted
                              ? column.isSortedDesc
                                ? TiArrowSortedDown
                                : TiArrowSortedUp
                              : TiArrowUnsorted
                          }
                        />
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {isLoading ? (
              <Tr>
                <Td
                  colSpan={headerGroups[0].headers.length}
                  textAlign="center"
                  fontSize="14px"
                  color="gray.500"
                  borderBottom="none"
                  h="425px"
                  p={4}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt="8px"
                    mb="8px"
                  >
                    <span className="loader"></span>
                  </Box>
                </Td>
              </Tr>
            ) : page.length === 0 ? (
              <Tr>
                <Td
                  colSpan={headerGroups[0].headers.length}
                  textAlign="center"
                  fontSize="14px"
                  color="gray.500"
                  borderBottom="none"
                  h={noDataBoxH}
                  p={4}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt="8px"
                    mb="8px"
                  >
                    <NotFoundIcon
                      w="80px"
                      h="80px"
                      color="rgba(55, 63, 72, 1)"
                    />
                  </Box>
                  {t("noDataForItem")}
                </Td>
              </Tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    sx={{
                      transition: "background-color 0.2s ease",
                      _hover: { backgroundColor: "gray.800" },
                    }}
                  >
                    {row.cells.map((cell, index) => (
                      <Td
                        {...cell.getCellProps()}
                        fontSize="sm"
                        key={index}
                        color="gray.500"
                        pt={2}
                        pb={2}
                        pl={3}
                        pr={3}
                        minW={cell.column.minWidth || "none"}
                        textAlign={cell.column.textAlign || "center"}
                        className="td-border-bottom"
                        whiteSpace="nowrap"
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })
            )}
            {isSum && (
              <Tr backgroundColor={"#000000"}>
                {columns.map((column, index) => (
                  <Td
                    key={index}
                    fontSize="sm"
                    color="white"
                    p={2}
                    minW="175px"
                    textAlign="center"
                    fontWeight="bold"
                  >
                    {index === 0 ? (
                      <Text color="green.500">รวม</Text>
                    ) : column.isSum ? (
                      sums[column.accessor]
                    ) : (
                      ""
                    )}
                  </Td>
                ))}
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex
        direction={{ sm: "column", xl: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        // px={{ xl: "22px" }}
      >
        <Stack
          direction={{ sm: "column", xl: "row" }}
          spacing={{ sm: "4px", xl: "12px" }}
          align="center"
          // me="12px"
          my="24px"
          minW={{ sm: "100px", xl: "200px" }}
        >
          <Text fontSize="xs" color="gray.500" fontWeight="normal">
            {t("showing")}
          </Text>
          <Select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            color="gray.500"
            size="sm"
            borderRadius="33px"
            h="38px"
            maxW="75px"
            cursor="pointer"
            bg="#1E252C"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
          </Select>
          <Text fontSize="xs" color="gray.500" fontWeight="normal">
            {t("entries")}
          </Text>
        </Stack>
        <Stack
          direction="row"
          alignSelf={stackAlignment}
          spacing="0px"
          ms={stackMarginStart}
        >
          <Button
            variant="no-hover"
            onClick={() => previousPage()}
            transition="all .5s ease"
            w="80px"
            h="40px"
            borderRadius="0px"
            bg="gray.600"
            display={
              pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
            }
            _hover={{
              bg: "gray.500",
            }}
            borderTopLeftRadius="33px"
            borderBottomLeftRadius="33px"
            border="1px solid #495058"
          >
            <Text fontSize="14px" fontWeight="normal" color="#8C9592">
              {t("previous")}
            </Text>
          </Button>
          {createPages(pageCount)
            .slice(pageChunk * pagesPerChunk, (pageChunk + 1) * pagesPerChunk)
            .map((pageNumber, index) => (
              <Button
                variant="no-hover"
                transition="all .5s ease"
                onClick={() => gotoPage(pageNumber - 1)}
                w="28px"
                h="40px"
                minW="0px"
                borderRadius="0px"
                bg={pageNumber === pageIndex + 1 ? "green.500" : "none"}
                _hover={{ bg: "gray.500" }}
                key={index}
                border="1px solid #495058"
              >
                <Text
                  fontSize="xs"
                  color={pageNumber === pageIndex + 1 ? "#fff" : "#8C9592"}
                >
                  {pageNumber}
                </Text>
              </Button>
            ))}
          <Button
            variant="no-hover"
            onClick={() => {
              if ((pageChunk + 1) * pagesPerChunk < pageCount) {
                setPageChunk(pageChunk + 1);
              }
            }}
            transition="all .5s ease"
            w="65px"
            h="40px"
            borderRadius="0px"
            borderTopRightRadius="33px"
            borderBottomRightRadius="33px"
            bg="none"
            display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
            _hover={{
              bg: "gray.500",
            }}
            border="1px solid #495058"
          >
            <Text fontSize="14px" fontWeight="normal" color="#8C9592">
              {t("next")}
            </Text>
          </Button>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default SearchTable;

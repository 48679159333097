import { useQuery } from "react-query";
import { fetchListUserAffiliateReport } from "services/member-report/affiliateReportService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useFetchAffiliateListUserReport = (username) => {
  const handleError = useErrorHandler();
  return useQuery(
    [QueryKeys.AFFILIATE_USER_LIST_REPORT, username],
    async () => {
      const response = await fetchListUserAffiliateReport({
        username: username,
      });
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );
};

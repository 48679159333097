import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { Form, Formik } from "formik";
import moment from "moment/moment";
import React from "react";
import { DEVICE_OPTIONS } from "variables/options";

const ManagePopupModal = ({
  isOpen,
  onClose,
  popup = null,
  handleSubmit,
  t,
}) => {
  const modalSize = useBreakpointValue({ base: "xl", xl: "xl" });

  const handlePopupSubmit = (values, actions) => {
    const formattedValues = {
      ...values,
      startDate: moment(values.startDate).format("YYYY/MM/DD"),
      endDate: moment(values.endDate).format("YYYY/MM/DD"),
    };

    handleSubmit(formattedValues, popup, actions);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent maxH="95vh">
        <ModalHeader
          backgroundColor="gray.800"
          borderTopRadius="12px"
          borderBottom="1px solid rgba(55, 63, 72, 1)"
          mb={4}
        >
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              {t("managePopup.title")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              name: popup?.name || "",
              content: popup?.content || "",
              actionLink: popup?.actionLink || "",
              image: popup?.image || "",
              device: popup?.device || "",
              language: popup?.language || "TH",
              sort: popup?.sort || 1,
              status: popup?.status || true,
              startDate: moment(popup?.startDate).format("yyyy-MM-DD") || "",
              endDate: moment(popup?.endDate).format("yyyy-MM-DD") || "",
            }}
            onSubmit={(values, actions) => {
              handlePopupSubmit(values, actions);
              onClose();
            }}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", xl: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={2}>
                    <Box>
                      <FormLabel>
                        {t("managePopup.imageLabel")}{" "}
                        <Box as="span" color="green.500" fontWeight="normal">
                          {t("managePopup.imageSize")}
                        </Box>
                      </FormLabel>
                      <ImageUploader
                        initialImage={values.image}
                        onUpload={(uri) => setFieldValue("image", uri)}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>{t("managePopup.nameLabel")}</FormLabel>
                      <Input
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>{t("managePopup.contentLabel")}</FormLabel>
                      <Input
                        name="content"
                        onChange={handleChange}
                        value={values.content}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>{t("managePopup.linkLabel")}</FormLabel>
                      <Input
                        name="actionLink"
                        onChange={handleChange}
                        value={values.actionLink}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, xl: 1 }}>
                    <FormControl>
                      <FormLabel>{t("managePopup.sortLabel")}</FormLabel>
                      <Input
                        name="sort"
                        onChange={handleChange}
                        value={values.sort}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, xl: 1 }}>
                    <FormControl>
                      <FormLabel>{t("managePopup.deviceLabel")}</FormLabel>
                      <Select
                        name="device"
                        onChange={handleChange}
                        value={values.device}
                        placeholder={t("managePopup.devicePlaceholder")}
                        fontSize="sm"
                        borderRadius="33px"
                      >
                        {DEVICE_OPTIONS.map((type, id) => (
                          <option key={id} value={type.val}>
                            {type.text}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, xl: 1 }}>
                    <FormControl>
                      <FormLabel>{t("managePopup.startDateLabel")}</FormLabel>
                      <Input
                        name="startDate"
                        onChange={(e) =>
                          setFieldValue("startDate", e.target.value)
                        }
                        value={values.startDate}
                        fontSize="sm"
                        type="date"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, xl: 1 }}>
                    <FormControl>
                      <FormLabel>{t("managePopup.endDateLabel")}</FormLabel>
                      <Input
                        name="endDate"
                        onChange={(e) =>
                          setFieldValue("endDate", e.target.value)
                        }
                        value={values.endDate}
                        fontSize="sm"
                        type="date"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <Flex>
                      <Switch
                        isChecked={values.status === "true"}
                        onChange={(e) =>
                          setFieldValue("status", e.target.checked.toString())
                        }
                      />
                      <Text mb={0} pl={4}>
                        {t("managePopup.statusLabel")}
                      </Text>
                    </Flex>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mb={6}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    type="submit"
                    fontWeight="normal"
                    isLoading={isSubmitting}
                    fontSize="14px"
                  >
                    {t("managePopup.submitButton")}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManagePopupModal;

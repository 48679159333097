import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { DeleteIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import { FieldArray, Form, Formik } from "formik";
import { useAffiliateSetting } from "hooks/business/system-setting/useAffiliateSetting";
import React from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { AFFILIATE_TYPE_OPTIONS, BONUS_OPTIONS } from "variables/options";

const AffiliateSetting = ({ pageName }) => {
  const { t } = useTranslation("affiliateSetting");
  const { affiliateData, handleSubmit, isSaving } = useAffiliateSetting();

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <CardHeader pb={6}>
          <Text fontSize="18px" fontWeight="bold" color="#fff">
            {t("affiliateSetting")}
          </Text>
        </CardHeader>
        <Formik
          initialValues={{
            id: affiliateData?.id || "",
            typeAffiliate: affiliateData?.typeAffiliate || "",
            typeBonus: affiliateData?.typeBonus || "",
            maxBonus: affiliateData?.maxBonus || "",
            maxWithdraw: affiliateData?.maxWithdraw || "",
            maxWallet: affiliateData?.maxWallet || "",
            img: affiliateData?.img || "",
            active: affiliateData?.active || false,
            forever: affiliateData?.forever || false,
            conditions: affiliateData?.conditions || [
              { minTopup: "", maxTopup: "", bonus: "" },
            ],
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Stack direction="column" spacing="24px" w="100%">
                  <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      p={0}
                      borderBottom="1px solid #373F48"
                      boxShadow="none"
                      pb="48px"
                      borderRadius="0px"
                    >
                      <Element id="info" name="info">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "repeat(1, 1fr)",
                              xl: "repeat(3, 1fr)",
                            }}
                            gap="24px"
                            w="100%"
                          >
                            <GridItem>
                              <Flex>
                                <Switch
                                  name="active"
                                  isChecked={values.active}
                                  onChange={handleChange}
                                />
                                <FormLabel mb="10px" ml="36px">
                                  {t("form.status.label")}
                                </FormLabel>
                              </Flex>
                            </GridItem>
                            <GridItem>
                              <Flex>
                                <Switch
                                  name="forever"
                                  isChecked={values.forever}
                                  onChange={handleChange}
                                />
                                <FormLabel mb="10px" ml="36px">
                                  {t("form.forever.label")}
                                </FormLabel>
                              </Flex>
                            </GridItem>
                            <GridItem display={{ base: "none", xl: "grid" }} />
                            <GridItem>
                              <FormControl>
                                <FormLabel>
                                  {t("form.typeAffiliate.label")}
                                </FormLabel>
                                <Select
                                  name="typeAffiliate"
                                  value={values.typeAffiliate}
                                  onChange={handleChange}
                                  fontSize="sm"
                                  borderRadius="33px"
                                  placeholder={t(
                                    "form.typeAffiliate.placeholder",
                                  )}
                                >
                                  {AFFILIATE_TYPE_OPTIONS(t).map(
                                    (item, index) => (
                                      <option key={index} value={item.typeCode}>
                                        {item.typeName}
                                      </option>
                                    ),
                                  )}
                                </Select>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel>
                                  {t("form.typeBonus.label")}
                                </FormLabel>
                                <Select
                                  name="typeBonus"
                                  value={values.typeBonus}
                                  onChange={handleChange}
                                  fontSize="sm"
                                  borderRadius="33px"
                                  placeholder={t("form.typeBonus.placeholder")}
                                >
                                  {BONUS_OPTIONS(t).map((item, index) => (
                                    <option key={index} value={item.typeCode}>
                                      {item.typeName}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel>
                                  {t("form.maxBonus.label")}
                                </FormLabel>
                                <NumberInput
                                  value={values.maxBonus}
                                  onChange={(valueString) =>
                                    setFieldValue("maxBonus", valueString)
                                  }
                                >
                                  <NumberInputField
                                    name="maxBonus"
                                    placeholder={t("form.maxBonus.placeholder")}
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel>
                                  {t("form.maxWithdraw.label")}{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    {t("form.maxWithdraw.notice")}
                                  </Box>
                                </FormLabel>
                                <NumberInput
                                  value={values.maxWithdraw}
                                  onChange={(valueString) =>
                                    setFieldValue("maxWithdraw", valueString)
                                  }
                                >
                                  <NumberInputField
                                    name="maxWithdraw"
                                    placeholder={t(
                                      "form.maxWithdraw.placeholder",
                                    )}
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel>
                                  {t("form.maxWallet.label")}
                                </FormLabel>
                                <NumberInput
                                  value={values.maxWallet}
                                  onChange={(valueString) =>
                                    setFieldValue("maxWallet", valueString)
                                  }
                                >
                                  <NumberInputField
                                    name="maxWallet"
                                    placeholder={t(
                                      "form.maxWallet.placeholder",
                                    )}
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Card>
                  </Animated>
                  <FieldArray name="conditions">
                    {({ push, remove }) => (
                      <>
                        <Flex justify="space-between">
                          <FormLabel
                            fontSize="lg"
                            fontWeight="bold"
                            color="#fff"
                          >
                            {t("form.conditions.label")}
                          </FormLabel>
                          <Button
                            variant="outline"
                            borderColor={"green.500"}
                            color={"#fff"}
                            bg={"#1E252C"}
                            size="md"
                            borderRadius="33px"
                            fontWeight="normal"
                            onClick={() =>
                              push({
                                minTopup: "",
                                maxTopup: "",
                                bonus: "",
                              })
                            }
                          >
                            {t("buttons.addCondition")}
                          </Button>
                        </Flex>
                        <Animated
                          animation="fadeInUp"
                          duration="1.0s"
                          delay="0.1s"
                        >
                          <Card
                            w={{ sm: "100%" }}
                            alignSelf="flex-end"
                            justifySelf="flex-end"
                            p={0}
                            boxShadow="none"
                          >
                            <Element id="info" name="info">
                              <CardBody>
                                <Grid
                                  templateColumns={{
                                    base: "repeat(3, 1fr) auto",
                                    xl: "repeat(3, 1fr) auto",
                                  }}
                                  gap="10px"
                                  w="100%"
                                >
                                  <GridItem colSpan={4}>
                                    <Grid
                                      templateColumns={{
                                        base: "repeat(3, 1fr) auto",
                                        xl: "repeat(3, 1fr) auto",
                                      }}
                                      pr={8}
                                    >
                                      <GridItem
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text>
                                          {t("form.conditions.minTopup.label")}
                                        </Text>
                                      </GridItem>
                                      <GridItem
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text>
                                          {t("form.conditions.maxTopup.label")}
                                        </Text>
                                      </GridItem>
                                      <GridItem
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text>
                                          {t("form.conditions.bonus.label")}
                                        </Text>
                                      </GridItem>
                                      <GridItem />
                                    </Grid>
                                  </GridItem>
                                  {values.conditions.map((condition, index) => (
                                    <React.Fragment key={index}>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <NumberInput
                                            value={condition.minTopup}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `conditions.${index}.minTopup`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`conditions.${index}.minTopup`}
                                              placeholder={t(
                                                "form.conditions.minTopup.placeholder",
                                              )}
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <NumberInput
                                            value={condition.maxTopup}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `conditions.${index}.maxTopup`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`conditions.${index}.maxTopup`}
                                              placeholder={t(
                                                "form.conditions.maxTopup.placeholder",
                                              )}
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <NumberInput
                                            value={condition.bonus}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `conditions.${index}.bonus`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`conditions.${index}.bonus`}
                                              placeholder={t(
                                                "form.conditions.bonus.placeholder",
                                              )}
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem
                                        colSpan={1}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="end"
                                      >
                                        <IconButton
                                          variant="outline"
                                          borderColor={"red.500"}
                                          color={"red.500"}
                                          bg="#1E252C"
                                          aria-label="delete"
                                          size="md"
                                          icon={<DeleteIcon />}
                                          onClick={() => remove(index)}
                                        />
                                      </GridItem>
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </CardBody>
                            </Element>
                          </Card>
                        </Animated>
                      </>
                    )}
                  </FieldArray>
                  <Animated
                    justify="flex-start"
                    pt="15px"
                    duration={0.4}
                    delay={1.0}
                  >
                    <Button
                      type="submit"
                      width={{ base: "100%", xl: "auto" }}
                      minW={{ base: "none", xl: "150px" }}
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      borderRadius="33px"
                      fontSize="14px"
                      fontWeight="normal"
                      isLoading={isSaving}
                    >
                      บันทึกข้อมูล
                    </Button>
                  </Animated>
                </Stack>
              </Flex>
            </Form>
          )}
        </Formik>
      </Card>
    </Flex>
  );
};
export default AffiliateSetting;

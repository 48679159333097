import { Badge, Flex, Text } from "@chakra-ui/react";
import { formatDate } from "utils/DateformatUtils";

export const memberTransferLogReportColumnsData = () => {
  return [
    {
      Header: "รหัสอ้างอิง",
      accessor: "referenceId",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "สถานะ",
      accessor: "message",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            bg={
              cell.value === "SUCCESS" ||
              cell.value === "No error." ||
              cell.value === "ZERO" ||
              cell.value === "success"
                ? "green.500"
                : "red.500"
            }
            w="65px"
            h="28px"
            color="#fff"
            fontSize="12px"
            fontWeight="normal"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {cell.value === "SUCCESS" ||
            cell.value === "No error." ||
            cell.value === "ZERO" ||
            cell.value === "success"
              ? "สำเร็จ"
              : "ไม่สำเร็จ"}
          </Badge>
        </Flex>
      ),
    },
    // {
    //   Header: "ชื่อผู้ใช้งาน",
    //   accessor: "username",
    // },
    {
      Header: "จำนวนเงิน",
      accessor: "amount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "ยอดคงเหลือ",
      accessor: "balance",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "โอนจาก",
      accessor: "source",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => {
        return value === "Main" ? "กระเป๋าหลัก" : value;
      },
    },
    {
      Header: "โอนไปยัง",
      accessor: "target",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => {
        return value === "Main" ? "กระเป๋าหลัก" : value;
      },
    },
    {
      Header: "วันที่และเวลาทำรายการ",
      accessor: "updatedDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};

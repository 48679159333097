import { CopyIcon } from "@chakra-ui/icons";
import { Badge, Flex, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { UnlockIcon } from "components/Icons/Icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateformatUtils";

export const memberTurnoverReportColumnsData = (handleModalOpen, t) => {
  return [
    {
      Header: t("headers.username"),
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);
        return (
          <Flex align="center">
            <IconButton
              icon={<CopyIcon color="green.500" />}
              _hover={{ boxShadow: "none", bg: "none" }}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: t("headers.targetTurnover"),
      accessor: "turnover",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.turnoverAmount"),
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: () => (
        <Text color="#fff" fontWeight="bold">
          0.00
        </Text>
      ),
    },
    {
      Header: t("headers.transactionDate"),
      accessor: "updatedDate",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => <Text>{formatDate(value)}</Text>,
    },
    {
      Header: t("headers.status"),
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: () => (
        <Flex align="center" justify="right">
          <Badge
            bg="#B86410"
            color="#fff"
            w="105px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="normal"
            fontSize="12px"
          >
            ติดเทริ์นโอเวอร์
          </Badge>
        </Flex>
      ),
    },
    {
      Header: t("headers.action"),
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          aria-label="remove credit"
          size="sm"
          borderRadius="28px"
          w="40px"
          h="30px"
          borderColor="rgba(55, 63, 72, 1)"
          bg="rgba(29, 34, 41, 1)"
          icon={<UnlockIcon h="16px" w="16px" color="green.500" />}
          onClick={() => handleModalOpen("clearTurnoverModal", row.original)}
          mr="6px"
        />
      ),
    },
  ];
};

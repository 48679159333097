import { useState } from "react";
import { useQuery } from "react-query";
import { fetchListMarketingReport } from "services/website-report/marketingReportService";
import { mapJsonToTableData } from "utils/CommonUtils";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useMarketingReport = (search, columnsData) => {
  const handleError = useErrorHandler();
  const [downloadableData, setDownloadableData] = useState([]);

  const queryKey = [
    QueryKeys.MARKETING_REPORT,
    search.createdDateFrom,
    search.createdDateTo,
  ];

  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      const response = await fetchListMarketingReport(search);
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
      onSuccess: (data) => {
        const formattedRecords = mapJsonToTableData(data, columnsData);
        setDownloadableData(formattedRecords);
      },
    },
  );

  return {
    data,
    isLoading,
    downloadableData,
  };
};

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField } from "utils/CommonUtils";
import { GameTypes, MistakeCreditTypes } from "variables/types";

const AddCreditModal = ({ isOpen, onClose, member, handleSubmit, t }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              {t("addCreditModal.title")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={0}>
          <Text
            fontSize="16px"
            color="green.500"
            fontWeight="bold"
            pt={4}
            pb={4}
          >
            {t("addCreditModal.subtitle")}
          </Text>
          <Formik
            initialValues={{
              type: MistakeCreditTypes.ADD_CREDIT,
              username: member?.username,
              credit: "",
              reason: "",
              turnOver: 0,
              minWithdraw: 0,
              bonus: 0,
              typeGame: GameTypes.ALL,
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Field name="credit" validate={validateField}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.credit && form.touched.credit}
                        isRequired
                      >
                        <FormLabel>{t("addCreditModal.amount")}</FormLabel>
                        <NumberInput
                          defaultValue={0}
                          precision={2}
                          step={0.2}
                          value={props.values.credit}
                          onChange={(valueString) =>
                            props.setFieldValue("credit", valueString)
                          }
                        >
                          <NumberInputField {...field} fontSize="16px" />
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bonus">
                    {({ field }) => (
                      <FormControl mt={2}>
                        <FormLabel>{t("addCreditModal.bonusAmount")}</FormLabel>
                        <NumberInput
                          value={props.values.bonus}
                          onChange={(valueString) =>
                            props.setFieldValue("bonus", valueString)
                          }
                        >
                          <NumberInputField {...field} fontSize="16px" />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="turnOver">
                    {({ field }) => (
                      <FormControl mt={2}>
                        <FormLabel>{t("addCreditModal.turnover")}</FormLabel>
                        <NumberInput
                          value={props.values.turnOver}
                          onChange={(valueString) =>
                            props.setFieldValue("turnOver", valueString)
                          }
                        >
                          <NumberInputField {...field} fontSize="16px" />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="minWithdraw">
                    {({ field, form }) => (
                      <FormControl
                        mt={2}
                        isInvalid={
                          form.errors.minWithdraw && form.touched.minWithdraw
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("addCreditModal.minimumWithdraw")}
                        </FormLabel>
                        <NumberInput
                          defaultValue={0}
                          precision={2}
                          step={0.2}
                          value={props.values.minWithdraw}
                          onChange={(valueString) =>
                            props.setFieldValue("minWithdraw", valueString)
                          }
                        >
                          <NumberInputField {...field} fontSize="16px" />
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="maxWithdraw">
                    {({ field, form }) => (
                      <FormControl
                        mt={2}
                        isInvalid={
                          form.errors.maxWithdraw && form.touched.maxWithdraw
                        }
                        isRequired
                      >
                        <FormLabel>{t("addCreditModal.finalCredit")}</FormLabel>
                        <NumberInput
                          defaultValue={0}
                          precision={2}
                          step={0.2}
                          value={props.values.maxWithdraw}
                          onChange={(valueString) =>
                            props.setFieldValue("maxWithdraw", valueString)
                          }
                        >
                          <NumberInputField {...field} fontSize="16px" />
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="reason" validate={validateField}>
                    {({ field, form }) => (
                      <FormControl
                        mt={2}
                        isInvalid={form.errors.reason && form.touched.reason}
                        isRequired
                      >
                        <FormLabel>{t("addCreditModal.note")}</FormLabel>
                        <Input
                          {...field}
                          placeholder="หมายเหตุ"
                          fontSize="16px"
                        />
                      </FormControl>
                    )}
                  </Field>
                  <ModalFooter pl={0} pr={0} mb={6}>
                    <Button
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      w="100%"
                      fontWeight="normal"
                      isLoading={props.isSubmitting}
                      disabled={props.isSubmitting}
                      type="submit"
                      fontSize="14px"
                    >
                      {t("addCreditModal.submit")}
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCreditModal;

import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { useTranslation } from "react-i18next";
import { QueryClientProvider, useQueryClient } from "react-query";
import { newMemberTodayColumnsData } from "variables/columns/member-report/newMemberTodayColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { MembersSearchTypes } from "variables/types";

const NewMemberTodayReport = ({ pageName }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("memberTodayReport");

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;
  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.ALL,
    newMemberTodayColumnsData(t),
  );
  const { search, setSearch } = useSearch(dateFieldsNames, dateSearchFormat);

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex direction="column" justifyContent="center">
            <Text color="#fff" fontSize="18px" fontWeight="bold">
              {t("memberToday")}
            </Text>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={QueryKeys.NEW_MEMBER_TODAY_REPORT}
                columnsData={newMemberTodayColumnsData(t)}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default NewMemberTodayReport;

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Stack,
  Switch, // Image,
  Text,
} from "@chakra-ui/react";
import tw from "assets/img/banks/ทรูวอเล็ต.png";
import { DeleteIcon, EditIcon } from "components/Icons/Icons";
import React from "react";

import { formatPhoneNumber } from "../../../../utils/CommonUtils";

const TrueWalletCard = ({ trueWallet, onEdit, onDelete, t }) => {
  return (
    <Box borderRadius="10px" overflow="hidden" bg="#1E252C">
      <Box h="285px" bg="#171D23" borderRadius="10px" p={6}>
        <Flex align="center" pb="18px">
          <Image
            src={tw}
            // p={2}
            boxSize="50px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
          <Stack spacing={1} align="flex-start" ml={6}>
            <Text fontWeight="bold" fontSize="18px">
              {t("trueWallet")}
            </Text>
          </Stack>
        </Flex>

        <Flex
          direction="column"
          align="flex-start"
          mt="12px"
          borderBottom="1px solid #373F48"
          pb="18px"
        >
          <Text fontSize="14px" color="gray.400">
            {t("form.phoneNumber")}
          </Text>

          <Text fontSize="29px" fontWeight="bold" color="green.500">
            {formatPhoneNumber(trueWallet?.phoneNumber, "dashes")}
          </Text>
        </Flex>

        <Stack spacing={1} align="flex-start" mt="16px">
          <Text fontSize="14px" color="#8C9592">
            {t("form.name")}
          </Text>
          <Text fontSize="18px" color="#8C9592" fontWeight="bold">
            {trueWallet?.name}
          </Text>
        </Stack>
      </Box>
      <Box p={6}>
        <Box
          border="1px solid #373F48"
          borderRadius="6px"
          px={4}
          bg="#252d35"
          mt={2}
        >
          <Flex justify="space-between" p={3} mx={-4}>
            <Text fontWeight="medium" fontSize="sm">
              {t("manageTrueWalletModal.statusLabel")}
            </Text>
            <Switch isChecked={trueWallet?.active} readOnly />
          </Flex>
        </Box>
        <Flex justify="space-between" align="center">
          <Text fontSize="14px">{t("manageTrueWalletText")}</Text>
          <Flex mt={4}>
            <IconButton
              icon={<Icon as={EditIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              variant="solid"
              color="#fff"
              bg="green.500"
              size="sm"
              onClick={onEdit}
              mr={2}
            />
            <IconButton
              icon={<Icon as={DeleteIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              color="#fff"
              bg="red.500"
              variant="solid"
              onClick={onDelete}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default TrueWalletCard;

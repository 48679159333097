import { extendTheme } from "@chakra-ui/react";

import { CardComponent } from "./additions/card/Card";
import { CardBodyComponent } from "./additions/card/CardBody";
import { CardFooterComponent } from "./additions/card/CardFooter";
import { CardHeaderComponent } from "./additions/card/CardHeader";
import { MainPanelComponent } from "./additions/layout/MainPanel";
import { PanelContainerComponent } from "./additions/layout/PanelContainer";
import { PanelContentComponent } from "./additions/layout/PanelContent";
import { badgeStyles } from "./components/badge";
import { buttonStyles } from "./components/button";
import { checkboxStyles } from "./components/checkbox";
import { drawerStyles } from "./components/drawer";
import { inputStyles } from "./components/input";
import { labelStyles } from "./components/label";
import { linkStyles } from "./components/link";
import { modalStyles } from "./components/modal";
import { radioStyles } from "./components/radio";
import { selectStyles } from "./components/select";
import { switchStyles } from "./components/switch";
import { textStyles } from "./components/text";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";

// import { mode } from "@chakra-ui/theme-tools";
export default extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  buttonStyles, // Button styles
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  drawerStyles, // Sidebar variant for Chakra's drawer
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardFooterComponent, // Card footer component
  CardHeaderComponent, // Card Header component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent, // Panel Container component
  switchStyles,
  inputStyles,
  modalStyles,
  selectStyles,
  textStyles,
  labelStyles,
  radioStyles,
  checkboxStyles,
);

import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs, // Image,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import DonutChart from "components/Charts/DonutChart";
import LineChart from "components/Charts/LineChart";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import { EditIcon, UserIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import { SimpleHSeparator } from "components/Separator/Separator";
import SearchTable from "components/Tables/SearchTable";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { format } from "date-fns";
import { useFetchAffiliateListUserReport } from "hooks/business/member-report/useFetchAffiliateListUserReport";
import { useMemberReceivePromotion } from "hooks/business/member-report/useMemberRecievePromotion";
import { useMemberTransactionReport } from "hooks/business/member-report/useMemberTransactionReport";
import { useDepositTransaction } from "hooks/business/transaction-report/useDepositTransaction";
import { useWithdrawTransaction } from "hooks/business/transaction-report/useWithdrawTransaction";
import { useMemberTransferLog } from "hooks/business/useMemberTransferLog";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { useSearch } from "hooks/common/useSearch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  fetchBalanceAllProvider,
  fetchMemberInfo,
  fetchMemberTransactionGraph,
} from "services/member-management/memberProfileService";
import { createLineChartOptions } from "utils/ChartUtils";
import { getMemberLevelColorScheme } from "utils/CommonUtils";
import { formatDate, formatDateString } from "utils/DateformatUtils";
import { banks } from "variables/bank";
import { pieChartOptions } from "variables/charts";
import { affiliateListUserReportColumnsData } from "variables/columns/member-profile/affiliateListUserReportColumnsData";
import { memberDepositTransactionReportColumnsData } from "variables/columns/member-profile/memberDepositTransactionReportColumnsData";
import { memberProfileReceivePromotionReportColumnsData } from "variables/columns/member-profile/memberProfileReceivePromotionReportColumnsData";
import { memberRefundWinLoseReportColumnsData } from "variables/columns/member-profile/memberRefundWinLoseReportColumnsData";
import { memberWithdrawTransactionReportColumnsData } from "variables/columns/member-profile/memberWithdrawTransactionReportColumnsData";
import { memberTransferLogReportColumnsData } from "variables/columns/member-report/memberTransferLogReportColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE, ERROR_MESSAGE } from "variables/system";

import {
  DepositTransactionTypes,
  ProviderTypes,
} from "../../../variables/types";
import ResetPasswordModal from "../../member-management/members/components/ResetPasswordModal";
import ClearTurnoverModal from "./components/ClearTurnoverModal";
import { MemberProfileCard } from "./components/MemberProfileCard";
import { MemberSummaryCard } from "./components/MemberSummaryCard";
import MinWithdrawModal from "./components/MinWithdrawModal";
import WithdrawCreditModal from "./components/WithdrawCreditModal";

const MemberProfile = () => {
  const { username } = useParams();
  const { t } = useTranslation("member-management");

  const [memberData, setMemberData] = useState(null);
  const [isLoading2, setIsLoading2] = useState(true);

  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    withdrawCreditModalOpen: false,
    minWithdrawModalOpen: false,
    resetPasswordModalOpen: false,
    clearTurnoverModalOpen: false,
  });

  const [chartOptions, setChartOptions] = useState({});
  const [memberLineData, setMemberLineData] = useState([]);

  const [transactionSummary, setTransactionSummary] = useState({
    deposit: 0,
    depositCount: 0,
    withdraw: 0,
    withdrawCount: 0,
    profitLoss: 0,
  });

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const handleModalOpen = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
  };

  const fetchMemberProfileData = () => {
    const req = {
      username: username,
    };
    fetchMemberInfo(req)
      .then((response) => {
        if (response.status === 200) {
          setMemberData({
            ...response.data.data,
            username,
          });
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchMemberTransactionGraphData = () => {
    const req = {
      username: username,
    };

    setIsLoading2(true);
    fetchMemberTransactionGraph(req)
      .then((response) => {
        if (response.status === 200) {
          const newChartOptions = createLineChartOptions({
            xAxisCategories: response.data.data.labelsDeposit,
          });
          setChartOptions(newChartOptions);
          setMemberLineData([
            {
              name: "Deposit",
              data: response.data.data.dataDeposit,
            },
            {
              name: "Withdraw",
              data: response.data.data.dataWithdraw,
            },
          ]);
          setIsLoading2(false);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const [providersBalance, setProvidersBalance] = useState([]);
  const fetchAllProviderBalance = () => {
    const req = {
      username: username,
    };
    fetchBalanceAllProvider(req)
      .then((res) => {
        setProvidersBalance(res.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const allProviders = [
    {
      key: "ufabet",
      name: ProviderTypes.UFABET,
      username: memberData?.ufaUsername,
    },
    {
      key: "betflix",
      name: ProviderTypes.BETFLIX,
      username: memberData?.betflixUsername,
    },
    {
      key: "ambking",
      name: ProviderTypes.AMBKING,
      username: memberData?.ambUsername,
    },
  ];
  const memberCards = allProviders.map((provider) => {
    if (provider.username && !provider.username.includes("null")) {
      return (
        <MemberProfileCard
          key={provider.key}
          provider={provider.name}
          username={provider.username}
          balance={
            providersBalance.find(
              (balance) => balance.provider === provider.name,
            )?.balance || 0
          }
        />
      );
    }
  });

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    null,
    null,
    true,
  );

  const { fetchData: fetchMemberTransferLogData } =
    useMemberTransferLog(username);

  const { fetchData: fetchMemberSummaryData } = useMemberTransactionReport([]);
  const { data: affiliateUserList, isLoading } =
    useFetchAffiliateListUserReport(username);

  const { fetchData: fetchMemberDepositHistory } = useDepositTransaction(
    role,
    [QueryKeys.DEPOSIT_TRANSACTION_REPORT, username],
    [],
    DepositTransactionTypes.ALL,
  );

  const { fetchData: fetchMemberWithdrawHistory } = useWithdrawTransaction(
    role,
    [QueryKeys.WITHDRAW_TRANSACTION_REPORT, username],
    [],
  );

  const { fetchData: fetchMemberReceivePromotion } =
    useMemberReceivePromotion(role);

  const pieChartData = [
    transactionSummary.deposit,
    transactionSummary.withdraw,
  ];

  useEffect(() => {
    fetchMemberProfileData();
    fetchMemberTransactionGraphData();
    fetchAllProviderBalance();
  }, []);

  // Txn summary useEffect
  useEffect(() => {
    const { createdDateFrom, createdDateTo, ...rest } = search;

    fetchMemberSummaryData(0, 1, {
      ...rest,
      searchValue: username,
      listDateFrom: formatDateString(createdDateFrom),
      listDateTo: formatDateString(createdDateTo),
    }).then((response) => {
      const result = response?.results?.[0];
      if (result) {
        setTransactionSummary({
          deposit: result.deposit,
          depositCount: result.depositCount,
          withdraw: result.withdraw,
          withdrawCount: result.withdrawCount,
          profitLoss: result.profitLoss,
        });
      } else {
        setTransactionSummary({
          deposit: 0,
          depositCount: 0,
          withdraw: 0,
          withdrawCount: 0,
          profitLoss: 0,
        });
      }
    });
  }, [search]);

  return (
    <Flex direction="column" pt={{ sm: "0px", xl: "5px" }} overflow="hidden">
      <Flex
        justifyContent="space-between"
        alignItems="left"
        direction={{ base: "column", xl: "row" }}
      >
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <PageHeader pageName={"โปรไฟล์สมาชิก"} t={t} />
        </Animated>
        <CustomRangePicker
          selectedDates={selectedDates}
          onDateChange={handleDateChange}
        />
      </Flex>
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <Grid templateColumns={{ base: "1fr", xl: "1fr 2fr" }} gap={4}>
          <GridItem>
            <Card bg="#1D2229" border="1px solid #373F48">
              <Flex direction="column" align="center" px={6} py={3}>
                <Flex justifyContent="center" w="100%">
                  <Icon
                    as={UserIcon}
                    mb={2}
                    h="60px"
                    w="60px"
                    color="green.500"
                  />
                </Flex>
                <Text
                  fontSize="14px"
                  color={memberData?.memberTagsColor || "#fff"}
                  alignSelf="center"
                  fontWeight="bold"
                >
                  {username}
                </Text>
                <Text mb={2} alignSelf="center">
                  {memberData?.name}
                </Text>
                <Flex justifyContent="space-between" w="100%">
                  <Box justifyItems="center">
                    <Text>เครดิตคงเหลือ (กระเป๋าหลัก)</Text>
                    <Text color="#fff">0.00</Text>
                  </Box>
                  <Box justifyItems="center">
                    <Text>ระดับสมาชิก</Text>
                    <Badge
                      colorScheme={getMemberLevelColorScheme(
                        memberData?.memberLevelName,
                      )}
                      borderRadius="full"
                      h="22px"
                      w="74px"
                      mb={2}
                      textAlign="center"
                      fontWeight="normal"
                      border="1px solid #373F48"
                    >
                      {memberData?.memberLevelName != null
                        ? memberData?.memberLevelName
                        : "-"}
                    </Badge>
                  </Box>
                </Flex>
                <Flex>
                  <Button
                    mt={{ base: "12px", xl: "0px" }}
                    borderColor={"green.500"}
                    color={"green.500"}
                    w="136px"
                    h="35px"
                    variant="outline"
                    fontSize="14px"
                    fontWeight="normal"
                    mr={2}
                  >
                    แก้ไขข้อมูล
                  </Button>
                </Flex>
              </Flex>
              <SimpleHSeparator />
              <Box py={6}>
                <Text>ยูสเซอร์</Text>
                <Text>{memberData?.username}</Text>
                <Flex justifyContent="space-between" mt={4}>
                  <Box>
                    <Text>รหัสผ่าน</Text>
                    <Text>********</Text>
                  </Box>
                  <IconButton
                    aria-label="edit"
                    bg={"green.500"}
                    w="35px"
                    variant="solid"
                    fontSize="14px"
                    fontWeight="normal"
                    onClick={() => {
                      handleModalOpen("resetPasswordModal");
                    }}
                    icon={<EditIcon h="14px" w="14px" color="#fff" />}
                  />
                </Flex>
              </Box>
              <SimpleHSeparator />
              <Box py={6}>
                <Text>ธนาคาร</Text>
                <Flex>
                  <Image
                    src={`${banks[memberData?.bankName]?.logo}`}
                    p={2}
                    backgroundColor={banks[memberData?.bankName]?.color}
                    boxSize="35px"
                    borderRadius="50%"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    mr={2}
                  />
                  <Text alignSelf="center">
                    {banks[memberData?.bankName]?.name_th}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" mt={4}>
                  <Box>
                    <Text fontWeight="normal" fontSize="14px">
                      เลขที่บัญชีธนาคาร
                    </Text>
                    <Text fontSize="18px" color="#fff" fontWeight="bold">
                      {memberData?.bankAccount}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="normal" fontSize="14px">
                      ชื่อบัญชี
                    </Text>
                    <Text fontSize="18px" color="#fff" fontWeight="bold">
                      {memberData?.name}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <SimpleHSeparator />
              <Grid templateColumns="1fr 1fr" gap={4} py={6}>
                <Box>
                  <Text fontSize="12px">สมัครสมาชิกเมื่อ</Text>
                  <Text fontSize="14px" color="#fff">
                    {formatDate(memberData?.createDate)}
                  </Text>
                </Box>
                <Box textAlign="right">
                  <Text fontSize="12px">เข้าใช้งานล่าสุด</Text>
                  <Text fontSize="14px" color="#fff">
                    {formatDate(memberData?.lastLoginDate)}
                  </Text>
                </Box>
              </Grid>
              <SimpleHSeparator />
              <Grid templateColumns="1fr 1fr" gap={4} py={6}>
                <Box>
                  <Text fontSize="12px">แหล่งที่มา การตลาด</Text>
                  <Text fontSize="14px" color="#fff">
                    {memberData?.marketing !== null
                      ? memberData?.marketing
                      : "-"}
                  </Text>
                </Box>
                <Box textAlign="right">
                  <Text fontSize="12px">แหล่งที่มา แนะนำเพื่อน</Text>
                  <Text fontSize="14px" color="#fff">
                    {" "}
                    {memberData?.affiliateName !== null
                      ? memberData?.affiliateName
                      : "-"}
                  </Text>
                </Box>
              </Grid>
            </Card>
          </GridItem>
          <GridItem>
            <Grid
              templateColumns={{ base: "1fr", xl: "repeat(3, 1fr)" }}
              gap={4}
            >
              <GridItem colSpan={3}>
                <Tabs variant="enclosed" isFitted>
                  <TabList mb="1em">
                    <Tab>ภาพรวม</Tab>
                    <Tab>ฝาก/ถอน</Tab>
                    <Tab>อื่นๆ</Tab>
                  </TabList>
                  {/* Tab 1 */}
                  <TabPanels>
                    <TabPanel>
                      <Text
                        fontSize="24px"
                        color="green.500"
                        fontWeight="bold"
                        mb={4}
                      >
                        ภาพรวม
                      </Text>
                      <Grid
                        templateColumns={{ base: "1fr", xl: "repeat(2, 1fr)" }}
                        gap="10px"
                      >
                        <Card h="385px">
                          <DonutChart
                            key={JSON.stringify(pieChartData)}
                            chartData={pieChartData}
                            chartOptions={pieChartOptions}
                          />
                        </Card>
                        <Box>
                          <MemberSummaryCard
                            title={"ยอดฝาก"}
                            date={`${
                              selectedDates[0]
                                ? format(selectedDates[0], "dd/MM/yyyy")
                                : ""
                            } - ${
                              selectedDates[1]
                                ? format(selectedDates[1], "dd/MM/yyyy")
                                : ""
                            }`}
                            amount={transactionSummary.deposit}
                            count={transactionSummary.depositCount}
                          />
                          <MemberSummaryCard
                            title={"ยอดถอน"}
                            date={`${
                              selectedDates[0]
                                ? format(selectedDates[0], "dd/MM/yyyy")
                                : ""
                            } - ${
                              selectedDates[1]
                                ? format(selectedDates[1], "dd/MM/yyyy")
                                : ""
                            }`}
                            amount={transactionSummary.withdraw}
                            count={transactionSummary.withdrawCount}
                          />
                          <MemberSummaryCard
                            title={"กำไร"}
                            date={`${
                              selectedDates[0]
                                ? format(selectedDates[0], "dd/MM/yyyy")
                                : ""
                            } - ${
                              selectedDates[1]
                                ? format(selectedDates[1], "dd/MM/yyyy")
                                : ""
                            }`}
                            amount={transactionSummary.profitLoss}
                          />
                        </Box>
                      </Grid>
                      <Animated
                        animation="fadeInUp"
                        duration="0.8s"
                        delay="0.1s"
                      >
                        <Grid
                          templateColumns={{
                            sm: "1fr",
                            xl: `repeat(${memberCards.length}, 1fr)`,
                          }}
                          gap={{ base: "0px", xl: "24px" }}
                        >
                          {memberCards}
                        </Grid>
                      </Animated>
                      <Animated duration={0.4} delay={1.2}>
                        <Card mt="24px">
                          <CardHeader>
                            <Flex
                              direction={{ base: "column", xl: "row" }}
                              justifyContent="space-between"
                            >
                              <Text
                                fontSize="18px"
                                fontWeight="bold"
                                color="#fff"
                                pb={{ base: "14px", xl: "0px" }}
                              >
                                รายการโยกเครดิต
                              </Text>
                            </Flex>
                          </CardHeader>
                          <CardBody>
                            <Animated
                              w="100%"
                              h="100%"
                              duration="1.0s"
                              delay="0.1s"
                            >
                              <SearchTableRemote
                                query={"wtf"}
                                columnsData={memberTransferLogReportColumnsData()}
                                fetchData={fetchMemberTransferLogData}
                                search={search}
                                setSearch={setSearch}
                                showExport={false}
                                showAction={false}
                                isSearchable={false}
                                noDataBoxH="275px"
                              />
                            </Animated>
                          </CardBody>
                        </Card>
                      </Animated>
                    </TabPanel>
                    {/* Tab 2 */}
                    <TabPanel>
                      <Text
                        fontSize="24px"
                        color="green.500"
                        fontWeight="bold"
                        mb={4}
                      >
                        ฝาก/ถอน
                      </Text>
                      <Card>
                        <CardHeader display="flex" flexDirection="column">
                          <Text color="white" fontSize="lg" fontWeight="bold">
                            รายงาน ฝาก/ถอน
                          </Text>
                          <Text fontSize="10px" color="#8C9592" mt="4px">
                            ย้อนหลัง 7 วัน
                          </Text>
                        </CardHeader>
                        <Animated
                          w="100%"
                          h="100%"
                          duration="0.6s"
                          delay="0.1s"
                        >
                          {isLoading2 ? null : (
                            <LineChart
                              chartData={memberLineData}
                              chartOptions={chartOptions}
                            />
                          )}
                        </Animated>
                      </Card>
                      <Card mt="24px">
                        <CardHeader display="flex" flexDirection="column">
                          <Text color="white" fontSize="lg" fontWeight="bold">
                            รายงานฝาก
                          </Text>
                        </CardHeader>
                        <CardBody>
                          <Animated
                            w="100%"
                            h="100%"
                            duration="1.0s"
                            delay="0.1s"
                          >
                            <SearchTableRemote
                              query={[
                                QueryKeys.DEPOSIT_TRANSACTION_REPORT,
                                username,
                              ]}
                              columnsData={memberDepositTransactionReportColumnsData(
                                t,
                              )}
                              fetchData={fetchMemberDepositHistory}
                              search={{ ...search, searchValue: username }}
                              setSearch={setSearch}
                              showExport={false}
                              showAction={false}
                              isSearchable={false}
                              noDataBoxH="275px"
                            />
                          </Animated>
                        </CardBody>
                      </Card>
                      <Card mt="24px">
                        <CardHeader display="flex" flexDirection="column">
                          <Text color="white" fontSize="lg" fontWeight="bold">
                            รายงานถอน
                          </Text>
                        </CardHeader>
                        <CardBody>
                          <Animated
                            w="100%"
                            h="100%"
                            duration="1.0s"
                            delay="0.1s"
                          >
                            <SearchTableRemote
                              query={[
                                QueryKeys.WITHDRAW_TRANSACTION_REPORT,
                                username,
                              ]}
                              columnsData={memberWithdrawTransactionReportColumnsData(
                                t,
                              )}
                              fetchData={fetchMemberWithdrawHistory}
                              search={{ ...search, searchValue: username }}
                              setSearch={setSearch}
                              showExport={false}
                              showAction={false}
                              isSearchable={false}
                              noDataBoxH="275px"
                            />
                          </Animated>
                        </CardBody>
                      </Card>
                    </TabPanel>
                    {/* Tab 3 */}
                    <TabPanel>
                      <Text
                        fontSize="24px"
                        color="green.500"
                        fontWeight="bold"
                        mb={4}
                      >
                        อื่นๆ
                      </Text>
                      <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
                        <Card bg="#1D2229" border="1px solid #373F48">
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            direction={{ base: "column", xl: "row" }}
                          >
                            <Stack alignSelf="flex-start">
                              <Text fontSize="16px">เทริน์โอเวอร์</Text>
                              <Text
                                textAlign="left"
                                fontSize="24px"
                                color="#fff"
                                fontWeight="bold"
                              >
                                ฿ {memberData?.turnOver}
                              </Text>
                            </Stack>
                            <Button
                              mt={{ base: "12px", xl: "0px" }}
                              borderColor={"green.500"}
                              color={"green.500"}
                              variant="outline"
                              fontSize="14px"
                              fontWeight="normal"
                              w="136px"
                              h="42px"
                              onClick={() =>
                                handleModalOpen("clearTurnoverModal")
                              }
                            >
                              เคลียร์ยอดเทิร์น
                            </Button>
                          </Flex>
                        </Card>
                        <Card bg="#1D2229" border="1px solid #373F48">
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            direction={{ base: "column", xl: "row" }}
                          >
                            <Stack alignSelf="flex-start">
                              <Text fontSize="16px">ถอนขั้นต่ำ</Text>
                              <Text
                                textAlign="left"
                                fontSize="24px"
                                color="#fff"
                                fontWeight="bold"
                              >
                                ฿ {memberData?.minWithdraw}
                              </Text>
                            </Stack>
                            <Button
                              mt={{ base: "12px", xl: "0px" }}
                              borderColor={"green.500"}
                              color={"green.500"}
                              w="136px"
                              h="42px"
                              variant="outline"
                              fontSize="14px"
                              fontWeight="normal"
                              onClick={() =>
                                handleModalOpen("minWithdrawModal")
                              }
                            >
                              อัพเดทถอนขั้นต่ำ
                            </Button>
                          </Flex>
                        </Card>
                      </Grid>
                      <Card mt="24px">
                        <CardHeader display="flex" flexDirection="column">
                          <Text color="white" fontSize="lg" fontWeight="bold">
                            รายการรับโปรโมชั่น
                          </Text>
                        </CardHeader>
                        <CardBody>
                          <Animated
                            w="100%"
                            h="100%"
                            duration="1.0s"
                            delay="0.1s"
                          >
                            <SearchTableRemote
                              query={[
                                QueryKeys.MEMBER_RECEIVE_PROMOTION_REPORT,
                                username,
                              ]}
                              columnsData={memberProfileReceivePromotionReportColumnsData()}
                              fetchData={fetchMemberReceivePromotion}
                              search={{
                                ...search,
                                searchValue: username,
                              }}
                              setSearch={setSearch}
                              showExport={false}
                              showAction={false}
                              isSearchable={false}
                              noDataBoxH="175px"
                            />
                          </Animated>
                        </CardBody>
                      </Card>
                      <Card mt="24px">
                        <CardHeader>
                          <Flex
                            direction={{ base: "column", xl: "row" }}
                            justifyContent="space-between"
                          >
                            <Text
                              fontSize="18px"
                              fontWeight="bold"
                              color="#fff"
                              pb={{ base: "14px", xl: "0px" }}
                            >
                              สมาชิกที่แนะนำ
                            </Text>
                          </Flex>
                        </CardHeader>
                        <SearchTable
                          isLoading={isLoading}
                          columnsData={affiliateListUserReportColumnsData()}
                          tableData={affiliateUserList}
                          isSearchable={false}
                          noDataBoxH="275px"
                        />
                      </Card>
                      <Card mt="24px">
                        <CardHeader display="flex" flexDirection="column">
                          <Text color="white" fontSize="lg" fontWeight="bold">
                            รายกาคืนยอดสีย
                          </Text>
                        </CardHeader>
                        <CardBody>
                          <Animated
                            w="100%"
                            h="100%"
                            duration="1.0s"
                            delay="0.1s"
                          >
                            <SearchTableRemote
                              query={[
                                QueryKeys.REFUND_WIN_LOSE_TRANSACTION_REPORT,
                                username,
                              ]}
                              columnsData={memberRefundWinLoseReportColumnsData()}
                              fetchData={fetchMemberDepositHistory}
                              search={{
                                ...search,
                                searchValue: username,
                                type: DepositTransactionTypes.REFUND_WIN_LOSE,
                              }}
                              setSearch={setSearch}
                              showExport={false}
                              showAction={false}
                              isSearchable={false}
                              noDataBoxH="175px"
                            />
                          </Animated>
                        </CardBody>
                      </Card>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Animated>
      <WithdrawCreditModal
        isOpen={modalState.withdrawCreditModalOpen}
        onClose={() => handleModalClose("withdrawCreditModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <MinWithdrawModal
        isOpen={modalState.minWithdrawModalOpen}
        onClose={() => handleModalClose("minWithdrawModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <ClearTurnoverModal
        isOpen={modalState.clearTurnoverModalOpen}
        onClose={() => handleModalClose("clearTurnoverModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <ResetPasswordModal
        isOpen={modalState.resetPasswordModalOpen}
        onClose={() => handleModalClose("resetPasswordModal")}
        member={memberData}
        t={t}
      />
    </Flex>
  );
};

export default MemberProfile;

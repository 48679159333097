export const radioStyles = {
  components: {
    Radio: {
      baseStyle: {
        control: {
          borderRadius: "full",
          borderWidth: "2px",
          _checked: {
            bg: "green.500",
            borderColor: "green.500",
          },
          // _hover: {
          //   borderColor: "green.400",
          // },
        },
        label: {
          color: "white",
        },
      },
    },
  },
};

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { useSuccessHandler } from "hooks/common/useSuccessHandler";
import React, { useState } from "react";
import { updateMemberTurnover } from "services/member-management/memberProfileService";
import { ERROR_MESSAGE } from "variables/system";

const ClearTurnoverModal = ({ isOpen, onClose, username, onSuccess }) => {
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState(false);

  const handleClearTurnover = () => {
    setLoading(true);
    const req = {
      turnOver: 0,
      username: username,
    };

    updateMemberTurnover(req)
      .then((response) => {
        onClose();
        handleSuccess(response.data.message);
        onSuccess(); // need to double-check
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text color="#fff">ยืนยันทำรายการ</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>คุณแน่ใจจะเคลียร์เทริน์โอเวอร์: {username}?</ModalBody>
        <ModalFooter>
          <Button
            backgroundColor="green.500"
            color="#000000"
            onClick={handleClearTurnover}
            w="100%"
            isLoading={loading}
            disabled={loading}
          >
            ทำรายการ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClearTurnoverModal;

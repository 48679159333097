import { Button, HStack, Icon } from "@chakra-ui/react";
import { HiOutlineHand, HiOutlineRefresh } from "react-icons/hi";
import { HiOutlineCheck, HiOutlineXMark } from "react-icons/hi2";

const ActionButtons = ({ row, handleModalOpen, t }) => {
  const status = row.original.status;
  const showButtons = ![
    "SUCCESS",
    "REJECT",
    "REJECT_N_REFUND",
    "PENDING",
  ].includes(status);

  const showApproveButton = ["BLOCK_AUTO"].includes(status);
  const showRefreshButton = ["ERROR"].includes(status);

  return (
    showButtons && (
      <HStack spacing={2}>
        {showApproveButton && (
          <Button
            leftIcon={<Icon as={HiOutlineCheck} boxSize={4} />}
            variant="outline"
            colorScheme="green"
            size="xs"
            fontSize="xs"
            padding={2}
            onClick={() => {
              handleModalOpen(
                "approveWithdrawBlockAutoTransactionModal",
                row.original,
              );
            }}
          >
            {t("actionsButtons.approve")}
          </Button>
        )}
        {showRefreshButton && (
          <Button
            leftIcon={<Icon as={HiOutlineRefresh} boxSize={4} />}
            variant="outline"
            colorScheme="yellow"
            size="xs"
            fontSize="xs"
            padding={2}
            onClick={() =>
              handleModalOpen(
                "refreshWithdrawErrorTransactionModal",
                row.original,
              )
            }
          >
            {t("actionsButtons.retry")}
          </Button>
        )}

        <Button
          leftIcon={<Icon as={HiOutlineHand} boxSize={4} />}
          variant="outline"
          colorScheme="blue"
          size="xs"
          fontSize="xs"
          padding={2}
          onClick={() =>
            handleModalOpen("manualWithdrawTransactionModal", row.original)
          }
        >
          {t("actionsButtons.manualWithdraw")}
        </Button>
        <Button
          leftIcon={<Icon as={HiOutlineXMark} boxSize={4} />}
          variant="outline"
          colorScheme="red"
          size="xs"
          fontSize="xs"
          padding={2}
          onClick={() =>
            handleModalOpen("rejectWithdrawTransactionModal", row.original)
          }
        >
          {t("actionsButtons.reject")}
        </Button>
      </HStack>
    )
  );
};
export default ActionButtons;

import { useState } from "react";
import { fetchJackpotHistory } from "services/system-setting/jackpotSettingService";
import { mapJsonToTableData } from "utils/CommonUtils";

export const useJackpotReport = (columnsData) => {
  const [downloadableData, setDownloadableData] = useState([]);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchJackpotHistory(pageIndex, pageSize, search);
    const formattedRecords = mapJsonToTableData(response.results, columnsData);

    setDownloadableData(formattedRecords);
    return response;
  };

  return {
    fetchData,
    downloadableData,
  };
};

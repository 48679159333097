import { callapi } from "../../utils/APIUtils";

export const fetchGameSetting = () => {
  return callapi("/api/admin/gamesetting", "GET", {});
};

export const updateGameSetting = (payload) => {
  return callapi("/api/admin/gamesetting", "POST", payload);
};

export const fetchGamesByProvider = (provider, gameType) => {
  const prefix = localStorage.getItem("PREFIX");
  return callapi(
    `/api/core/getprovidergames?provider=${provider}&gameType=${gameType}&prefix=${prefix}`,
    "GET",
    {},
  );
};

export const saveRecommendGame = (payload) => {
  return callapi("/api/admin/recommendGame", "POST", payload);
};

export const fetchRecommendGames = () => {
  return callapi("/api/admin/recommendGame", "GET", {});
};

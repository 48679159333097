import {
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, // Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text, // useBreakpointValue,
} from "@chakra-ui/react";
import CardFooter from "components/Card/CardFooter";
import React, { useEffect, useState } from "react";
import { getProviderNiceName } from "utils/CommonUtils";

const CommissionSettingModal = ({
  isOpen,
  onClose,
  modalData,
  handleSubmit,
  t,
}) => {
  const [localData, setLocalData] = useState(modalData);

  useEffect(() => {
    setLocalData(modalData);
  }, [modalData]);

  const handleInputChange = (event, provider) => {
    const { value } = event.target;
    const updatedData = localData.map((item) =>
      item.provider === provider ? { ...item, percent: value } : item,
    );
    setLocalData(updatedData);
  };

  // const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const handleSave = () => {
    handleSubmit(localData);
    onClose();
  };

  const tabs = [
    { type: "isSlot", label: "fields.tabList.slot" },
    { type: "isLive", label: "fields.tabList.live" },
    { type: "isCard", label: "fields.tabList.card" },
    { type: "isFishing", label: "fields.tabList.fishing" },
    { type: "isSport", label: "fields.tabList.sport" },
  ].filter((tab) => localData.some((provider) => provider[tab.type]));

  const renderProviders = (type, label) => (
    <>
      <Text fontSize="18px" fontWeight="bold" mt={2} pb={6} color="green.500">
        {t("fields.providerList.label")} {t(label)}
      </Text>
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(6, 1fr)" }}
        gap={3}
      >
        {localData
          .filter((provider) => provider[type])
          .map((provider) => (
            <GridItem key={provider.provider}>
              <Box mb={4}>
                <Text fontSize="xs" color="#fff">
                  {getProviderNiceName(provider.provider)}
                </Text>
                <InputGroup>
                  <Input
                    name={provider.provider}
                    value={provider.percent}
                    onChange={(e) => handleInputChange(e, provider.provider)}
                  />
                  <InputRightElement width="3rem">
                    <Text>%</Text>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </GridItem>
          ))}
      </Grid>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"4xl"}
      scrollBehavior={"inside"}
      isCentered
      overflowY={true}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent maxH="95vh">
        <ModalHeader>{t("commissionSettingModal.header")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant="enclosed" mt={4}>
            <TabList
              overflowX="auto"
              whiteSpace="nowrap"
              overflowY="hidden"
              pb={{ base: "10px", md: "0px" }}
              maxWidth={{ base: "375px", md: "100%" }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.type}
                  _selected={{
                    color: "#fff",
                    background:
                      "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                    border: "none",
                    fontWeight: "bold",
                  }}
                  backgroundColor="#373F48"
                  // marginLeft={{ base: "0px", md: "55px" }}
                  marginRight="5px"
                  color="#8C9592"
                  minWidth={{ base: "80px", md: "136px" }}
                  fontSize={{ base: "14px", md: "18px" }}
                  borderTopRadius="8px"
                  borderBottomRadius={{ base: "8px", md: "0px" }}
                >
                  {t(tab.label)}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabs.map((tab) => (
                <TabPanel key={tab.type} pl={0} pr={0}>
                  {renderProviders(tab.type, tab.label)}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
          <CardFooter pl={0} pr={0} pb={6} justifyContent="center">
            <Button
              bg="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              onClick={handleSave}
              w="100%"
              pt={0}
              fontWeight="normal"
              fontSize="14px"
            >
              {t("commissionSettingModal.saveButton")}
            </Button>
          </CardFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CommissionSettingModal;

import { Box, Button, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { CreateIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import { Loader } from "components/Loader/Loader";
import { useTrueWalletSetting } from "hooks/business/finance-setting/useTrueWalletSetting";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import DeleteTrueWalletModal from "./components/DeleteTrueWalletModal";
import ManageTrueWalletModal from "./components/ManageTrueWalletModal";
import TrueWalletCard from "./components/TrueWalletCard";

const TrueWalletSetting = ({ pageName }) => {
  const { t } = useTranslation("trueWalletSetting");
  const { trueWalletData, isLoading, handleSubmit, handleDelete, isDeleting } =
    useTrueWalletSetting();

  const [modalState, setModalState] = useState({
    manageTrueWalletModalOpen: false,
    deleteTrueWalletModalOpen: false,
  });
  const [selectedTrueWallet, setSelectedTrueWallet] = useState(null);

  const handleModalOpen = (modal, user = null) => {
    setSelectedTrueWallet(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTrueWallet(null);
  };

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            justifyContent="space-between"
            direction={{ base: "column", xl: "row" }}
          >
            <Text color="white" fontSize="18px" fontWeight="bold" pb="12px">
              {t("trueWalletSettings")}
            </Text>
            <Button
              w="150px"
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              variant="solid"
              borderRadius="33px"
              fontSize="14px"
              fontWeight="normal"
              onClick={() => handleModalOpen("manageTrueWalletModal")}
            >
              <Icon as={CreateIcon} mr="5px" />
              {t("addTrueWallet")}
            </Button>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          {isLoading ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              height="400px"
              w="100%"
            >
              <Loader />
            </Flex>
          ) : (
            <SimpleGrid columns={{ base: 1, xl: 3 }} spacing={6} w="100%">
              {trueWalletData
                .slice()
                .sort((a, b) => a.id - b.id)
                .map((tw) => (
                  <Box key={tw.id}>
                    <TrueWalletCard
                      trueWallet={tw}
                      onEdit={() =>
                        handleModalOpen("manageTrueWalletModal", tw)
                      }
                      onDelete={() =>
                        handleModalOpen("deleteTrueWalletModal", tw)
                      }
                      t={t}
                    />
                  </Box>
                ))}
            </SimpleGrid>
          )}
        </Animated>
      </Card>
      <ManageTrueWalletModal
        isOpen={modalState.manageTrueWalletModalOpen}
        onClose={() => handleModalClose("manageTrueWalletModal")}
        trueWallet={selectedTrueWallet}
        handleSubmit={handleSubmit}
        t={t}
      />
      <DeleteTrueWalletModal
        isOpen={modalState.deleteTrueWalletModalOpen}
        onClose={() => handleModalClose("deleteTrueWalletModal")}
        trueWallet={selectedTrueWallet}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
        t={t}
      />
    </Flex>
  );
};

export default TrueWalletSetting;

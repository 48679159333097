import { fetchMemberLevelUpReport } from "services/member-report/memberLevelUpReportService";

export const useFetchMemberLevelUp = () => {
  const fetchData = async (pageIndex, pageSize, search) => {
    return await fetchMemberLevelUpReport(pageIndex, pageSize, search);
  };

  return {
    fetchData,
  };
};

import { callListApi, callapi } from "../../utils/APIUtils";
import {
  buildBasePayload,
  buildFetchDepositHistoryPayload,
  buildFetchInvalidTransactionPayload,
} from "../../utils/PayloadUtils";
import { DepositTransactionTypes } from "../../variables/types";

const getDepositTransactionPayloadBuilder = (searchType) => {
  switch (searchType) {
    case DepositTransactionTypes.ALL:
      return buildFetchDepositHistoryPayload;
    case DepositTransactionTypes.NOT_SURE:
      return buildFetchInvalidTransactionPayload;
  }
};

export const fetchDepositHistories = async (
  page,
  pageSize,
  search,
  searchType,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    getDepositTransactionPayloadBuilder(searchType),
  );
  const response = await callListApi(
    "/api/admin/search/list/depositHistory",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  return {
    count: response.page.totalElements,
    results: response.data,
  };
};

export const fetchMarketingDepositHistories = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
  searchType,
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    getDepositTransactionPayloadBuilder(searchType),
  );
  const response = await callListApi(
    "/api/admin/marketing/search/list/depositHistory",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  return {
    count: response.page.totalElements,
    results: response.data,
  };
};

export const updateDepositTransaction = (payload) => {
  return callapi(
    "/api/admin/depositHistory/updateBlockAutoTransaction",
    "POST",
    payload,
  );
};

export const updateInvalidTransaction = (payload) => {
  return callapi(
    "/api/admin/depositHistory/updateNotSureStatus",
    "POST",
    payload,
  );
};

export const fetchDepositPaymentSummary = (payload) => {
  return callapi("/api/admin/summary/payment", "POST", payload);
};

import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { API_BASE_URL, APP_TOKEN } from "variables/system";

const request = (options, authorization) => {
  switch (options.method) {
    case "GET":
      return axios.get(API_BASE_URL + options.url, {
        headers: {
          Authorization: authorization,
        },
      });
    case "POST":
      return axios.post(API_BASE_URL + options.url, options.body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      });
    case "PUT":
      return axios.put(API_BASE_URL + options.url, options.body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      });

    case "DELETE":
      return axios.delete(API_BASE_URL + options.url, {
        headers: {
          Authorization: authorization,
        },
      });
  }
};

export function callapi(url, method, bodyRequest) {
  const accessToken = localStorage.getItem(APP_TOKEN);
  return trackPromise(
    request(
      {
        url: url,
        method: method,
        body: method === "GET" ? bodyRequest : JSON.stringify(bodyRequest),
      },
      "Bearer " + accessToken,
    ),
  );
}

export function callBasicAuthApi(url, method, bodyRequest, credentials) {
  return trackPromise(
    request(
      {
        url: url,
        method: method,
        body: method === "GET" ? bodyRequest : JSON.stringify(bodyRequest),
      },
      "Basic " + credentials,
    ),
  );
}

export function callListApi(url, method, searchData) {
  let sortField = searchData.sortField;
  if (searchData.sortField === "createdBy") {
    sortField = "audit.createdBy";
  } else if (searchData.sortField === "updatedBy") {
    sortField = "audit.updatedBy";
  }

  // console.log(searchData);

  let bodyRequest = {
    ...searchData,
    page: searchData.page - 1,
    size: searchData.sizePerPage,
    // sort: searchData.sortOrder === "desc" ? "D" : "A",
    sortField: sortField,
    createdDateFrom: searchData && searchData.createdDateFrom,
    createdDateTo: searchData && searchData.createdDateTo,

    ...getFilters(searchData.filters),
    sizePerPage: undefined,
    sortOrder: undefined,
    data: undefined,
    filters: undefined,
  };
  return callapi(url, method, bodyRequest);
}

function getFilters(filters) {
  let filter = {};
  if (filters && Object.keys(filters).length !== 0) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value.filterType === "NUMBER") {
        filter[key] = value.filterVal.number;
      } else {
        filter[key] = value.filterVal;
      }
    });
  }
  return filter;
}

export function fileUpload(url, data) {
  const accessToken = localStorage.getItem(APP_TOKEN);
  return axios.post(API_BASE_URL + url, data, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  });
}

import { IconButton } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/Icons";
import { formatDate } from "utils/DateformatUtils";

import { SUPER_ROLE } from "../../system";

export const roleSettingColumnsData = (history, t) => {
  return [
    {
      Header: t("headers.roleName"),
      accessor: "roleName",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.createdBy"),
      accessor: "audit.createdBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.createdDate"),
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.updatedDate"),
      accessor: "updatedDate",
      Cell: ({ value }) => formatDate(value),
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "",
      accessor: "edit",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ row }) =>
        row.original.roleName === SUPER_ROLE ? (
          <IconButton
            border="none"
            aria-label="edit"
            size="sm"
            background="none"
            _hover={{ background: "none" }}
            _active={{ background: "none" }}
          />
        ) : (
          <IconButton
            variant="solid"
            aria-label="edit"
            size="sm"
            borderRadius="28px"
            w="40px"
            h="30px"
            bg="green.500"
            icon={<EditIcon h="18px" w="18px" color="#fff" />}
            onClick={() =>
              history.push(`/admin/system-setting/role/edit/${row.original.id}`)
            }
          >
            แก้ไข
          </IconButton>
        ),
      disableSortBy: true,
    },
  ];
};

export const memberLoginHistoryColumnsData = (t) => [
  // {
  //   Header: t("headers.active"),
  //   accessor: "active",
  //   textAlign: "left",
  //   justifyContent: "flex-start",
  //   Cell: ({ cell }) => (
  //     <Flex align="center">
  //       <Badge
  //         bg={cell.value === true ? "green.500" : "red.500"}
  //         w="65px"
  //         h="28px"
  //         borderRadius="15px"
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         {cell.value === true ? t("status.online") : t("status.offline")}
  //       </Badge>
  //     </Flex>
  //   ),
  // },
  {
    Header: t("headers.username"),
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.lastlogin"),
    accessor: "lastlogin",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.ip"),
    accessor: "ip",
    textAlign: "left",
    justifyContent: "flex-start",
  },
];

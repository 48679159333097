import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import AdminNavbarLinks from "./AdminNavbarLinks";

const AdminNavbarLinksWrapper = ({ activeRoutes }) => {
  const isOverview =
    activeRoutes === "ภาพรวม" || activeRoutes === "Default Brand Text";

  const { t } = useTranslation();

  return (
    <Flex
      alignItems="center"
      w="100%"
      justifyContent={{ sm: "end", xl: "space-between" }}
      borderRadius="0px"
      minH="65px"
      lineHeight="25.6px"
      mx="auto"
      pb="20px"
      px={{
        xl: "2px",
        sm: "20px",
      }}
      ps={{
        xl: "0px",
      }}
      pt="20px"
    >
      <Flex display={{ sm: "none", xl: "flex" }}>
        <Text
          fontSize="18px"
          fontWeight="medium"
          ml={{ sm: "none", xl: "20px" }}
          color={isOverview ? "#8C9592" : "green.500"}
        >
          {t("dashboard")}
        </Text>
        {!isOverview && (
          <Text fontSize="18px" fontWeight="medium" ml="16px">
            {" "}
            /
            <Text as="span" ml="16px">
              {t(activeRoutes, { ns: "shared" })}
            </Text>{" "}
          </Text>
        )}
      </Flex>
      <Box w={{ base: "100%", xl: "auto" }}>
        <AdminNavbarLinks
        // onOpen={props.onOpen}
        // logoText={props.logoText}
        // username={props.username}
        // profileUrl={props.profileUrl}
        // secondary={props.secondary}
        // fixed={props.fixed}
        />
      </Box>
    </Flex>
  );
};

export default AdminNavbarLinksWrapper;

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField } from "utils/CommonUtils";

const RejectBlockAutoTransactionModal = ({
  isOpen,
  onClose,
  transaction,
  handleSubmit,
  t,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              {t("rejectBlockAutoTransaction.title")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              depositId: transaction?.id,
              status: "REJECT",
              reason: "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel>
                        {t("rejectBlockAutoTransaction.reasonLabel")}
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder={t(
                          "rejectBlockAutoTransaction.reasonPlaceholder",
                        )}
                        fontSize="sm"
                      />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    fontWeight="normal"
                    type="submit"
                    fontSize="14px"
                    isLoading={props.isSubmitting}
                  >
                    {t("rejectBlockAutoTransaction.submitButton")}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RejectBlockAutoTransactionModal;

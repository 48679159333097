import { fetchMemberHaveTurnoverReport } from "services/member-report/memberReportService";

export const useFetchMemberHaveTurnover = () => {
  const fetchData = async (pageIndex, pageSize, search) => {
    return fetchMemberHaveTurnoverReport(pageIndex, pageSize, search);
  };

  return {
    fetchData,
  };
};

import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import SearchTable from "components/Tables/SearchTable";
import { useMarketingSetting } from "hooks/business/system-setting/useMarketingSetting";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { marketingSettingColumnsData } from "variables/columns/system-setting/marketingSettingColumnsData";

import ManageMarketingModal from "./components/ManageMarketingModal";

const MarketingSetting = ({ pageName }) => {
  const { t } = useTranslation("marketingSetting");
  const { marketingStaffData, isMarketingStaffDataLoading, marketingLinkData } =
    useMarketingSetting();

  const [modalState, setModalState] = useState({
    manageMarketingModalOpen: false,
  });
  const [selectedMarketing, setSelectedMarketing] = useState(null);

  const handleModalOpen = (modal, marketing = null) => {
    setSelectedMarketing(marketing);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMarketing(null);
  };

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <CardHeader>
          <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
            {t("marketingSettings")}
          </Text>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <CardBody>
            {marketingLinkData && (
              <SearchTable
                isLoading={isMarketingStaffDataLoading}
                tableData={marketingStaffData}
                showAction={true}
                handleModalOpen={handleModalOpen}
                actionButtonText={t("buttons.addMarketing")}
                actionButtonIcon={null}
                modalName={"manageMarketingModal"}
                columnsData={marketingSettingColumnsData(
                  handleModalOpen,
                  marketingLinkData,
                  t,
                )}
              />
            )}
          </CardBody>
        </Animated>
      </Card>
      <ManageMarketingModal
        isOpen={modalState.manageMarketingModalOpen}
        onClose={() =>
          handleModalClose("manageMarketingModal", marketingStaffData)
        }
        marketing={selectedMarketing}
        t={t}
      />
    </Flex>
  );
};

export default MarketingSetting;

import { callListApi } from "../../utils/APIUtils";
import {
  buildBasePayload,
  buildMemberLevelUpReportPayload,
} from "../../utils/PayloadUtils";

export const fetchMemberLevelUpReport = async (
  page,
  pageSize,
  search,
  searchType,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildMemberLevelUpReportPayload,
  );

  const response = await callListApi(
    "/api/admin/webuser/search/levelhistory",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  return {
    count: response.page.totalElements,
    results: response.datas,
  };
};

import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { useQuery } from "react-query";
import { usePagination, useTable } from "react-table";

import FileExportDropdown from "../FileExportDropdown/FileExportDropdown";
import { NotFoundIcon, SearchIcon } from "../Icons/Icons";
import { Loader } from "../Loader/Loader";
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
};

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

function SearchTableRemote(props) {
  const {
    columnsData,
    query,
    fetchData,
    search,
    setSearch,
    isSearchable = true,
    showExport = false,
    showAction = false,
    showSearchableDropdown = false,
    searchOptions,
    onChange,
    handleModalOpen,
    modalName,
    actionButtonIcon,
    actionButtonText,
    downloadableData,
    filename,
    isSum,
    dropDownPlaceholder = "Select...",
    noDataBoxH = "425px",
  } = props;

  const { t, i18n } = useTranslation();
  const columns = useMemo(() => columnsData, [t]);
  // const data = useMemo(() => tableData, []);

  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] =
    React.useReducer(reducer, initialState);

  const { data, isSuccess, isLoading } = useQuery(
    [query, queryPageIndex, queryPageSize, search],
    () => fetchData(queryPageIndex, queryPageSize, search),
    {
      keepPreviousData: false,
      // staleTime: Infinity,
    },
  );

  const [forceRenderKey, setForceRenderKey] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: isSuccess ? data.results : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
    },
    // useGlobalFilter,
    // useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    const onLanguageChange = () => {
      setForceRenderKey((prevKey) => prevKey + 1);
    };

    i18n.on("languageChanged", onLanguageChange);

    return () => {
      i18n.off("languageChanged", onLanguageChange);
    };
  }, [i18n]);

  React.useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  React.useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  React.useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
    }
  }, [data?.count]);

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  // const stackAlignment = useBreakpointValue({base: "center", xl: "flex-end"});
  const stackAlignment = useBreakpointValue({ base: "center" });
  const stackMarginStart = useBreakpointValue({ base: "0", xl: "auto" });

  const [pageChunk, setPageChunk] = useState(0);
  const pagesPerChunk = 5;

  return (
    <Flex direction="column" w="100%" key={forceRenderKey}>
      {(isSearchable || showSearchableDropdown || showAction || showExport) && (
        <Flex direction="column" w="100%">
          {(isSearchable ||
            showSearchableDropdown ||
            showAction ||
            showExport) && (
            <Flex
              justify="space-between"
              align="center"
              w="100%"
              pb="22px"
              direction={{ base: "column", xl: "row" }}
              gap={{ base: "12px", xl: "0" }}
            >
              {isSearchable && (
                <InputGroup flexShrink={0} w={{ base: "100%", xl: "266px" }}>
                  <Input
                    type="text"
                    placeholder={t("search")}
                    h="42px"
                    fontSize="14px"
                    borderRadius="33px"
                    color="gray.500"
                    backgroundColor="#1E252C"
                    _focus={{ borderColor: "none" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setSearch({
                          ...search,
                          searchValue: e.target.value,
                        });
                        fetchData(queryPageIndex, queryPageSize, search);
                      }
                    }}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.500" h="18px" w="18px" />}
                    right="8px"
                  />
                </InputGroup>
              )}

              {(showSearchableDropdown || showAction || showExport) && (
                <Flex
                  w="100%"
                  direction={{ base: "column", xl: "row" }}
                  justify={{ base: "flex-start", xl: "space-between" }}
                  align={{ base: "stretch", xl: "center" }}
                  gap={{ base: "12px", xl: "0" }}
                >
                  {showSearchableDropdown && (
                    <Box
                      w={{ base: "100%", xl: "auto" }}
                      pr={{ base: "0", xl: showExport ? "6px" : "0" }}
                      display={{ base: "block", xl: "none" }}
                    >
                      <SearchableDropdown
                        options={searchOptions}
                        isSearchable={false}
                        onChange={onChange}
                        placeholder={dropDownPlaceholder}
                      />
                    </Box>
                  )}

                  {(showSearchableDropdown || showAction || showExport) && (
                    <Flex
                      w="100%"
                      justify={
                        !showAction && showExport
                          ? { base: "flex-end", xl: "flex-end" }
                          : { base: "space-between", xl: "flex-end" }
                      }
                      gap="8px"
                    >
                      {showSearchableDropdown && (
                        <Box
                          w={{ base: "100%", xl: "auto" }}
                          pr={{ base: "0", xl: showExport ? "6px" : "0" }}
                          display={{ base: "none", xl: "block" }}
                        >
                          <SearchableDropdown
                            options={searchOptions}
                            isSearchable={false}
                            onChange={onChange}
                            placeholder={dropDownPlaceholder}
                          />
                        </Box>
                      )}
                      {showAction && (
                        <Button
                          background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                          color="#fff"
                          borderRadius="33px"
                          fontSize="14px"
                          pl={6}
                          pr={6}
                          onClick={() => handleModalOpen(modalName)}
                        >
                          <Icon
                            as={actionButtonIcon}
                            mr="5px"
                            h="24px"
                            w="24px"
                            justifyContent="center"
                          />
                          {actionButtonText}
                        </Button>
                      )}
                      {showExport && (
                        <Box>
                          <FileExportDropdown
                            data={downloadableData}
                            filename={filename}
                          />
                        </Box>
                      )}
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      )}

      <Box
        overflowX="auto"
        w="100%"
        borderRadius="6px"
        border="1px solid rgba(55, 63, 72, 1)"
        bg={page.length === 0 ? "rgba(33, 39, 46, 1)" : ""}
      >
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb={isSum ? "0px" : "24px"}
          w="100%"
        >
          <Thead backgroundColor="#1D2229">
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    fontFamily="'LINESeedSansTH'"
                    fontWeight="normal"
                    pe="0px"
                    key={index}
                    pt={3}
                    pb={3}
                    pl={4}
                    pr={4}
                    fontSize="14px"
                    textAlign={column.textAlign || "center"}
                    whiteSpace="nowrap"
                    onClick={
                      column.disableSortBy
                        ? null
                        : () => {
                            if (search.sort === "A") {
                              setSearch({
                                ...search,
                                sortField: column.id,
                                sort: "D",
                              });
                            } else {
                              setSearch({
                                ...search,
                                sortField: column.id,
                                sort: "A",
                              });
                            }
                          }
                    }
                  >
                    <Flex
                      justify={column.justifyContent || "center"}
                      align="center"
                      fontSize="sm"
                      color="gray.500"
                      minW={column.minWidth || "95px"}
                      textTransform="none"
                    >
                      {column.render("Header")}
                      {!column.disableSortBy && (
                        <Icon
                          w={{ sm: "10px", xl: "14px" }}
                          h={{ sm: "10px", xl: "14px" }}
                          color={column.isSorted ? "gray.500" : "gray.400"}
                          float="right"
                          as={
                            column.isSorted
                              ? column.isSortedDesc
                                ? TiArrowSortedDown
                                : TiArrowSortedUp
                              : TiArrowUnsorted
                          }
                        />
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {isLoading ? (
              <Tr>
                <Td
                  colSpan={headerGroups[0].headers.length}
                  textAlign="center"
                  fontSize="14px"
                  color="gray.500"
                  borderBottom="none"
                  h="425px"
                  p={4}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt="8px"
                    mb="8px"
                  >
                    <Loader />
                  </Box>
                </Td>
              </Tr>
            ) : page.length === 0 ? (
              <Tr>
                <Td
                  colSpan={headerGroups[0].headers.length}
                  textAlign="center"
                  fontSize="14px"
                  color="gray.500"
                  borderBottom="none"
                  h={noDataBoxH}
                  p={4}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt="8px"
                    mb="8px"
                  >
                    <NotFoundIcon
                      w="80px"
                      h="80px"
                      color="rgba(55, 63, 72, 1)"
                    />
                  </Box>
                  {t("noDataForItem")}
                </Td>
              </Tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    sx={{
                      transition: "background-color 0.2s ease",
                      _hover: { backgroundColor: "gray.800" },
                    }}
                  >
                    {row.cells.map((cell, index) => (
                      <Td
                        {...cell.getCellProps()}
                        fontSize="sm"
                        key={index}
                        color="gray.500"
                        pt={2}
                        pb={2}
                        pl={3}
                        pr={3}
                        minW={cell.column.minWidth || "none"}
                        textAlign={cell.column.textAlign || "center"}
                        className="td-border-bottom"
                        whiteSpace="nowrap"
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })
            )}
            {isSum && page.length !== 0 && (
              <Tr backgroundColor={"rgb(45,53,61)"}>
                {columns.map((column, index) => (
                  <Td
                    key={index}
                    fontSize="sm"
                    color="white"
                    p={6}
                    textAlign="right"
                    fontWeight="bold"
                  >
                    {index === columns.length - 2 ? (
                      <Text color="#8C9592" fontSize="16px">
                        Total:{" "}
                      </Text>
                    ) : index === columns.length - 1 ? (
                      data?.summary ?? ""
                    ) : (
                      ""
                    )}
                  </Td>
                ))}
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex
        direction={{ sm: "column", xl: "row" }}
        justify="space-between"
        align="center"
        w="100%"
      >
        <Stack
          direction={{ sm: "column", xl: "row" }}
          spacing={{ sm: "4px", xl: "12px" }}
          align="center"
          my="24px"
          minW={{ sm: "100px", xl: "200px" }}
        >
          <Text fontSize="xs" color="gray.500" fontWeight="normal">
            {t("showing")}
          </Text>
          <Select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            color="gray.500"
            size="sm"
            borderRadius="33px"
            h="38px"
            maxW="75px"
            cursor="pointer"
            bg="#1E252C"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
          </Select>
          <Text fontSize="xs" color="gray.500" fontWeight="normal">
            {t("entries")}
          </Text>
        </Stack>
        <Stack
          direction="row"
          alignSelf={stackAlignment}
          spacing="0px"
          ms={stackMarginStart}
        >
          <Button
            variant="no-hover"
            onClick={() => {
              if (pageChunk > 0) {
                setPageChunk(pageChunk - 1);
              }
            }}
            transition="all .5s ease"
            w="80px"
            h="40px"
            borderRadius="0px"
            bg="none"
            _hover={{ bg: "gray.500" }}
            borderTopLeftRadius="33px"
            borderBottomLeftRadius="33px"
            border="1px solid #495058"
          >
            <Text fontSize="14px" fontWeight="normal" color="#8C9592">
              {t("previous")}
            </Text>
          </Button>
          {createPages(pageCount)
            .slice(pageChunk * pagesPerChunk, (pageChunk + 1) * pagesPerChunk)
            .map((pageNumber, index) => (
              <Button
                variant="no-hover"
                transition="all .5s ease"
                onClick={() => gotoPage(pageNumber - 1)}
                w="28px"
                h="40px"
                minW="0px"
                borderRadius="0px"
                bg={pageNumber === pageIndex + 1 ? "green.500" : "none"}
                _hover={{ bg: "gray.500" }}
                key={index}
                border="1px solid #495058"
              >
                <Text
                  fontSize="xs"
                  color={pageNumber === pageIndex + 1 ? "#fff" : "#8C9592"}
                >
                  {pageNumber}
                </Text>
              </Button>
            ))}
          <Button
            variant="no-hover"
            onClick={() => {
              if ((pageChunk + 1) * pagesPerChunk < pageCount) {
                setPageChunk(pageChunk + 1);
              }
            }}
            transition="all .5s ease"
            w="65px"
            h="40px"
            borderRadius="0px"
            bg="none"
            _hover={{ bg: "gray.500" }}
            borderTopRightRadius="33px"
            borderBottomRightRadius="33px"
            border="1px solid #495058"
          >
            <Text fontSize="14px" fontWeight="normal" color="#8C9592">
              {t("next")}
            </Text>
          </Button>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default SearchTableRemote;

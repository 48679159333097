import { formatDate } from "utils/DateformatUtils";

import { StatusBadge } from "../transaction-report/withdrawTransactionReportColumnsData";

export const memberDepositTransactionReportColumnsData = (t) => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      Cell: ({ cell }) => <StatusBadge status={cell.value} t={t} />,
    },
    {
      Header: "ยอดฝาก",
      accessor: "amount",
    },
    {
      Header: "ยอดโบนัส",
      accessor: "bonus",
    },
    {
      Header: "วันเวลาทำรายการ",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "สาเหตุ",
      accessor: "reason",
    },
  ];
};

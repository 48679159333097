import { DEVICE_OPTIONS } from "../variables/options";
import {
  BonusTypes,
  GameTypes,
  JackpotTypes,
  PromotionTypes,
} from "../variables/types";

export const validateField = (value) => {
  let error;
  if (!value) {
    error = "This field is required";
  }
  return error;
};

export function formatPhoneNumber(phoneNumber, type = "default") {
  if (type === "default") {
    if (phoneNumber.startsWith("02")) {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        5,
      )}-${phoneNumber.slice(5)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6,
      )}-${phoneNumber.slice(6)}`;
    }
  } else if (type === "dashes") {
    if (phoneNumber.startsWith("02")) {
      return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(
        2,
        5,
      )}-${phoneNumber.slice(5)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6,
      )}-${phoneNumber.slice(6)}`;
    }
  }
}

export function formatAccountNumber(accountNumber) {
  const prefix = `${accountNumber.slice(0, 3)}-${accountNumber.slice(
    3,
    4,
  )}-${accountNumber.slice(4, 9)}-${accountNumber.slice(9, 10)}`;
  const rest = accountNumber.slice(10, accountNumber.length);
  return rest ? `${prefix}${rest}` : prefix;
}

export const validateObject = (value, obj) => {
  let error;
  if (!obj && !value) {
    error = "This field is required";
  }
  return error;
};

export const getDeviceText = (value) => {
  const device = DEVICE_OPTIONS.find((device) => device.val === value);
  return device ? device.text : value;
};

export const getMemberLevelName = (memberLevelData, level) => {
  const memberLevel = memberLevelData?.find(
    (member) => parseInt(member.seqLevel) + 1 === level,
  );
  return memberLevel ? memberLevel.levelName : "-";
};

export const getMemberLevelColorScheme = (value) => {
  switch (value) {
    case "4":
      return "#005EE2";
    case "3":
      return "#9C6A1F";
    case "2":
      return "#3F4A56";
    case "1":
      return "#1D2229";
    case "0":
      return "#1D2229";
    default:
      return "#1D2229";
  }
};

export const mapJsonToTableData = (jsonData, columnsData) => {
  return jsonData.map((item) => {
    const mappedRow = {};

    columnsData.forEach((column) => {
      const accessor = column.accessor;
      const header = column.Header;

      if (accessor && header) {
        mappedRow[header] = item[accessor] !== undefined ? item[accessor] : "-";
      }
    });

    return mappedRow;
  });
};

export const getProviderNiceName = (name) => {
  switch (name) {
    case "pg":
      return "PGSOFT";
    case "dg":
      return "Dream Gaming";
    case "pp":
      return "Pragmatic Play";
    case "sa":
      return "SA Gaming";
    case "sexy":
      return "Sexy Gaming";
    case "hab":
      return "Habanero";
    case "joker":
      return "Joker";
    case "ep":
      return "EvoPlay";
    case "fc":
      return "Fachai";
    case "mg":
      return "Micro Gaming";
    case "bpg":
      return "Blueprint Gaming";
    case "rlx":
      return "Relax Gaming";
    case "bng":
      return "Booongo";
    case "kgl":
      return "Kalamba Games";
    case "bg":
      return "BG";
    case "ygg":
      return "Yggdrasil";
    case "waz":
      return "Wazdan";
    case "aws":
      return "AE Gaming Slot";
    case "ag":
      return "Asia Gaming";
    case "ng":
      return "Naga Games";
    case "netent":
      return "NetEnt";
    case "ttg":
      return "TTG";
    case "swg":
      return "SkyWind Group";
    case "bs":
      return "Betsoft";
    case "bfs":
      return "BF Sports";
    case "we":
      return "WE Entertainment";
    case "wm":
      return "WM";
    case "eg":
      return "Evolution Gaming";
    case "km":
      return "KINGMAKER";
    case "funky":
      return "Funky Games";
    case "ps":
      return "PlayStar";
    case "1x2":
      return "1X2 Gaming";
    case "qs":
      return "Quickspin";
    case "ga":
      return "Game Art";
    case "sp":
      return "SimplePlay";
    case "ds":
      return "Dragoon Soft";
    case "nlc":
      return "Nolimit City";
    case "red":
      return "Red Tiger";
    case "ids":
      return "Iron Dog";
    case "tk":
      return "Thunderkick";
    case "elk":
      return "ELK Studios";
    case "prs":
      return "Print Studios";
    case "hak":
      return "Hacksaw Gaming";
    case "png":
      return "Play n Go";
    case "max":
      return "Maverick";
    case "pug":
      return "Push Gaming";
    case "nge":
      return "NetGames Entertainment";
    case "fng":
      return "Fantasma Gaming";
    default:
      return name;
  }
};

export const getCommissionTypeNiceName = (type) => {
  switch (type) {
    case "EARN_POINT":
      return "รับพ๊อยต์";
    case "COMM_CREDIT":
      return "ค่าคอมมิชชั่น";
    case "TRANS_CREDIT":
      return "แลกพ๊อยต์";
  }
};

export const getJackpotBackground = (type) => {
  switch (type) {
    case JackpotTypes.GRAND_JACKPOT:
      return "#F7AB1E";
    case JackpotTypes.MAJOR_JACKPOT:
      return "#2E4DB2";
    case JackpotTypes.MINOR_JACKPOT:
      return "#4F4A43";
    case JackpotTypes.MINI_JACKPOT:
      return "#373F48";
  }
};

export const getStaffCreditStatusBackground = (status) => {
  switch (status) {
    case "NO_SLIP":
      return "#F7AB1E";
    case "CUT_CREDIT":
      return "#BB344C";
    case "ADD_CREDIT":
      return "#31AA93";
  }
};

export const getStaffCreditStatusNiceName = (status) => {
  switch (status) {
    case "NO_SLIP":
      return "status.noSlip";
    case "CUT_CREDIT":
      return "status.cutCredit";
    case "ADD_CREDIT":
      return "status.addCredit";
    default:
      return "status.unknown";
  }
};

export const getActivityTitle = (title) => {
  switch (title) {
    case "คอมมิชชั่น":
      return "titles.commission";
    case "โปรโมชั่น":
      return "titles.promotion";
    case "กงล้อมหาสนุก":
      return "titles.luckywheel";
    case "แนะนำเพื่อน":
      return "titles.affiliate";
    case "ดูหนัง":
      return "titles.movie";
  }
};

export const getBonusType = (type) => {
  switch (type) {
    case BonusTypes.FIX:
      return "options.bonusOptions.fix";
    case BonusTypes.PERCENT:
      return "options.bonusOptions.percent";
  }
};

export const getTypePromotion = (type) => {
  switch (type) {
    case PromotionTypes.ALL_DAY:
      return "promotionOptions.allDay";
    case PromotionTypes.NEW_USER:
      return "promotionOptions.newUser";
    case PromotionTypes.FIRST_TIME:
      return "promotionOptions.firstTime";
    case PromotionTypes.GOLDEN_TIME:
      return "promotionOptions.goldTime";
    case PromotionTypes.SEVEN_DAY_IN_A_ROW:
      return "promotionOptions.sevenDayInRow";
  }
};

export const getTypeGame = (type) => {
  switch (type) {
    case GameTypes.ALL:
      return "gameOptions.all";
    case GameTypes.SPORT:
      return "gameOptions.sport";
    case GameTypes.LIVE:
      return "gameOptions.live";
    case GameTypes.SLOT:
      return "gameOptions.slot";
    case GameTypes.CARD:
      return "gameOptions.card";
  }
};

export const getTransactionStatusBackground = (status) => {
  switch (status) {
    case "SUCCESS":
      return "#31AA93";
    case "PENDING":
      return "#FFD82B";
    case "ERROR":
      return "#BB344C";
    case "BLOCK_AUTO":
      return "#B86410";
    case "NOT_SURE":
      return "#3E4246";
    case "REJECT":
      return "#5C2429";
    case "REJECT_N_REFUND":
      return "#521017";
  }
};

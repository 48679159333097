import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useFetchMemberLevelUp } from "hooks/business/member-report/useFetchMemberLevelUp";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { useTranslation } from "react-i18next";
import { QueryClientProvider, useQueryClient } from "react-query";
import { memberLevelUpReportColumnsData } from "variables/columns/member-report/memberLevelUpReportColumnsData";
import { QueryKeys } from "variables/queryKeys";

const MemberLevelUpReport = ({ pageName }) => {
  const { t } = useTranslation("memberLevelUpReport");
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";

  const { fetchData } = useFetchMemberLevelUp();
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "14px", xl: "0px" }}
            >
              {t("memberLevelUp")}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={QueryKeys.MEMBER_LEVEL_UP_REPORT}
                columnsData={memberLevelUpReportColumnsData(t)}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={true}
                // downloadableData={}
                filename={pageName}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default MemberLevelUpReport;

export const DEVICE_OPTIONS = [
  { val: "1", text: "Mobile" },
  { val: "2", text: "Desktop" },
];

export const BONUS_OPTIONS = (t) => [
  {
    typeCode: "FIX",
    typeName: t("options.bonusOptions.fix", { ns: "shared" }),
  },
  {
    typeCode: "PERCENT",
    typeName: t("options.bonusOptions.percent", { ns: "shared" }),
  },
];

export const AFFILIATE_TYPE_OPTIONS = (t) => [
  {
    typeCode: "DEPOSIT",
    typeName: t("options.affiliateOptions.deposit", { ns: "shared" }),
  },
  {
    typeCode: "WINLOSE",
    typeName: t("options.affiliateOptions.winLose", { ns: "shared" }),
  },
];

export const PROMOTION_OPTIONS = (t) => [
  { typeCode: "ALLDAY", typeName: t("promotionOptions.allDay") },
  { typeCode: "NEWUSER", typeName: t("promotionOptions.newUser") },
  { typeCode: "FIRSTTIME", typeName: t("promotionOptions.firstTime") },
  { typeCode: "GOLDTIME", typeName: t("promotionOptions.goldTime") },
  { typeCode: "7DAYINROW", typeName: t("promotionOptions.sevenDayInRow") },
];

export const GAME_OPTIONS = (t) => [
  { typeCode: "ALL", typeName: t("gameOptions.all") },
  { typeCode: "SPORT", typeName: t("gameOptions.sport") },
  { typeCode: "SLOT", typeName: t("gameOptions.slot") },
  { typeCode: "LIVE", typeName: t("gameOptions.live") },
  { typeCode: "CARD", typeName: t("gameOptions.card") },
  // { typeCode: "TABLE_GAME", typeName: "สกิลเกมส์" },
  // { typeCode: "FISHING", typeName: "ยิงปลา" },
  // {typeCode: "LOTTO", typeName: "LOTTO"},
  // {typeCode: "MULTI_PLAYER", typeName: "MULTI_PLAYER"},
  // {typeCode: "POKER", typeName: "POKER"},
  // {typeCode: "KENO", typeName: "KENO"},
  // {typeCode: "TRADING", typeName: "TRADING"}
];

export const TURNOVER_OPTIONS = (t) => [
  { typeCode: "TURNOVER", typeName: t("turnoverOptions.turnover") },
  { typeCode: "MINCREDIT", typeName: t("turnoverOptions.minCredit") },
];

export const FOOTER_ICON_TYPE_OPTIONS = (t) => [
  {
    val: "1",
    text: t("footerIconTypes.contactUs"),
  },
  {
    val: "2",
    text: t("footerIconTypes.paymentMethod"),
  },
  {
    val: "4",
    text: t("footerIconTypes.depositMethod"),
  },
  {
    val: "3",
    text: t("footerIconTypes.provider"),
  },
];

export const REFUND_TYPE_OPTIONS = (t) => [
  { typeCode: "WEEKLY", typeName: t("refundTypes.weekly") },
  { typeCode: "MONTHLY", typeName: t("refundTypes.monthly") },
];

export const DEPOSIT_TRANSACTION_SEARCH_OPTIONS = (t) => [
  {
    label: t("searchOptions.all"),
    value: "",
  },
  {
    label: t("statuses.success"),
    value: "SUCCESS",
  },
  {
    label: t("statuses.inprogress"),
    value: "PENDING",
  },
  {
    label: t("statuses.error"),
    value: "ERROR",
  },
  {
    label: t("statuses.reject"),
    value: "REJECT",
  },
  // {
  //   label: "rejectAndRefund",
  //   value: "REJECT_N_REFUND",
  // },
  {
    label: t("statuses.blockAuto"),
    value: "BLOCK_AUTO",
  },
  {
    label: t("statuses.notSure"),
    value: "NOT_SURE",
  },
];

export const WITHDRAW_TRANSACTION_SEARCH_OPTIONS = (t) => [
  {
    label: t("searchOptions.all"),
    value: "",
  },
  {
    label: t("statuses.success"),
    value: "SUCCESS",
  },
  {
    label: t("statuses.inprogress"),
    value: "PENDING",
  },
  {
    label: t("statuses.error"),
    value: "ERROR",
  },
  {
    label: t("statuses.reject"),
    value: "REJECT",
  },
  {
    label: t("statuses.rejectAndRefund"),
    value: "REJECT_N_REFUND",
  },
  {
    label: t("statuses.blockAuto"),
    value: "BLOCK_AUTO",
  },
];

export const UPDATE_WITHDRAW_STATUS_OPTIONS = (t) => [
  { value: "SUCCESS", label: t("statuses.success") },
  { value: "PENDING", label: t("statuses.pending") },
  { value: "ERROR", label: t("statuses.error") },
  { value: "REJECT", label: t("statuses.reject") },
  { value: "REJECT_N_REFUND", label: t("statuses.rejectAndRefund") },
];

export const LEVEL_VALIDITY_OPTIONS = (t) => [
  { val: "", text: t("levelValidityOptions.none") },
  { val: 1, text: t("levelValidityOptions.1Month") },
  { val: 3, text: t("levelValidityOptions.3Month") },
  { val: 6, text: t("levelValidityOptions.6Month") },
  { val: 12, text: t("levelValidityOptions.1Year") },
  { val: 24, text: t("levelValidityOptions.2Year") },
];

export const STAFF_CREDIT_HISTORY_SEARCH_OPTIONS = (t) => [
  {
    label: t("searchOptions.all"),
    value: "ALL",
  },
  {
    label: t("searchOptions.cutCredit"),
    value: "CUT_CREDIT",
  },
  {
    label: t("searchOptions.noSlip"),
    value: "NO_SLIP",
  },
  {
    label: t("searchOptions.addCredit"),
    value: "ADD_CREDIT",
  },
];

export const MEMBER_DEPOSIT_SEARCH_OPTIONS = () => [
  {
    label: "ทีมีการฝาก",
    value: "1",
  },
  {
    label: "ฝากครั้งแรก",
    value: "3",
  },
];

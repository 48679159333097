export const memberProfileReceivePromotionReportColumnsData = () => {
  return [
    {
      Header: "โปรโมชั่น",
      accessor: "name",
    },
    {
      Header: "ยอดถอน",
      accessor: "bonus",
    },
    {
      Header: "วันเวลาทำรายการ",
      accessor: "createdDate",
      // Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
  ];
};

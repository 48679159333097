import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import i18n from "../src/i18n/i18n";

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Switch>
        <Route path={"/auth"} component={AuthLayout} />
        <Route path={"/admin"} component={AdminLayout} />
        <Redirect from={"/"} to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById("root"),
);

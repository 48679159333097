import { Box, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import AnimatedNumber from "components/AnimatedNumber/AnimatedNumber";
import Card from "components/Card/Card";
import IconBox from "components/Icons/IconBox";
import {
  TransactionApproveIcon,
  TransactionPendingIcon,
} from "components/Icons/Icons";
import React from "react";

const BigReportCard = ({
  isDeposit,
  mainIcon,
  mainAmount,
  isInteger,
  mainCount,
  firstAmount,
  firstCount,
  secondAmount,
  secondCount,
  isLoading,
  t,
}) => {
  const mainText = isDeposit ? "titles.totalDeposit" : "titles.totalWithdraw";
  const firstText = isDeposit
    ? "titles.depositApproved"
    : "titles.withdrawApproved";
  const secondText = isDeposit
    ? "titles.depositPendingReview"
    : "titles.withdrawPendingReview";
  const mainColor = isDeposit ? "#31AA93" : "#BB344C";
  const mode = isDeposit ? "Deposit" : "Withdraw";

  const cleanMainAmount = parseFloat(mainAmount?.replace(/,/g, ""));
  const cleanFirstAmount = parseFloat(firstAmount?.replace(/,/g, ""));
  const cleanSecondAmount = parseFloat(secondAmount?.replace(/,/g, ""));

  return (
    <Card border={`1px solid ${mainColor}`} bgColor="gray.900">
      <Flex
        direction={{ base: "column", xl: "row" }}
        justify="space-between"
        align={{ base: "flex-start", xl: "center" }}
        gap={4}
      >
        <Box flex={{ base: "1", xl: "2" }} w="100%">
          <Flex
            align="center"
            justify={{ base: "space-between", xl: "flex-start" }}
          >
            <IconBox
              w={{ base: "62px", xl: "62px" }}
              h={{ base: "62px", xl: "62px" }}
              mb="16px"
              backgroundColor={mainColor}
            >
              {mainIcon}
            </IconBox>
            <Stack spacing="1" ml="4" textAlign={{ base: "right", xl: "left" }}>
              {isLoading && !cleanMainAmount ? (
                <span className="loader"></span>
              ) : (
                <AnimatedNumber
                  n={cleanMainAmount}
                  dp={isInteger ? 0 : 2}
                  mode={mode}
                  fontSize="32px"
                />
              )}
              <Text fontSize="14px" color="#fff">
                {t(mainText)}
              </Text>
              <Text fontSize="14px" color="gray.500">
                {mainCount + " " + t("labels.entry")}
              </Text>
            </Stack>
          </Flex>
        </Box>

        <Box flex={{ base: "1", xl: "3" }} w="100%">
          <Stack>
            <Flex
              align="center"
              justify="space-between"
              pb={{ base: "0px", xl: "16px" }}
              borderBottom="1px solid #373F48"
              direction="row"
            >
              <Flex align="center" mb={{ base: 2, xl: 0 }}>
                <Icon
                  as={TransactionApproveIcon}
                  color="#8C9592"
                  mr="4"
                  boxSize="22px"
                />
                <Stack spacing="0">
                  <Text fontSize="14px" color="#fff">
                    {t(firstText)}
                  </Text>
                  <Text fontSize="12px" color="#8C9592">
                    {firstCount + " " + t("labels.entry")}
                  </Text>
                </Stack>
              </Flex>
              {isLoading && !cleanFirstAmount ? (
                <span className="loader"></span>
              ) : (
                <AnimatedNumber
                  n={cleanFirstAmount}
                  dp={isInteger ? 0 : 2}
                  mode={mode}
                  fontSize="24px"
                />
              )}
            </Flex>

            <Flex align="center" justify="space-between" pt={2} direction="row">
              <Flex align="center">
                <Icon
                  as={TransactionPendingIcon}
                  boxSize="22px"
                  color="#8C9592"
                  mr="4"
                />
                <Stack spacing="0">
                  <Text fontSize="14px" color="#fff">
                    {t(secondText)}
                  </Text>
                  <Text fontSize="12px" color="#8C9592">
                    {secondCount + " " + t("labels.entry")}
                  </Text>
                </Stack>
              </Flex>
              {isLoading && !cleanSecondAmount ? (
                <span className="loader"></span>
              ) : (
                <AnimatedNumber
                  n={cleanSecondAmount}
                  dp={isInteger ? 0 : 2}
                  mode={mode}
                  fontSize="24px"
                />
              )}
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </Card>
  );
};

export default BigReportCard;

import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import React from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { fetchThieve } from "services/thief/thiefService";
import { thiefColumnsData } from "variables/columns/theif/thiefColumnsData";

const Thief = ({ pageName }) => {
  const queryClient = new QueryClient();
  const { t } = useTranslation("thief");

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
        <Card pb="38px">
          <CardHeader>
            <Flex direction="column">
              <Text color="white" fontSize="18px" fontWeight="bold">
                {t("thiefReport")}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                columnsData={thiefColumnsData(t)}
                query={"thieve"}
                fetchData={fetchThieve}
              />
            </QueryClientProvider>
          </CardBody>
        </Card>
      </Animated>
    </Flex>
  );
};

export default Thief;

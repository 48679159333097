import { Box, Button, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { CreateIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import { usePromotionSetting } from "hooks/business/system-setting/usePromotionSetting";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import DeletePromotionModal from "./components/DeletePromotionModal";
import ManagePromotionModal from "./components/ManagePromotionModal";
import PromotionCard from "./components/PromotionCard";

const PromotionSetting = ({ pageName }) => {
  const { t } = useTranslation("promotionSetting");
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const {
    promotionData,
    handleSubmit,
    handleToggleProvider,
    handleDeletePromotion,
    isDeleting,
  } = usePromotionSetting();

  const [modalState, setModalState] = useState({
    managePromotionModalOpen: false,
    deletePromotionModalOpen: false,
  });

  const handleModalOpen = (modal, promotion = null) => {
    setSelectedPromotion(promotion);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedPromotion(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <CardHeader>
          <Flex
            justifyContent="space-between"
            direction={{ base: "column", xl: "row" }}
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "12px", xl: "24px" }}
            >
              {t("promotionSettings")}
            </Text>
            <Button
              w="150px"
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              variant="solid"
              borderRadius="33px"
              fontSize="14px"
              fontWeight="normal"
              onClick={() => handleModalOpen("managePromotionModal")}
            >
              <Icon as={CreateIcon} mr="5px" />
              {t("addButton")}
            </Button>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <SimpleGrid columns={{ base: 1, xl: 3 }} spacing={6} w="100%">
            {promotionData?.map((promo) => (
              <Box key={promo.id}>
                <PromotionCard
                  promotion={promo}
                  onEdit={() => handleModalOpen("managePromotionModal", promo)}
                  onDelete={() =>
                    handleModalOpen("deletePromotionModal", promo)
                  }
                  t={t}
                />
              </Box>
            ))}
          </SimpleGrid>
        </Animated>
      </Card>
      <ManagePromotionModal
        isOpen={modalState.managePromotionModalOpen}
        onClose={() => handleModalClose("managePromotionModal")}
        promotion={selectedPromotion}
        handleSubmit={handleSubmit}
        handleToggle={handleToggleProvider}
        t={t}
      />
      <DeletePromotionModal
        isOpen={modalState.deletePromotionModalOpen}
        onClose={() => handleModalClose("deletePromotionModal")}
        promotion={selectedPromotion}
        handleSubmit={handleDeletePromotion}
        isSubmitting={isDeleting}
        t={t}
      />
    </Flex>
  );
};

export default PromotionSetting;

export const checkboxStyles = {
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          bg: "#373F48",
          border: "none",
          borderRadius: "3px",
          color: "#fff",
          _checked: {
            bg: "green.500",
            borderColor: "green.500",
            color: "#fff",
            _hover: {
              bg: "green.500",
              borderColor: "green.500",
            },
          },
          _disabled: {
            bg: "gray.400",
            borderColor: "gray.400",
            cursor: "not-allowed",
          },
          _hover: {
            bg: "#373F48",
            borderColor: "#373F48",
          },
        },
      },
      sizes: {
        xl: {
          control: { w: "18px", h: "18px" },
          label: { fontSize: "md" },
        },
      },
      defaultProps: {
        size: "md",
        variant: "solid",
      },
    },
  },
};

export default checkboxStyles;

import { callBasicAuthApi } from "utils/APIUtils";

export const login = (prefix, credentials) => {
  return callBasicAuthApi(
    "/api/admin/auth",
    "POST",
    { prefix: prefix },
    credentials,
  );
};

import { Flex, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import { AddMemberIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useCredit } from "hooks/business/member-management/useCredit";
import { useMember } from "hooks/business/member-management/useMember";
import { useMemberLevel } from "hooks/business/member-management/useMemberLevel";
import { useMemberTag } from "hooks/business/member-management/useMemberTag";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { membersColumnsData } from "variables/columns/member-management/membersColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { MembersSearchTypes } from "variables/types";

import AddCreditModal from "./components/AddCreditModal";
import AddCreditSlipModal from "./components/AddCreditSlipModal";
import ManageMemberModal from "./components/ManageMemberModal";
import RemoveCreditModal from "./components/RemoveCreditModal";
import ResetPasswordModal from "./components/ResetPasswordModal";

const Members = ({ pageName }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("members");

  const [modalState, setModalState] = useState({
    manageMemberModalOpen: false,
    addCreditModalOpen: false,
    addCreditSlipModalOpen: false,
    removeCreditModalOpen: false,
    resetPasswordModalOpen: false,
  });

  const [selectedMember, setSelectedMember] = useState(null);

  const role = JSON.parse(localStorage.getItem(APP_PROFILE)).role;

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    null,
    null,
    false,
  );

  const { handleSaveMember } = useMember();
  const { memberLevelData } = useMemberLevel();
  const { data: memberLevelTagData } = useMemberTag();
  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.ALL,
    membersColumnsData(null, null, t),
  );

  const { handleCredit } = useCredit();

  const handleModalOpen = (modal, user = null) => {
    setSelectedMember(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMember(null);
  };

  const handleTypeChange = (value) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      memberLevel: value === "" ? "" : value,
    }));
  };

  const handleDropDownChange = (value) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      memberTag: value.value === "" ? "" : value.value,
    }));
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "14px", xl: "0px" }}
            >
              {t("manageAllMembers")}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Text mb="18px">ประเภทกสมชิก</Text>
        <Flex mb="20px" justifyContent="left">
          <RadioGroup
            onChange={handleTypeChange}
            value={search.memberLevel || ""}
            colorScheme="green"
          >
            <Stack direction="row" spacing="20px">
              <Radio value="">ทั้งหมด</Radio>
              {memberLevelData.map((level) => (
                <Radio key={level.id} value={level.levelName}>
                  {t(level.levelName)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Flex>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTableRemote
              query={QueryKeys.MEMBERS}
              columnsData={membersColumnsData(handleModalOpen, queryClient, t)}
              fetchData={fetchData}
              search={search}
              setSearch={setSearch}
              showExport={true}
              showAction={true}
              handleModalOpen={handleModalOpen}
              modalName={"manageMemberModal"}
              actionButtonIcon={AddMemberIcon}
              actionButtonText={t("addMember")}
              downloadableData={downloadableData}
              filename={pageName}
              showSearchableDropdown={true}
              searchOptions={memberLevelTagData?.map((tag) => ({
                label: tag.name,
                value: tag.name,
              }))}
              onChange={handleDropDownChange}
              dropDownPlaceholder={"เลือกแท็คสมาชิก"}
            />
          </CardBody>
        </Animated>
      </Card>
      <ManageMemberModal
        isOpen={modalState.manageMemberModalOpen}
        onClose={() => handleModalClose("manageMemberModal")}
        member={selectedMember}
        handleSubmit={handleSaveMember}
        t={t}
      />
      <AddCreditModal
        isOpen={modalState.addCreditModalOpen}
        onClose={() => handleModalClose("addCreditModal")}
        member={selectedMember}
        handleSubmit={handleCredit}
        t={t}
      />
      <AddCreditSlipModal
        isOpen={modalState.addCreditSlipModalOpen}
        onClose={() => handleModalClose("addCreditSlipModal")}
        member={selectedMember}
        handleSubmit={handleCredit}
        t={t}
      />
      <RemoveCreditModal
        isOpen={modalState.removeCreditModalOpen}
        onClose={() => handleModalClose("removeCreditModal")}
        member={selectedMember}
        handleSubmit={handleCredit}
        t={t}
      />
      <ResetPasswordModal
        isOpen={modalState.resetPasswordModalOpen}
        onClose={() => handleModalClose("resetPasswordModal")}
        member={selectedMember}
        t={t}
      />
    </Flex>
  );
};

export default Members;

import { useQuery } from "react-query";
import { fetchDepositPaymentSummary } from "services/transaction-report/depositTransactionReportService";
import { ERROR_MESSAGE } from "variables/system";

import { QueryKeys } from "../../../variables/queryKeys";
import { useErrorHandler } from "../../common/useErrorHandler";

export const useFetchPaymentSummary = (search) => {
  const handleError = useErrorHandler();

  const { createdDateFrom, createdDateTo } = search;
  const { data, isLoading } = useQuery(
    [QueryKeys.PAYMENT_SUMMARY_REPORT, createdDateFrom, createdDateTo],
    async () => {
      const response = await fetchDepositPaymentSummary(search);
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  return {
    paymentSummaryData: data,
    isPaymentSummaryDataLoading: isLoading,
  };
};

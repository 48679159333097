import { Flex, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useWithdrawTransaction } from "hooks/business/transaction-report/useWithdrawTransaction";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withdrawTransactionColumnsData } from "variables/columns/transaction-report/withdrawTransactionReportColumnsData";
import { WITHDRAW_TRANSACTION_SEARCH_OPTIONS } from "variables/options";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";

import ApproveWithdrawBlockAutoTransactionModal from "./components/ApproveWithdrawBlockAutoTransactionModal";
import ManualWithdrawTransactionModal from "./components/ManualWithdrawTransactionModal";
import RefreshWithdrawErrorTransactionModal from "./components/RefreshWithdrawErrorTransactionModal";
import RejectWithdrawTransactionModal from "./components/RejectWithdrawTransactionModal";
import UpdateWithdrawTransactionStatusModal from "./components/UpdateWithdrawTransactionStatusModal";

const WithdrawTransactionReport = ({ pageName }) => {
  const { t } = useTranslation("withdrawReport");

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleBlockAutoTransaction,
    handleRefreshErrorTransaction,
  } = useWithdrawTransaction(
    role,
    QueryKeys.WITHDRAW_TRANSACTION_REPORT,
    withdrawTransactionColumnsData(null, t),
  );

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  const [modalState, setModalState] = useState({
    approveWithdrawBlockAutoTransactionModalOpen: false,
    refreshWithdrawErrorTransactionModalOpen: false,
    rejectWithdrawTransactionModalOpen: false,
    manualWithdrawTransactionModalOpen: false,
    updateWithdrawTransactionStatusModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const handleTypeChange = (value) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      type: value === "ALL" ? "" : value,
    }));
  };

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", xl: "0px" }}
              color="#fff"
            >
              {t("withdrawTransactionReport")}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Text mb="18px">ประเภทการถอน</Text>
        <Flex mb="20px" justifyContent="left">
          <RadioGroup
            onChange={handleTypeChange}
            value={search.type || "ALL"}
            colorScheme="green"
          >
            <Stack direction="row" spacing="20px">
              <Radio value="ALL">{t("searchOptions.all")}</Radio>
              <Radio value="BANK">{t("searchOptions.bank")}</Radio>
              <Radio value="PAYMENTGATEWAY">
                {t("searchOptions.paymentGateway")}
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTableRemote
              query={QueryKeys.WITHDRAW_TRANSACTION_REPORT}
              columnsData={withdrawTransactionColumnsData(handleModalOpen, t)}
              fetchData={fetchData}
              search={search}
              setSearch={setSearch}
              showExport={true}
              downloadableData={downloadableData}
              filename={pageName}
              showSearchableDropdown={true}
              onChange={handleOptionChange}
              searchOptions={WITHDRAW_TRANSACTION_SEARCH_OPTIONS(t)}
            />
          </CardBody>
        </Animated>
      </Card>
      <ApproveWithdrawBlockAutoTransactionModal
        isOpen={modalState.approveWithdrawBlockAutoTransactionModalOpen}
        onClose={() =>
          handleModalClose("approveWithdrawBlockAutoTransactionModal")
        }
        transaction={selectedTransaction}
        handleSubmit={handleBlockAutoTransaction}
        t={t}
      />
      <RefreshWithdrawErrorTransactionModal
        isOpen={modalState.refreshWithdrawErrorTransactionModalOpen}
        onClose={() => handleModalClose("refreshWithdrawErrorTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleRefreshErrorTransaction}
        t={t}
      />
      <RejectWithdrawTransactionModal
        isOpen={modalState.rejectWithdrawTransactionModalOpen}
        onClose={() => handleModalClose("rejectWithdrawTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleBlockAutoTransaction}
        t={t}
      />
      <ManualWithdrawTransactionModal
        isOpen={modalState.manualWithdrawTransactionModalOpen}
        onClose={() => handleModalClose("manualWithdrawTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleBlockAutoTransaction}
        t={t}
      />
      <UpdateWithdrawTransactionStatusModal
        isOpen={modalState.updateWithdrawTransactionStatusModalOpen}
        onClose={() => handleModalClose("updateWithdrawTransactionStatusModal")}
        transaction={selectedTransaction}
        handleSubmit={handleSaveTransaction}
        t={t}
      />
    </Flex>
  );
};

export default WithdrawTransactionReport;

import {
  Flex,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import React from "react";

import AnimatedNumber from "../../../../components/AnimatedNumber/AnimatedNumber";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";

const DepositReportCard = ({
  title,
  totalDeposit,
  totalBonus,
  isInteger,
  t,
}) => {
  const cleanDepositNumber = parseFloat(totalDeposit?.replace(/,/g, ""));
  const cleanBonusNumber = parseFloat(totalBonus?.replace(/,/g, ""));

  return (
    <Card border="1px solid #373F48" bgColor="gray.900">
      <CardBody h="100%">
        <CardHeader>{t(title)}</CardHeader>
        <Flex direction="column" justify="space-between" w="100%" h="100%">
          <Flex justify="space-between" align="center" w="100%">
            <StatGroup w="100%">
              <Stat w="100%">
                <Flex justify="space-between" align="center" w="100%">
                  <Stack align="flex-start">
                    <StatLabel>ยอดฝาก</StatLabel>
                    <StatNumber>
                      <AnimatedNumber
                        n={cleanDepositNumber}
                        dp={isInteger ? 0 : 2}
                        mode={"Deposit"}
                      />
                    </StatNumber>
                  </Stack>
                </Flex>
              </Stat>
            </StatGroup>
            <StatGroup w="100%">
              <Stat w="100%">
                <Flex justify="space-between" align="center" w="100%">
                  <Stack align="flex-start">
                    <StatLabel>ยอดโบนัส</StatLabel>
                    <StatNumber>
                      <AnimatedNumber
                        n={cleanBonusNumber}
                        dp={isInteger ? 0 : 2}
                        mode={"Withdraw"}
                      />
                    </StatNumber>
                  </Stack>
                </Flex>
              </Stat>
            </StatGroup>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default DepositReportCard;

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { LockIcon, ResetPasswordSuccessIcon } from "components/Icons/Icons";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { useSuccessHandler } from "hooks/common/useSuccessHandler";
import React, { useState } from "react";
import { resetPassword } from "services/member-management/memberService";
import { ERROR_MESSAGE } from "variables/system";

const ResetPasswordModal = ({ isOpen, onClose, member, t }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const handleConfirm = async () => {
    setIsLoading(true);
    resetPassword(member.id)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setResponseData(response.data);
          setShowSuccessModal(true);
          handleSuccess(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        onClose();
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    setResponseData(null);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            backgroundColor="gray.800"
            borderTopRadius="12px"
            borderBottom="1px solid rgba(55, 63, 72, 1)"
          >
            <Box textAlign="left">{t("resetPasswordModal.title")}</Box>
          </ModalHeader>
          <ModalCloseButton />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="32px"
          >
            <LockIcon w="80px" h="80px" color="green.500" />
          </Box>
          <ModalBody textAlign="center" mb={2}>
            <Text fontSize="20px" fontWeight="bold" color="gray.500" mb={1}>
              {t("resetPasswordModal.confirmationTitle")}
            </Text>
            <Text color="gray.500" fontSize="14px">
              {t("resetPasswordModal.confirmationMessage")}
            </Text>
            <Text fontWeight="bold" color="green.500" fontSize="14px">
              {member?.username}?
            </Text>
          </ModalBody>
          <ModalFooter mb={6}>
            <Button
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              onClick={handleConfirm}
              isLoading={isLoading}
              fontWeight="normal"
              fontSize="14px"
              w="100%"
            >
              {t("resetPasswordModal.submit")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {responseData && (
        <Modal
          isOpen={showSuccessModal}
          onClose={handleSuccessClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box textAlign="left">{t("resetPasswordModal.title")}</Box>
            </ModalHeader>
            <ModalCloseButton />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt="32px"
            >
              <ResetPasswordSuccessIcon w="80px" h="80px" color="green.500" />
            </Box>
            <ModalBody textAlign="center">
              <Text fontSize="20px" fontWeight="bold" color="green.500" mb={1}>
                {t("resetPasswordModal.successTitle")}
              </Text>
              <Text color="gray.500" fontSize="14px">
                {t("resetPasswordModal.successMessage")}
              </Text>
              <Flex
                justify="space-between"
                bg="#1D2229"
                p={3}
                border="1px solid #373F48"
                borderTopRadius="10px"
                mt="22px"
              >
                <Text fontSize="14px">Username </Text>
                <Text fontSize="14px" fontWeight="bold">
                  {responseData.data.username}
                </Text>
              </Flex>
              <Flex
                justify="space-between"
                bg="#1D2229"
                p={3}
                border="1px solid #373F48"
                borderTop="none"
                borderBottomRadius="10px"
              >
                <Text fontSize="14px">Password</Text>
                <Text fontSize="14px" fontWeight="bold">
                  {responseData.data.password}
                </Text>
              </Flex>
            </ModalBody>
            <ModalFooter mb={6}>
              <Button
                background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                fontWeight="normal"
                fontSize="14px"
                onClick={handleSuccessClose}
                w="100%"
              >
                {t("resetPasswordModal.close")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ResetPasswordModal;

import { Flex, IconButton, Image, Text, useClipboard } from "@chakra-ui/react";
import { CopyIcon } from "components/Icons/Icons";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateformatUtils";

import {
  formatAccountNumber,
  formatPhoneNumber,
} from "../../../utils/CommonUtils";
import { banks } from "../../bank";

export const memberDepositReportColumnsData = (t) => {
  return [
    {
      Header: t("headers.username"),
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<CopyIcon color="green.500" />}
              _hover={{ boxShadow: "none", bg: "none" }}
              // _selected={{ boxShadow: "none", bg: "none" }}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: t("headers.tel"),
      accessor: "tel",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => formatPhoneNumber(cell.value),
    },
    {
      Header: t("headers.fullName"),
      accessor: "fullName",
      disableSortBy: true,
      isSorted: false,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.bankName"),
      accessor: "bankName",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankName]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankName]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            padding="5px"
          />
        </Flex>
      ),
    },
    {
      Header: t("headers.accountNumber"),
      accessor: "accountNumber",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => formatAccountNumber(cell.value),
    },
    {
      Header: t("headers.credit"),
      accessor: "credit",
      disableSortBy: true,
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.createdDate"),
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
      textAlign: "left",
      justifyContent: "flex-start",
    },
    // {
    //   Header: t("headers.createdBy"),
    //   accessor: "createdBy",
    //   disableSortBy: true,
    //   textAlign: "left",
    //   justifyContent: "flex-start",
    // },
    {
      Header: t("headers.marketing"),
      accessor: "marketing",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => (
        <Text fontWeight="normal">{cell.value ? cell.value : "-"}</Text>
      ),
    },
    {
      Header: t("headers.ip"),
      accessor: "ip",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
  ];
};

import { fetchMemberTransferLog } from "../../services/member-management/memberProfileService";

export const useMemberTransferLog = (username) => {
  const fetchData = async (pageIndex, pageSize, search) => {
    return await fetchMemberTransferLog(pageIndex, pageSize, {
      username,
      ...search,
    });
  };

  return {
    fetchData,
  };
};

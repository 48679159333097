import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchJackpot,
  saveJackpot,
  submitJackpot,
  verifyJackpot,
} from "services/system-setting/jackpotSettingService";
import {
  transformJackpotArrayToJson,
  transformJackpotJsonToArray,
} from "utils/JackpotUtils";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useJackpotSetting = () => {
  const [jackpotData, setJackpotData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [verifySuccessMessage, setVerifySuccessMessage] = useState("");
  const [verifyUnSuccessMessage, setVerifyUnSuccessMessage] = useState("");

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const { isLoading } = useQuery(QueryKeys.JACKPOT_SETTING, fetchJackpot, {
    onSuccess: (response) => {
      const data = response.data.data;
      const formattedData = transformJackpotJsonToArray(data);
      setJackpotData(formattedData);
      setIsActive(data.active);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
    },
  });

  const saveMutation = useMutation((newData) => saveJackpot(newData), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.JACKPOT_SETTING);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
    },
  });

  // Verify jackpot
  const verifyJackpotMutation = useMutation(
    (username) => verifyJackpot({ username }),
    {
      onSuccess: () => {
        setIsUserVerified(true);
        setVerifySuccessMessage("giveJackpotModal.verification.successMessage");
        setVerifyUnSuccessMessage("");
      },
      onError: () => {
        setIsUserVerified(false);
        setVerifySuccessMessage("");
        setVerifyUnSuccessMessage(
          "giveJackpotModal.verification.failureMessage",
        );
      },
    },
  );

  // Give jackpot
  const submitJackpotMutation = useMutation((values) => submitJackpot(values), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      setVerifySuccessMessage("");
      setVerifyUnSuccessMessage("");
      queryClient.invalidateQueries(QueryKeys.JACKPOT_SETTING);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
      setVerifySuccessMessage("");
      setVerifyUnSuccessMessage("");
    },
  });

  const handleValueChange = (id, field, value) => {
    setJackpotData((prevData) => {
      return prevData.map((jackpot) =>
        jackpot.id === id
          ? { ...jackpot, [field]: parseFloat(value) }
          : jackpot,
      );
    });
  };

  const handleSave = () => {
    const data = transformJackpotArrayToJson(jackpotData);
    const combinedData = { ...data, active: isActive };
    saveMutation.mutate(combinedData);
  };

  const handleSwitchChange = () => {
    setIsActive((prev) => !prev);
  };

  const handleVerifyJackpot = (username) => {
    verifyJackpotMutation.mutate(username);
  };

  const handleGiveJackpot = (values, actions, onClose) => {
    submitJackpotMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  const handleSaveJackpotBotSetting = (values, actions, onClose) => {
    console.log(values);
    saveMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  return {
    jackpotData,
    isLoading,
    isActive,
    isSaving: saveMutation.isLoading,
    isUserVerified,
    verifySuccessMessage,
    verifyUnSuccessMessage,
    handleValueChange,
    handleSave,
    handleSwitchChange,
    handleVerifyJackpot,
    handleGiveJackpot,
    handleSaveJackpotBotSetting,
  };
};

import { Box, Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import { Loader } from "components/Loader/Loader";
import SimpleTable from "components/Tables/SimpleTable";
import React from "react";
import { gameSettingColumnsData } from "variables/columns/system-setting/gameSettingColumnsData";

const ProviderCard = ({ title, providerData, isLoading, handleToggle, t }) => {
  return (
    <Animated animation="fadeInUp" duration="0.6s" delay="0.1s" overflow="auto">
      <Flex direction="column" w="100%">
        <Text
          color="white"
          fontSize="18px"
          fontWeight="bold"
          mb="12px"
          mt="12px"
        >
          {t("providerCard.title")} {t(title)}
        </Text>
        {isLoading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            height="400px"
            w="100%"
          >
            <Loader />
          </Flex>
        ) : (
          <Box h="375px" w="100%" overflowX="auto">
            <SimpleTable
              tableData={providerData}
              columnsData={gameSettingColumnsData(handleToggle, t)}
              rowSize={providerData?.length}
            />
          </Box>
        )}
      </Flex>
    </Animated>
  );
};

export default ProviderCard;

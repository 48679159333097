import { Box, ChakraProvider, Flex, Text } from "@chakra-ui/react";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "assets/css/pud-dashboard-styles.css";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import theme from "theme/theme.js";

import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import useDashRoutes from "../hooks/common/useDashRoutes.js";
import i18n from "../i18n/i18n";
import SignInBasic from "../views/auth";

theme.config.initialColorMode = "dark";

export default function Pages() {
  const routes = useDashRoutes();
  const wrapper = React.createRef();
  const [currentLanguage, setCurrentLanguage] = useState("th");

  useEffect(() => {
    document.body.style.overflow = "unset";
    return function cleanup() {};
  });

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleLanguageChange = (code) => {
    setCurrentLanguage(code);
    i18n.changeLanguage(code);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formattedDateTime = currentDateTime.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  const navRef = React.useRef();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "auth";
  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box ref={navRef} w="100%">
        <Box w="100%">
          <Box ref={wrapper} w="100%">
            <Box position="fixed" top="16px" right="16px" display="flex">
              <Flex direction="column" alignItems="flex-end" mr="10px" mt="2px">
                <Text fontSize="12px">GMT+7 Bangkok</Text>
                <Text fontSize="12px">{formattedDateTime}</Text>
              </Flex>
              <LanguageSelector
                currentLanguage={currentLanguage}
                handleLanguageChange={handleLanguageChange}
              />
            </Box>
            <Switch>
              {getRoutes(routes)}
              <Route path="/auth/login" component={SignInBasic}></Route>
              <Redirect from="/auth" to="/auth/login" />
            </Switch>
          </Box>
        </Box>
        <Box px="24px" mx="auto" width="1044px" maxW="100%">
          {/*<footer />*/}
        </Box>
      </Box>
    </ChakraProvider>
  );
}

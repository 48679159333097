import { Box, FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

export const TransactionReceipt = ({ transaction }) => {
  return (
    <Box>
      <FormControl>
        <FormLabel>ยูสเซอร์เนม</FormLabel>
        <Input
          fontSize="sm"
          value={transaction?.username}
          readOnly
          backgroundColor="#373F48"
          _hover="none"
          _focus={{
            boxShadow: "none",
            outline: "none",
          }}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>ชื่อ-นามสกุล</FormLabel>
        <Input
          fontSize="sm"
          value={transaction?.fullName}
          readOnly
          backgroundColor="#373F48"
          _hover="none"
          _focus={{
            boxShadow: "none",
            outline: "none",
          }}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>เลขที่บัญชี</FormLabel>
        <Input
          fontSize="sm"
          value={transaction?.accountNumber}
          readOnly
          backgroundColor="#373F48"
          _hover="none"
          _focus={{
            boxShadow: "none",
            outline: "none",
          }}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>จำนวนเงินถอน</FormLabel>
        <Input
          fontSize="sm"
          value={transaction?.realAmount}
          readOnly
          backgroundColor="#373F48"
          _hover="none"
          _focus={{
            boxShadow: "none",
            outline: "none",
          }}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>วันที่ทำรายการ</FormLabel>
        <Input
          fontSize="sm"
          value={transaction?.createdDate}
          readOnly
          backgroundColor="#373F48"
          _hover="none"
          _focus={{
            boxShadow: "none",
            outline: "none",
          }}
        />
      </FormControl>
    </Box>
  );
};

import { Flex, Grid, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useDepositTransaction } from "hooks/business/transaction-report/useDepositTransaction";
import { useFetchPaymentSummary } from "hooks/business/transaction-report/useFetchPaymentSummary";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { depositTransactionReportColumnsData } from "variables/columns/transaction-report/depositTransactionReportColumnsData";
import { DEPOSIT_TRANSACTION_SEARCH_OPTIONS } from "variables/options";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { DepositTransactionTypes } from "variables/types";

import ApproveInvalidTransactionModal from "../invalid/components/ApproveInvalidTransactionModal";
import RejectInvalidTransactionModal from "../invalid/components/RejectInvalidTransactionModal";
import ApproveBlockAutoTransactionModal from "./components/ApproveBlockAutoTransactionModal";
import DepositReportCard from "./components/DepositReportCard";
import QrCodeModal from "./components/QrCodeModal";
import RejectBlockAutoTransactionModal from "./components/RejectBlockAutoTransactionModal";

const DepositTransactionReport = ({ pageName }) => {
  const { t } = useTranslation("depositReport");
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleInvalidTransaction,
  } = useDepositTransaction(
    role,
    QueryKeys.DEPOSIT_TRANSACTION_REPORT,
    depositTransactionReportColumnsData(null, t),
    DepositTransactionTypes.ALL,
  );

  const { paymentSummaryData } = useFetchPaymentSummary(search);

  const DEPOSIT_TYPES = [
    { type: "BANK", val: "searchOptions.bank" },
    { type: "TRUEWALLET", val: "searchOptions.trueWallet" },
    { type: "PAYMENTGATEWAY", val: "searchOptions.paymentGateway" },
    { type: "MANUAL", val: "searchOptions.manual" },
    { type: "REFUNDWINLOSE", val: "searchOptions.refundWinLose" },
  ];

  const normalizedSummaryData = DEPOSIT_TYPES.map((type) => {
    const found = paymentSummaryData?.find((item) => item.type === type.type);
    return {
      type: type.val,
      totalDeposit: found?.totalDeposit ?? 0,
      totalBonus: found?.totalBonus ?? 0,
    };
  });

  const [modalState, setModalState] = useState({
    approveBlockAutoTransactionModalOpen: false,
    rejectBlockAutoTransactionModalOpen: false,
    approveInvalidTransactionModalOpen: false,
    rejectInvalidTransactionModalOpen: false,
    qrCodeModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  const handleTypeChange = (value) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      type: value === "ALL" ? "" : value,
    }));
  };

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Animated animation="fadeInUp" duration="0.5s" delay="0.1s">
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(5, 1fr)" }}
          gap="12px"
          pb="12px"
        >
          {normalizedSummaryData.map(({ type, totalDeposit, totalBonus }) => (
            <DepositReportCard
              key={type}
              title={type}
              totalDeposit={totalDeposit.toString()}
              totalBonus={totalBonus.toString()}
              isInteger={false}
              t={t}
            />
          ))}
        </Grid>
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", xl: "0px" }}
              color="#fff"
            >
              {t("depositTransactionReport")}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Text mb="18px">ประเภทการฝาก</Text>
        <Flex mb="20px" justifyContent="left">
          <RadioGroup
            onChange={handleTypeChange}
            value={search.type || "ALL"}
            colorScheme="green"
          >
            <Stack direction="row" spacing="20px">
              <Radio value="ALL">{t("searchOptions.all")}</Radio>
              <Radio value="BANK">{t("searchOptions.bank")}</Radio>
              <Radio value="TRUEWALLET">{t("searchOptions.trueWallet")}</Radio>
              <Radio value="PAYMENT">{t("searchOptions.paymentGateway")}</Radio>
              <Radio value="MANUAL">{t("searchOptions.manual")}</Radio>
              <Radio value="REFUNDWINLOSE">
                {t("searchOptions.refundWinLose")}
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTableRemote
              query={QueryKeys.DEPOSIT_TRANSACTION_REPORT}
              columnsData={depositTransactionReportColumnsData(
                handleModalOpen,
                t,
              )}
              fetchData={fetchData}
              search={search}
              setSearch={setSearch}
              showExport={true}
              downloadableData={downloadableData}
              filename={pageName}
              showSearchableDropdown={true}
              onChange={handleOptionChange}
              searchOptions={DEPOSIT_TRANSACTION_SEARCH_OPTIONS(t)}
            />
          </CardBody>
        </Animated>
      </Card>
      <ApproveBlockAutoTransactionModal
        isOpen={modalState.approveBlockAutoTransactionModalOpen}
        onClose={() => handleModalClose("approveBlockAutoTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleSaveTransaction}
        t={t}
      />
      <RejectBlockAutoTransactionModal
        isOpen={modalState.rejectBlockAutoTransactionModalOpen}
        onClose={() => handleModalClose("rejectBlockAutoTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleSaveTransaction}
        t={t}
      />
      <ApproveInvalidTransactionModal
        isOpen={modalState.approveInvalidTransactionModalOpen}
        onClose={() => handleModalClose("approveInvalidTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleInvalidTransaction}
        t={t}
      />
      <RejectInvalidTransactionModal
        isOpen={modalState.rejectInvalidTransactionModalOpen}
        onClose={() => handleModalClose("rejectInvalidTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleInvalidTransaction}
        t={t}
      />
      <QrCodeModal
        isOpen={modalState.qrCodeModalOpen}
        onClose={() => handleModalClose("qrCodeModal")}
        transaction={selectedTransaction}
      />
    </Flex>
  );
};

export default DepositTransactionReport;

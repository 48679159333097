import { Badge, Flex, Text } from "@chakra-ui/react";
import { numberWithCommas } from "utils/ColumnsUtils";
import {
  getStaffCreditStatusBackground,
  getStaffCreditStatusNiceName,
} from "utils/CommonUtils";

export const staffCreditHistoryColumnsData = (t) => [
  {
    Header: t("headers.mistakeType"),
    accessor: "mistakeType",
    textAlign: "left",
    // width: "135px",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={getStaffCreditStatusBackground(cell.value)}
          w="85px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="normal"
        >
          {t(getStaffCreditStatusNiceName(cell.value))}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: t("headers.username"),
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.beforeAmount"),
    accessor: "beforeAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: t("headers.amount"),
    accessor: "amount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text fontWeight="bold" color="#fff">
        {numberWithCommas(value)}
      </Text>
    ),
  },
  {
    Header: t("headers.afterAmount"),
    accessor: "afterAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => numberWithCommas(value),
  },

  {
    Header: t("headers.createdDate"),
    accessor: "createdDate",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.reason"),
    accessor: "reason",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.createdBy"),
    accessor: "createdBy",
    textAlign: "left",
    justifyContent: "flex-start",
  },
];

import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { fetchMarketingDepositHistories } from "services/transaction-report/depositTransactionReportService";
import {
  createBlockAutoWithdrawTransaction,
  fetchWithdrawHistories,
  refreshErrorWithdrawTransaction,
  updateWithdrawTransactionStatus,
} from "services/transaction-report/withdrawTransactionReportService";
import { mapJsonToTableData } from "utils/CommonUtils";
import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useWithdrawTransaction = (role, queryKey, columnsData) => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [downloadableData, setDownloadableData] = useState([]);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchWithdrawHistories(pageIndex, pageSize, search)
        : await fetchMarketingDepositHistories(pageIndex, pageSize, search);

    const formattedRecords = mapJsonToTableData(response.results, columnsData);

    setDownloadableData(formattedRecords);
    return response;
  };

  const updateWithdrawTransactionMutation = useMutation(
    updateWithdrawTransactionStatus,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const updateBlockAutoWithdrawTransactionMutation = useMutation(
    createBlockAutoWithdrawTransaction,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const refreshErrorWithdrawTransactionMutation = useMutation(
    refreshErrorWithdrawTransaction,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const handleSaveTransaction = (values, actions, onClose) => {
    updateWithdrawTransactionMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  const handleBlockAutoTransaction = (values, actions, onClose) => {
    updateBlockAutoWithdrawTransactionMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  const handleRefreshErrorTransaction = (values, actions, onClose) => {
    refreshErrorWithdrawTransactionMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  return {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleBlockAutoTransaction,
    handleRefreshErrorTransaction,
  };
};

import { formatDate } from "utils/DateformatUtils";

export const memberRefundWinLoseReportColumnsData = () => {
  return [
    {
      Header: "ยอดเงิน",
      accessor: "bonus",
    },
    {
      Header: "วันเวลาทำรายการ",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};

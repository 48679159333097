import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { commissionSettingColumnsData } from "variables/columns/system-setting/commissionSettingColumnsData";

import CommissionSettingModal from "./ComissionSettingModal";

const CommissionForm = ({
  title,
  initialValues,
  handleSubmit,
  isCommissionDataLoading,
  isSaving,
  t,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState(null);
  const [modalIndex, setModalIndex] = useState(null);

  const isLoading = isCommissionDataLoading;

  const openModal = (data, index) => {
    setModalData(data);
    setModalIndex(index);
    onOpen();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const handleModalSubmit = (updatedData) => {
          setFieldValue(`commissions.${modalIndex}.setting`, updatedData);
          onClose();
        };

        const tableData = values.commissions?.map((commission, index) => ({
          ...commission,
          index,
        }));

        return (
          <Box overflow="auto">
            <Form>
              <Flex direction="column" w="100%" pb={6}>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  mb="12px"
                  pt="12px"
                  color="#fff"
                >
                  {t("commissionSettings")}{" "}
                  <Box as="span" color="green.500" fontWeight="bold">
                    {t(title)}
                  </Box>
                </Text>
                <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                  <FieldArray name="commissions" enableReinitialize>
                    {() => (
                      <SimpleTable
                        columnsData={commissionSettingColumnsData(openModal, t)}
                        tableData={tableData}
                        rowSize={tableData?.length}
                        isLoading={isLoading}
                      />
                    )}
                  </FieldArray>
                </Animated>
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  width="100%"
                  mt={6}
                >
                  <Box
                    bg="rgba(30, 37, 44, 1)"
                    borderRadius="8px"
                    p={4}
                    mb={4}
                    color="gray.500"
                    fontSize="14px"
                  >
                    <Text fontWeight="bold" mb={2}>
                      {t("fields.note.label")}
                    </Text>
                    <Text>{t("fields.note.message1")}</Text>
                    <Text>{t("fields.note.message2")}</Text>
                  </Box>
                </Flex>
                <Flex justifyContent="flex-start" width="100%" mt={6}>
                  <Button
                    type="submit"
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    width={{ base: "100%", xl: "auto" }}
                    minW={{ base: "none", xl: "195px" }}
                    fontWeight="normal"
                    fontSize="14px"
                    isLoading={isSaving}
                  >
                    {t("fields.saveButton")}
                  </Button>
                </Flex>
              </Flex>
              {modalData && (
                <CommissionSettingModal
                  isOpen={isOpen}
                  onClose={onClose}
                  modalData={modalData}
                  handleSubmit={handleModalSubmit}
                  t={t}
                />
              )}
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};

export default CommissionForm;

import { Flex, Grid } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import PageHeader from "components/Layout/PageHeader";
import { useCommissionSetting } from "hooks/business/system-setting/useCommissionSetting";
import React from "react";
import { useTranslation } from "react-i18next";

import CommissionForm from "./components/CommissionForm";

const CommissionSetting = ({ pageName }) => {
  const {
    commissionData,
    isCommissionLoading,
    isSaving,
    handleCommissionSubmit,
  } = useCommissionSetting();

  const { t } = useTranslation("commissionSetting");

  const handleCommissionTurnOver = (values) => {
    handleCommissionSubmit("commissionTurnOver", values);
  };

  const handleCommissionAffiliate = (values) => {
    handleCommissionSubmit("commissionAffiliate", values);
  };

  const handleCommissionWinlose = (values) => {
    handleCommissionSubmit("commissionWinlose", values);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <Grid templateColumns={{ base: "1fr", xl: "repeat(1, 1fr)" }} gap={6}>
          <CommissionForm
            title="sections.affiliate"
            initialValues={{
              commissions: commissionData?.commissionAffiliate?.sort(
                (a, b) => a.level - b.level,
              ),
            }}
            handleSubmit={handleCommissionAffiliate}
            isCommissionDataLoading={isCommissionLoading}
            isSaving={isSaving}
            t={t}
          />
          <CommissionForm
            title="sections.winLose"
            initialValues={{
              commissions: commissionData?.commissionWinlose?.sort(
                (a, b) => a.level - b.level,
              ),
            }}
            handleSubmit={handleCommissionWinlose}
            isCommissionDataLoading={isCommissionLoading}
            isSaving={isSaving}
            t={t}
          />
          <CommissionForm
            title="sections.turnOver"
            initialValues={{
              commissions: commissionData?.commissionTurnOver?.sort(
                (a, b) => a.level - b.level,
              ),
            }}
            handleSubmit={handleCommissionTurnOver}
            isCommissionDataLoading={isCommissionLoading}
            isSaving={isSaving}
            t={t}
          />
        </Grid>
      </Card>
    </Flex>
  );
};

export default CommissionSetting;

import { Text } from "@chakra-ui/react";

export const dailySummaryReportColumnsData = () => [
  {
    Header: "วัน",
    disableSortBy: true,
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ row }) => (
      <Text>
        {`${row.original.day}-${row.original.month}-${row.original.year}`}
      </Text>
    ),
  },
  {
    Header: "ยอดฝากเงิน",
    accessor: "depositAmount",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "ยอดถอนเงิน",
    accessor: "withdrawAmount",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "ยอดฝาก (มือ)",
    accessor: "depositMistakeAmount",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "ยอดถอน (มือ)",
    accessor: "withdrawMistakeAmount",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "ยอดโบนัส",
    accessor: "withdraw",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: () => 0.0,
  },
  {
    Header: "ยอดแนะนำเพื่อน",
    accessor: "test", // to change
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: () => 0.0,
  },
  {
    Header: "ยอดคอมมิชชั่น",
    accessor: "testx", // to change
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: () => 0.0,
  },
  {
    Header: "ยอดเสีย",
    accessor: "testxxx", // to change
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: () => 0.0,
  },
  {
    Header: "ยอดวงล้อ",
    accessor: "luckywheelAmount", // to change
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "ยอดแจ๊คพ๊อต",
    accessor: "jackpotAmount", // to change
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "กำไร",
    accessor: "profitAmount",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    minWidth: "425px",
  },
];

import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from "@chakra-ui/react";
// import CN from "assets/img/flags/CN.png";
import TH from "assets/img/flags/TH.png";
import UK from "assets/img/flags/UK.png";

const LanguageSelector = ({ currentLanguage, handleLanguageChange }) => {
  const languages = [
    // { code: "cn", label: "CN", src: CN },
    { code: "en", label: "EN", src: UK },
    { code: "th", label: "TH", src: TH },
  ];

  const selectedLanguage = languages.find(
    (lang) => lang.code === currentLanguage,
  );

  const isDropdown = useBreakpointValue({ base: true, xl: false });

  return (
    <>
      {isDropdown ? (
        // Dropdown for small screens
        <Menu>
          <MenuButton
            as={Button}
            background="transparent"
            p="0"
            borderRadius="5px"
            minW="0px"
            minH="0px"
          >
            <Flex align="center">
              <Image
                src={selectedLanguage?.src}
                alt={selectedLanguage?.label}
                width="32px"
                height="24px"
                borderRadius="5px"
              />
            </Flex>
          </MenuButton>
          <MenuList minW="0px" zIndex="999" bg="#1D2229">
            {languages.map((lang) => (
              <MenuItem
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code)}
              >
                <Flex align="center">
                  <Image
                    src={lang.src}
                    alt={lang.label}
                    width="32px"
                    height="24px"
                    borderRadius="5px"
                    mr="2"
                  />
                  <Box fontSize="14px">{lang.label}</Box>
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : (
        // Buttons for large screens
        <Flex>
          {languages.map((lang) => (
            <Button
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              // ml="2"
              background="transparent"
              p="0"
              borderRadius="5px"
            >
              <Image
                src={lang.src}
                alt={lang.label}
                width="32px"
                height="24px"
                borderRadius="5px"
              />
            </Button>
          ))}
        </Flex>
      )}
    </>
  );
};

export default LanguageSelector;

import { Flex, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { CopyIcon } from "components/Icons/Icons";
import { useHistory } from "react-router-dom";

export const memberTransactionColumnsData = (t) => {
  return [
    {
      Header: t("headers.username"),
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<CopyIcon color="green.500" />}
              _hover={{ boxShadow: "none", bg: "none" }}
              // _selected={{ boxShadow: "none", bg: "none" }}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: t("headers.depositCount"),
      accessor: "depositCount",
    },
    {
      Header: t("headers.deposit"),
      accessor: "deposit",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.withdrawCount"),
      accessor: "withdrawCount",
    },
    {
      Header: t("headers.withdraw"),
      accessor: "withdraw",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: t("headers.profitLoss"),
      accessor: "profitLoss",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text
          color={value === 0 ? "#fff" : value < 0 ? "red.500" : "green.500"}
          fontWeight="bold"
        >
          {value}
        </Text>
      ),
    },
  ];
};

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import { Form, Formik } from "formik";
import { useConditionSetting } from "hooks/business/system-setting/useConditionSetting";
import React from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { REFUND_TYPE_OPTIONS } from "variables/options";

const ConditionSetting = ({ pageName }) => {
  const {
    conditionData,
    showInputField2,
    setShowInputField2,
    showColumn2Inputs,
    setShowColumn2Inputs,
    showColumn3Input,
    setShowColumn3Input,
    handleSubmit,
    isSaving,
  } = useConditionSetting();
  const { t } = useTranslation("conditionSetting");

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <CardHeader pb={6}>
          <Text fontSize="18px" fontWeight="bold" color="#fff">
            {t("conditionSetting")}
          </Text>
        </CardHeader>
        <Formik
          initialValues={{
            prefix: conditionData?.prefix || "",
            website: conditionData?.website || "",
            runningText: conditionData?.runningText || "",
            lineId: conditionData?.lineId || "",
            lineToken: conditionData?.lineToken || "",
            lineTokenWithdraw: conditionData?.lineTokenWithdraw || "",
            telegramToken: conditionData?.telegramToken || "",
            telegramDeposit: conditionData?.telegramDeposit || "",
            telegramWithdraw: conditionData?.telegramWithdraw || "",
            config: conditionData?.config || {
              allowOutstandingBet: "false",
              approveWithdrawAuto: false,
              limitWithDraw: false,
              winLoseRefund: true,
              winLoseRefundMax: "0",
              onOffWebsite: true,
              minWithdrawCredit: "1",
              forceWithdrawAll: false,
              countWithDraw: "1000",
              winLoseRefundTurn: "0",
              winLoseRefundType: "WEEKLY",
              checkBonusWithdraw: false,
              maxAutoWithdraw: 20,
              winLoseRefundRate: 5,
              approveWithdrawAutoNew: true,
              checkAcctFlag: false,
            },
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Stack direction="column" spacing="15px" w="100%">
                  <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      p={0}
                      boxShadow="none"
                      borderBottom="1px solid #373F48"
                      borderRadius="0px"
                      pb="48px"
                    >
                      <Element id="info" name="info">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "repeat(1, 1fr)",
                              xl: "repeat(3, 1fr)",
                            }}
                            gap="20px"
                            w="100%"
                          >
                            <GridItem colSpan={3}>
                              <Flex>
                                <Switch
                                  isChecked={values.config.onOffWebsite}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "config.onOffWebsite",
                                      e.target.checked,
                                    )
                                  }
                                />
                                <Text
                                  fontWeight="normal"
                                  fontSize="16px"
                                  ml={4}
                                >
                                  {t("form.onOffWebsite.label")}
                                </Text>
                              </Flex>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>{t("form.prefix.label")}</FormLabel>
                                <Input
                                  fontSize="sm"
                                  value={values.prefix}
                                  readOnly
                                  backgroundColor="#373F48"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>{t("form.website.label")}</FormLabel>
                                <Input
                                  placeholder={t("form.website.placeholder")}
                                  fontSize="sm"
                                  value={values.website}
                                  onChange={handleChange}
                                  name="website"
                                  readOnly
                                  backgroundColor="#373F48"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>{t("form.lineId.label")}</FormLabel>
                                <Input
                                  placeholder={t("form.lineId.placeholder")}
                                  fontSize="sm"
                                  value={values.lineId}
                                  onChange={handleChange}
                                  name="lineId"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  {t("form.runningText.label")}
                                </FormLabel>
                                <Input
                                  placeholder={t(
                                    "form.runningText.placeholder",
                                  )}
                                  fontSize="sm"
                                  value={values.runningText}
                                  onChange={handleChange}
                                  name="runningText"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  {t("form.lineToken.label")}{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    {t("form.lineToken.notice")}
                                  </Box>
                                </FormLabel>
                                <Input
                                  placeholder={t("form.lineToken.placeholder")}
                                  fontSize="sm"
                                  value={values.lineToken}
                                  onChange={handleChange}
                                  name="lineToken"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  {t("form.lineTokenWithdraw.label")}{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    {t("form.lineTokenWithdraw.notice")}
                                  </Box>
                                </FormLabel>
                                <Input
                                  placeholder={t(
                                    "form.lineTokenWithdraw.placeholder",
                                  )}
                                  fontSize="sm"
                                  value={values.lineTokenWithdraw}
                                  onChange={handleChange}
                                  name="lineTokenWithdraw"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  {t("form.telegramToken.label")}
                                </FormLabel>
                                <Input
                                  placeholder={t(
                                    "form.telegramToken.placeholder",
                                  )}
                                  fontSize="sm"
                                  value={values.telegramToken}
                                  onChange={handleChange}
                                  name="telegramToken"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  {t("form.telegramDeposit.label")}
                                </FormLabel>
                                <Input
                                  placeholder={t(
                                    "form.telegramDeposit.placeholder",
                                  )}
                                  fontSize="sm"
                                  value={values.telegramDeposit}
                                  onChange={handleChange}
                                  name="telegramDeposit"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, xl: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  {t("form.telegramWithdraw.label")}
                                </FormLabel>
                                <Input
                                  placeholder={t(
                                    "form.telegramWithdraw.placeholder",
                                  )}
                                  fontSize="sm"
                                  value={values.telegramWithdraw}
                                  onChange={handleChange}
                                  name="telegramWithdraw"
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Card>
                  </Animated>
                  <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      p={0}
                      boxShadow="none"
                      borderBottom="1px solid #373F48"
                      borderRadius="0px"
                      pb="48px"
                    >
                      <Grid
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          xl: "repeat(3, 1fr)",
                        }}
                        gap="20px"
                        w="100%"
                      >
                        <GridItem colSpan={{ base: 3, xl: 1 }}>
                          <FormLabel>
                            {t("config.minWithdrawCredit.label")}
                          </FormLabel>
                          <NumberInput
                            value={values.config.minWithdrawCredit}
                            onChange={(valueString) =>
                              setFieldValue(
                                "config.minWithdrawCredit",
                                valueString,
                              )
                            }
                            min={0}
                          >
                            <NumberInputField
                              placeholder={t(
                                "config.minWithdrawCredit.placeholder",
                              )}
                              fontSize="sm"
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, xl: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>
                              {t("config.approveWithdrawAuto.label")}
                            </FormLabel>
                            <Switch
                              isChecked={values.config.approveWithdrawAuto}
                              onChange={(e) => {
                                setFieldValue(
                                  "config.approveWithdrawAuto",
                                  e.target.checked,
                                );
                                setShowInputField2(e.target.checked);
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, xl: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>
                              {t("config.forceWithdrawAll.label")}
                            </FormLabel>
                            <Switch
                              isChecked={values.config.forceWithdrawAll}
                              onChange={(e) =>
                                setFieldValue(
                                  "config.forceWithdrawAll",
                                  e.target.checked,
                                )
                              }
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ base: 3, xl: 1 }}>
                          {showInputField2 && (
                            <FormControl>
                              <FormLabel>
                                {t("config.maxAutoWithdraw.label")}
                              </FormLabel>
                              <NumberInput
                                value={values.config.maxAutoWithdraw}
                                onChange={(valueString) =>
                                  setFieldValue(
                                    "config.maxAutoWithdraw",
                                    valueString,
                                  )
                                }
                                min={0}
                              >
                                <NumberInputField
                                  placeholder={t(
                                    "config.maxAutoWithdraw.placeholder",
                                  )}
                                  fontSize="sm"
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </FormControl>
                          )}
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, xl: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>
                              {t("config.checkAcctFlag.label")}
                            </FormLabel>
                            <Switch
                              isChecked={values.config.checkAcctFlag}
                              onChange={(e) =>
                                setFieldValue(
                                  "config.checkAcctFlag",
                                  e.target.checked,
                                )
                              }
                              disabled
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, xl: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>
                              {t("config.checkBonusWithdraw.label")}
                            </FormLabel>
                            <Switch
                              isChecked={values.config.checkBonusWithdraw}
                              onChange={(e) =>
                                setFieldValue(
                                  "config.checkBonusWithdraw",
                                  e.target.checked,
                                )
                              }
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </Card>
                  </Animated>
                  <Animated animation="fadeInUp" duration="0.9s" delay="0.1s">
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      p={0}
                      boxShadow="none"
                      borderRadius="0px"
                    >
                      <Element id="config" name="config">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "repeat(1, 1fr)",
                              xl: "repeat(2, 1fr)",
                            }}
                            gap="20px"
                            width="100%"
                          >
                            <GridItem>
                              <Box>
                                <FormControl mb={4} display="flex">
                                  <FormLabel>
                                    {t("config.winLoseRefund.label")}
                                  </FormLabel>
                                  <Switch
                                    isChecked={values.config.winLoseRefund}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "config.winLoseRefund",
                                        e.target.checked,
                                      );
                                      setShowColumn2Inputs(e.target.checked);
                                    }}
                                  />
                                </FormControl>
                                {showColumn2Inputs && (
                                  <>
                                    <FormControl mb={4}>
                                      <FormLabel
                                        fontWeight="normal"
                                        fontSize="16px"
                                      >
                                        {t("config.winLoseRefundType.label")}
                                      </FormLabel>
                                      <Select
                                        placeholder={t(
                                          "config.winLoseRefundType.placeholder",
                                        )}
                                        fontSize="sm"
                                        value={values.config.winLoseRefundType}
                                        borderRadius="33px"
                                        onChange={(e) =>
                                          setFieldValue(
                                            "config.winLoseRefundType",
                                            e.target.value,
                                          )
                                        }
                                      >
                                        {REFUND_TYPE_OPTIONS(t).map(
                                          (type, id) => (
                                            <option
                                              key={id}
                                              value={type.typeCode}
                                            >
                                              {type.typeName}
                                            </option>
                                          ),
                                        )}
                                      </Select>
                                    </FormControl>

                                    <FormControl mb={4}>
                                      <FormLabel>
                                        {" "}
                                        {t(
                                          "config.winLoseRefundRate.label",
                                        )}{" "}
                                        <Box
                                          as="span"
                                          color="green.500"
                                          fontWeight="normal"
                                        >
                                          (%)
                                        </Box>
                                      </FormLabel>
                                      <NumberInput
                                        value={values.config.winLoseRefundRate}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            "config.winLoseRefundRate",
                                            valueString,
                                          )
                                        }
                                        min={0}
                                      >
                                        <NumberInputField
                                          placeholder={t(
                                            "config.winLoseRefundRate.placeholder",
                                          )}
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>

                                    <FormControl mb={4}>
                                      <FormLabel
                                        fontWeight="normal"
                                        fontSize="16px"
                                      >
                                        {t("config.winLoseRefundTurn.label")}{" "}
                                        <Box
                                          as="span"
                                          color="green.500"
                                          fontWeight="normal"
                                        >
                                          {t("config.winLoseRefundTurn.notice")}
                                        </Box>
                                      </FormLabel>
                                      <NumberInput
                                        value={values.config.winLoseRefundTurn}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            "config.winLoseRefundTurn",
                                            valueString,
                                          )
                                        }
                                        min={0}
                                      >
                                        <NumberInputField
                                          placeholder={t(
                                            "config.winLoseRefundTurn.placeholder",
                                          )}
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>
                                    <FormControl>
                                      <FormLabel
                                        fontWeight="normal"
                                        fontSize="16px"
                                      >
                                        {t("config.winLoseRefundMax.label")}{" "}
                                        <Box
                                          as="span"
                                          color="green.500"
                                          fontWeight="normal"
                                        >
                                          {t("config.winLoseRefundMax.notice")}
                                        </Box>
                                      </FormLabel>
                                      <NumberInput
                                        value={values.config.winLoseRefundMax}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            "config.winLoseRefundMax",
                                            valueString,
                                          )
                                        }
                                        min={0}
                                      >
                                        <NumberInputField
                                          placeholder="0.00"
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>
                                  </>
                                )}
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box>
                                <FormControl mb={4} display="flex">
                                  <FormLabel>
                                    {t("config.limitWithDraw.label")}
                                  </FormLabel>
                                  <Switch
                                    isChecked={values.config.limitWithDraw}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "config.limitWithDraw",
                                        e.target.checked,
                                      );
                                      setShowColumn3Input(e.target.checked);
                                    }}
                                  />
                                </FormControl>
                                {showColumn3Input && (
                                  <FormControl mb={4}>
                                    <FormLabel>
                                      {t("config.countWithDraw.label")}{" "}
                                      <Box
                                        as="span"
                                        color="green.500"
                                        fontWeight="normal"
                                      >
                                        {t("config.countWithDraw.notice")}
                                      </Box>
                                    </FormLabel>
                                    <NumberInput
                                      value={values.config.countWithDraw}
                                      onChange={(valueString) =>
                                        setFieldValue(
                                          "config.countWithDraw",
                                          valueString,
                                        )
                                      }
                                      min={0}
                                    >
                                      <NumberInputField
                                        placeholder={t(
                                          "config.countWithDraw.placeholder",
                                        )}
                                        fontSize="sm"
                                      />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </FormControl>
                                )}
                              </Box>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Card>
                  </Animated>
                  <Animated
                    justify="flex-start"
                    pt="15px"
                    pb="35px"
                    duration="0.6s"
                    delay="0.1s"
                  >
                    <Button
                      width={{ base: "100%", xl: "auto" }}
                      minW={{ base: "none", xl: "150px" }}
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      borderRadius="33px"
                      type="submit"
                      fontSize="14px"
                      fontWeight="normal"
                      isLoading={isSaving}
                    >
                      {t("buttons.submit")}
                    </Button>
                  </Animated>
                </Stack>
              </Flex>
            </Form>
          )}
        </Formik>
      </Card>
    </Flex>
  );
};

export default ConditionSetting;

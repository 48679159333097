import { Flex, Grid } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import PageHeader from "components/Layout/PageHeader";
import { useActivityImage } from "hooks/business/website-setting/useActivityImage";
import { useTranslation } from "react-i18next";

import ActivityImageCard from "./components/ActivityImageCard";

const ActivityImageSetting = ({ pageName }) => {
  const { t } = useTranslation("activitySetting");
  const { activityImageData, handleImageUpload, handleToggle } =
    useActivityImage();

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <Animated animation="fadeInUp" duration="0.8s" delay="0.2s">
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(3, 1fr)" }}
            gap={6}
          >
            {activityImageData?.map((activity) => (
              <ActivityImageCard
                key={activity.id}
                title={activity.title}
                imageSrc={activity.backgroundImage}
                isActive={activity.active}
                onDelete={() => handleImageUpload(activity.id, "")}
                onToggle={() => handleToggle(activity.id)}
                onImageUpload={(newImageUrl) =>
                  handleImageUpload(activity.id, newImageUrl)
                }
                t={t}
              />
            ))}
          </Grid>
        </Animated>
      </Card>
    </Flex>
  );
};

export default ActivityImageSetting;

export const inputStyles = {
  components: {
    NumberInput: {
      variants: {
        outline: {
          field: {
            background: "rgba(30, 37, 44, 1)",
            height: "42px",
            fontSize: "12px",
            borderRadius: "33px",
            border: "1px solid",
            borderColor: "rgba(55, 63, 72, 1)",
            _hover: {
              borderColor: "gray.500",
            },
            _focus: {
              borderColor: "gray.500",
              boxShadow: "none",
            },
          },
        },
      },
    },
    Input: {
      baseStyle: {
        fontSize: "12px",
        borderColor: "rgba(55, 63, 72, 1)",
      },
      sizes: {
        xl: {
          field: {
            fontSize: "md",
            padding: "12px",
          },
        },
      },
      variants: {
        outline: {
          field: {
            background: "rgba(30, 37, 44, 1)",
            height: "42px",
            fontSize: "14px",
            borderRadius: "33px",
            border: "1px solid",
            borderColor: "rgba(55, 63, 72, 1)",
            _hover: {
              borderColor: "gray.500",
            },
            _focus: {
              borderColor: "gray.500",
              boxShadow: "none",
            },
          },
        },
      },
      defaultProps: {
        size: "md",
        variant: "outline",
      },
    },
  },
};

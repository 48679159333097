import { CopyIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export const memberLevelUpReportColumnsData = (t) => {
  return [
    {
      Header: t("headers.username"),
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);
        return (
          <Flex align="center">
            <IconButton
              icon={<CopyIcon color="green.500" />}
              _hover={{ boxShadow: "none", bg: "none" }}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: t("headers.previousLevel"),
      accessor: "tel",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.currentLevel"),
      accessor: "fullName",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.upgradeDate"),
      accessor: "createdDate",
    },
  ];
};

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField } from "utils/CommonUtils";
import { StatusBadge } from "variables/columns/transaction-report/withdrawTransactionReportColumnsData";
import { UPDATE_WITHDRAW_STATUS_OPTIONS } from "variables/options";

import { TransactionReceipt } from "./TransactionReceipt";

const UpdateWithdrawTransactionStatusModal = ({
  isOpen,
  onClose,
  transaction,
  handleSubmit,
  t,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              {t("updateWithdrawStatus.title")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              id: transaction?.id,
              amount: transaction?.amount,
              status: "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Text
                  fontSize="16px"
                  fontWeight="bold"
                  pt={2}
                  pb={4}
                  color="green.500"
                >
                  {t("updateWithdrawStatus.title")}
                </Text>
                <Stack mb={2}>
                  <FormLabel>
                    {t("updateWithdrawStatus.currentStatus")}
                  </FormLabel>
                  <StatusBadge status={transaction?.status} t={t} />
                </Stack>
                <TransactionReceipt transaction={transaction} />
                <Field name="status" validate={validateField}>
                  {({ field }) => (
                    <FormControl isRequired mt={4}>
                      <FormLabel>
                        {t("updateWithdrawStatus.statusLabel")}
                      </FormLabel>
                      <Select
                        {...field}
                        placeholder={t(
                          "updateWithdrawStatus.statusPlaceholder",
                        )}
                        fontSize="sm"
                        borderRadius="33px"
                      >
                        {UPDATE_WITHDRAW_STATUS_OPTIONS(t).map((status) => (
                          <option key={status.id} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    fontWeight="normal"
                    type="submit"
                    fontSize="14px"
                    isLoading={props.isSubmitting}
                  >
                    {t("updateWithdrawStatus.submitButton")}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdateWithdrawTransactionStatusModal;

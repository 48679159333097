import {
  Badge,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import {
  CopyIcon,
  DepositHandIcon,
  DepositSlipIcon,
  EditIcon,
  LockIcon,
  RefreshIcon,
  RemoveCreditIcon,
} from "components/Icons/Icons";
import { useHistory } from "react-router-dom";
import {
  formatAccountNumber,
  formatPhoneNumber,
  getMemberLevelColorScheme,
} from "utils/CommonUtils";
import { formatDate } from "utils/DateformatUtils";

import { banks } from "../../bank";
import { QueryKeys } from "../../queryKeys";

export const membersColumnsData = (handleModalOpen, queryClient, t) => {
  return [
    {
      Header: t("headers.memberLevel"),
      accessor: "memberLevelName",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row, cell }) => (
        <Flex align="center">
          <Badge
            bg={getMemberLevelColorScheme(
              row.original.memberLevelId?.toString(),
            )}
            w="65px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontWeight="normal" color="#fff">
              {cell.value ? cell.value : "-"}
            </Text>
          </Badge>
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: t("headers.username"),
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<CopyIcon color="green.500" />}
              _hover={{ boxShadow: "none", bg: "none" }}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: t("headers.phoneNumber"),
      accessor: "tel",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => formatPhoneNumber(cell.value),
    },
    {
      Header: t("headers.fullName"),
      accessor: "fullName",
      disableSortBy: true,
      isSorted: false,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: t("headers.bank"),
      accessor: "ธนาคาร",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankName]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankName]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            padding="5px"
          />
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: t("headers.accountNumber"),
      accessor: "accountNumber",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => formatAccountNumber(cell.value),
    },
    {
      Header: t("headers.credit"),
      accessor: "credit",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            color="#fff"
            fontWeight="bold"
          >
            <span>{value.toFixed(2)}</span>
            <Tooltip>
              <IconButton
                ml={1}
                size="xs"
                _hover={{ boxShadow: "none", bg: "none" }}
                icon={<RefreshIcon h="14px" w="14px" />}
                aria-label="Refresh credit"
                onClick={() => queryClient.invalidateQueries(QueryKeys.MEMBERS)}
                isRound
                variant="solid"
                bg="transparent"
                borderColor={"green.500"}
                color={"green.500"}
              />
            </Tooltip>
          </Flex>
        );
      },
      disableSortBy: true,
    },
    {
      Header: t("headers.deposit"),
      minWidth: "65px",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          aria-label="deposit slip"
          borderRadius="28px"
          w="40px"
          h="30px"
          size="sm"
          borderColor="rgba(55, 63, 72, 1)"
          bg="rgba(29, 34, 41, 1)"
          icon={<DepositSlipIcon h="16px" w="16px" color="green.500" />}
          onClick={() => handleModalOpen("addCreditSlipModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: t("headers.addCredit"),
      minWidth: "65px",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          aria-label="deposit hand"
          borderRadius="28px"
          w="40px"
          h="30px"
          size="sm"
          borderColor="rgba(55, 63, 72, 1)"
          bg="rgba(29, 34, 41, 1)"
          icon={<DepositHandIcon h="18px" w="18px" color="green.500" />}
          onClick={() => handleModalOpen("addCreditModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: t("headers.removeCredit"),
      minWidth: "65px",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          aria-label="remove credit"
          borderRadius="28px"
          w="40px"
          h="30px"
          size="sm"
          borderColor="rgba(55, 63, 72, 1)"
          bg="rgba(29, 34, 41, 1)"
          icon={<RemoveCreditIcon h="18px" w="18px" color="green.500" />}
          onClick={() => handleModalOpen("removeCreditModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: t("headers.promotionStatus"),
      accessor: "isBonus",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            bg={cell.value === true ? "#31AA93" : "#373F48"}
            color="#fff"
            w="90px"
            h="26px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="400"
          >
            {cell.value === true
              ? t("promoStatus.accepted")
              : t("promoStatus.notAccepted")}
          </Badge>
        </Flex>
      ),
    },
    {
      Header: t("headers.affiliate"),
      accessor: "affiliate",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => (
        <Text fontWeight="normal">{cell.value ? cell.value : "-"}</Text>
      ),
    },
    {
      Header: t("headers.registrationDate"),
      accessor: "createdDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: t("headers.ip"),
      accessor: "ip",
      textAlign: "left",
      justifyContent: "flex-start",
      disableSortBy: true,
      Cell: ({ cell }) => <Text fontWeight="normal">{cell.value || "-"}</Text>,
    },
    {
      Header: t("headers.tools"),
      Cell: ({ row }) => (
        <>
          <IconButton
            variant="outline"
            aria-label="remove credit"
            size="sm"
            borderRadius="28px"
            w="40px"
            h="30px"
            borderColor="rgba(55, 63, 72, 1)"
            bg="rgba(29, 34, 41, 1)"
            icon={<LockIcon h="16px" w="16px" color="green.500" />}
            onClick={() => handleModalOpen("resetPasswordModal", row.original)}
            mr="6px"
          />
          <IconButton
            variant="solid"
            aria-label="edit"
            size="sm"
            borderRadius="28px"
            w="40px"
            h="30px"
            bg="green.500"
            icon={<EditIcon h="18px" w="18px" color="#fff" />}
            onClick={() => handleModalOpen("manageMemberModal", row.original)}
          />
        </>
      ),
      disableSortBy: true,
    },
  ];
};

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import adminProfileEn from "./en/adminProfile.json";
import loginEn from "./en/auth/login.json";
import dashboardEn from "./en/dashboard/dashboard.json";
import bankSettingEn from "./en/finance-setting/bankSetting.json";
import paymentGatewaySettingEn from "./en/finance-setting/paymentGatewaySetting.json";
import trueWalletSettingEn from "./en/finance-setting/truewalletSetting.json";
import memberLevelEn from "./en/member-management/memberLevel.json";
import memberTagEn from "./en/member-management/memberTag.json";
import membersEn from "./en/member-management/members.json";
import affiliateReportEn from "./en/member-report/affiliateReport.json";
import commissionReportEn from "./en/member-report/commissionReport.json";
import memberDepositReportEn from "./en/member-report/memberDeposit.json";
import memberFirstDepositReportEn from "./en/member-report/memberFirstDeposit.json";
import memberLevelUpReportEn from "./en/member-report/memberLevelUp.json";
import memberNotDepositReportEn from "./en/member-report/memberNotDeposit.json";
import memberReceivePromotionReportEn from "./en/member-report/memberReceivePromotion.json";
import memberTodayReportEn from "./en/member-report/memberToday.json";
import memberTransactionReportEn from "./en/member-report/memberTransaction.json";
import memberTurnoverReportEn from "./en/member-report/memberTurnover.json";
import promotionReportEn from "./en/member-report/promotionReport.json";
import sharedEn from "./en/shared.json";
import affiliateSettingEn from "./en/system-setting/affiliateSetting.json";
import commissionSettingEn from "./en/system-setting/commissionSetting.json";
import conditionSettingEn from "./en/system-setting/conditionSetting.json";
import gameSettingEn from "./en/system-setting/gameSetting.json";
import jackpotSettingEn from "./en/system-setting/jackpotSetting.json";
import marketingSettingEn from "./en/system-setting/marketingSetting.json";
import promotionSettingEn from "./en/system-setting/promotionSetting.json";
import recommendGameSettingEn from "./en/system-setting/recommendGameSetting.json";
import roleSettingEn from "./en/system-setting/roleSetting.json";
import spinnerSettingEn from "./en/system-setting/spinnerSetting.json";
import staffSettingEn from "./en/system-setting/staffSetting.json";
import thiefEn from "./en/theif/thief.json";
import depositReportEn from "./en/transaction-report/depositReport.json";
import invalidReportEn from "./en/transaction-report/invalidReport.json";
import withdrawReportEn from "./en/transaction-report/withdrawReport.json";
import activitySettingEn from "./en/web-setting/activitySetting.json";
import bannerSettingEn from "./en/web-setting/bannerSetting.json";
import footerSettingEn from "./en/web-setting/footerSetting.json";
import liveChatSettingEn from "./en/web-setting/liveChatSetting.json";
import popupSettingEn from "./en/web-setting/popupSetting.json";
import seoSettingEn from "./en/web-setting/seoSetting.json";
import jackpotReportEn from "./en/website-report/jackpotReport.json";
import marketingReportEn from "./en/website-report/marketingReport.json";
import memberLoginReportEn from "./en/website-report/memberLoginReport.json";
import profitAndLossReportEn from "./en/website-report/profitAndLossReport.json";
import spinnerReportEn from "./en/website-report/spinnerReport.json";
import staffCreditReportEn from "./en/website-report/staffCreditReport.json";
import staffLoginReportEn from "./en/website-report/staffLoginReport.json";
import adminProfileTh from "./th/adminProfile.json";
import loginTh from "./th/auth/login.json";
import dashboardTh from "./th/dashboard/dashboard.json";
import bankSettingTh from "./th/finance-setting/bankSetting.json";
import paymentGatewaySettingTh from "./th/finance-setting/paymentGatewaySetting.json";
import trueWalletSettingTh from "./th/finance-setting/truewalletSetting.json";
import memberLevelTh from "./th/member-management/memberLevel.json";
import memberTagTh from "./th/member-management/memberTag.json";
import membersTh from "./th/member-management/members.json";
import affiliateReportTh from "./th/member-report/affiliateReport.json";
import commissionReportTh from "./th/member-report/commissionReport.json";
import memberDepositReportTh from "./th/member-report/memberDeposit.json";
import memberFirstDepositReportTh from "./th/member-report/memberFirstDeposit.json";
import memberLevelUpReportTh from "./th/member-report/memberLevelUp.json";
import memberNotDepositReportTh from "./th/member-report/memberNotDeposit.json";
import memberReceivePromotionReportTh from "./th/member-report/memberReceivePromotion.json";
import memberTodayReportTh from "./th/member-report/memberToday.json";
import memberTransactionReportTh from "./th/member-report/memberTransaction.json";
import memberTurnoverReportTh from "./th/member-report/memberTurnover.json";
import promotionReportTh from "./th/member-report/promotionReport.json";
import sharedTh from "./th/shared.json";
import affiliateSettingTh from "./th/system-setting/affiliateSetting.json";
import commissionSettingTh from "./th/system-setting/commissionSetting.json";
import conditionSettingTh from "./th/system-setting/conditionSetting.json";
import gameSettingTh from "./th/system-setting/gameSetting.json";
import jackpotSettingTh from "./th/system-setting/jackpotSetting.json";
import marketingSettingTh from "./th/system-setting/marketingSetting.json";
import promotionSettingTh from "./th/system-setting/promotionSetting.json";
import recommendGameSettingTh from "./th/system-setting/recommendGameSetting.json";
import roleSettingTh from "./th/system-setting/roleSetting.json";
import spinnerSettingTh from "./th/system-setting/spinnerSetting.json";
import staffSettingTh from "./th/system-setting/staffSetting.json";
import thiefTh from "./th/theif/thief.json";
import depositReportTh from "./th/transaction-report/depositReport.json";
import invalidReportTh from "./th/transaction-report/invalidReport.json";
import withdrawReportTh from "./th/transaction-report/withdrawReport.json";
import activitySettingTh from "./th/web-setting/activitySetting.json";
import bannerSettingTh from "./th/web-setting/bannerSetting.json";
import footerSettingTh from "./th/web-setting/footerSetting.json";
import liveChatSettingTh from "./th/web-setting/liveChatSetting.json";
import popupSettingTh from "./th/web-setting/popupSetting.json";
import seoSettingTh from "./th/web-setting/seoSetting.json";
import jackpotReportTh from "./th/website-report/jackpotReport.json";
import marketingReportTh from "./th/website-report/marketingReport.json";
import memberLoginReportTh from "./th/website-report/memberLoginReport.json";
import profitAndLossReportTh from "./th/website-report/profitAndLossReport.json";
import spinnerReportTh from "./th/website-report/spinnerReport.json";
import staffCreditReportTh from "./th/website-report/staffCreditReport.json";
import staffLoginReportTh from "./th/website-report/staffLoginReport.json";

const resources = {
  en: {
    dashboard: dashboardEn,
    members: membersEn,
    thief: thiefEn,
    memberLevel: memberLevelEn,
    memberTag: memberTagEn,
    memberDepositReport: memberDepositReportEn,
    memberNotDepositReport: memberNotDepositReportEn,
    memberFirstDepositReport: memberFirstDepositReportEn,
    memberTodayReport: memberTodayReportEn,
    affiliateReport: affiliateReportEn,
    commissionReport: commissionReportEn,
    memberLevelUpReport: memberLevelUpReportEn,
    memberReceivePromotionReport: memberReceivePromotionReportEn,
    memberTransactionReport: memberTransactionReportEn,
    memberTurnoverReport: memberTurnoverReportEn,
    promotionReport: promotionReportEn,
    depositReport: depositReportEn,
    invalidReport: invalidReportEn,
    withdrawReport: withdrawReportEn,
    jackpotReport: jackpotReportEn,
    marketingReport: marketingReportEn,
    memberLoginReport: memberLoginReportEn,
    profitAndLossReport: profitAndLossReportEn,
    spinnerReport: spinnerReportEn,
    staffLoginReport: staffLoginReportEn,
    staffCreditReport: staffCreditReportEn,
    activitySetting: activitySettingEn,
    seoSetting: seoSettingEn,
    footerSetting: footerSettingEn,
    liveChatSetting: liveChatSettingEn,
    popupSetting: popupSettingEn,
    bannerSetting: bannerSettingEn,
    bankSetting: bankSettingEn,
    trueWalletSetting: trueWalletSettingEn,
    paymentGatewaySetting: paymentGatewaySettingEn,
    conditionSetting: conditionSettingEn,
    promotionSetting: promotionSettingEn,
    commissionSetting: commissionSettingEn,
    affiliateSetting: affiliateSettingEn,
    marketingSetting: marketingSettingEn,
    staffSetting: staffSettingEn,
    gameSetting: gameSettingEn,
    recommendGameSetting: recommendGameSettingEn,
    spinnerSetting: spinnerSettingEn,
    jackpotSetting: jackpotSettingEn,
    roleSetting: roleSettingEn,
    adminProfile: adminProfileEn,
    login: loginEn,
    shared: sharedEn,
  },
  th: {
    dashboard: dashboardTh,
    thief: thiefTh,
    members: membersTh,
    memberLevel: memberLevelTh,
    memberTag: memberTagTh,
    memberDepositReport: memberDepositReportTh,
    memberNotDepositReport: memberNotDepositReportTh,
    memberFirstDepositReport: memberFirstDepositReportTh,
    memberTodayReport: memberTodayReportTh,
    affiliateReport: affiliateReportTh,
    commissionReport: commissionReportTh,
    memberLevelUpReport: memberLevelUpReportTh,
    memberReceivePromotionReport: memberReceivePromotionReportTh,
    memberTransactionReport: memberTransactionReportTh,
    memberTurnoverReport: memberTurnoverReportTh,
    promotionReport: promotionReportTh,
    depositReport: depositReportTh,
    invalidReport: invalidReportTh,
    withdrawReport: withdrawReportTh,
    jackpotReport: jackpotReportTh,
    marketingReport: marketingReportTh,
    memberLoginReport: memberLoginReportTh,
    profitAndLossReport: profitAndLossReportTh,
    spinnerReport: spinnerReportTh,
    staffLoginReport: staffLoginReportTh,
    staffCreditReport: staffCreditReportTh,
    activitySetting: activitySettingTh,
    seoSetting: seoSettingTh,
    footerSetting: footerSettingTh,
    liveChatSetting: liveChatSettingTh,
    popupSetting: popupSettingTh,
    bannerSetting: bannerSettingTh,
    bankSetting: bankSettingTh,
    trueWalletSetting: trueWalletSettingTh,
    paymentGatewaySetting: paymentGatewaySettingTh,
    conditionSetting: conditionSettingTh,
    promotionSetting: promotionSettingTh,
    commissionSetting: commissionSettingTh,
    affiliateSetting: affiliateSettingTh,
    marketingSetting: marketingSettingTh,
    staffSetting: staffSettingTh,
    gameSetting: gameSettingTh,
    recommendGameSetting: recommendGameSettingTh,
    spinnerSetting: spinnerSettingTh,
    jackpotSetting: jackpotSettingTh,
    roleSetting: roleSettingTh,
    adminProfile: adminProfileTh,
    login: loginTh,
    shared: sharedTh,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "th",
    ns: [
      "dashboard",
      "members",
      "thief",
      "memberLevel",
      "memberTag",
      "memberDepositReport",
      "memberNotDepositReport",
      "memberFirstDepositReport",
      "memberTodayReport",
      "affiliateReport",
      "commissionReport",
      "memberLevelUpReport",
      "memberReceivePromotionReport",
      "memberTransactionReport",
      "memberTurnoverReport",
      "promotionReport",
      "depositReport",
      "invalidReport",
      "withdrawReport",
      "jackpotReport",
      "marketingReport",
      "memberLoginReport",
      "profitAndLossReport",
      "spinnerReport",
      "staffLoginReport",
      "activitySetting",
      "seoSetting",
      "footerSetting",
      "liveChatSetting",
      "popupSetting",
      "bannerSetting",
      "bankSetting",
      "trueWalletSetting",
      "paymentGatewaySetting",
      "conditionSetting",
      "promotionSetting",
      "commissionSetting",
      "affiliateSetting",
      "marketingSetting",
      "staffSetting",
      "gameSetting",
      "recommendGameSetting",
      "spinnerSetting",
      "jackpotSetting",
      "roleSetting",
      "adminProfile",
      "login",
      "shared",
    ],
    defaultNS: "shared",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

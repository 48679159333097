import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
  Switch,
  Text,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import { Field, Form, Formik } from "formik";
import { useLiveChatSetting } from "hooks/business/website-setting/useLiveChatSetting";
import React from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

const LiveChatSetting = ({ pageName }) => {
  const { t } = useTranslation("liveChatSetting");
  const gridWidth = useBreakpointValue({ base: "100%", xl: "50%" });
  const { liveChatSettingData, handleSave, isSaving } = useLiveChatSetting();
  const handleSubmit = (payload) => handleSave(payload);

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Stack direction="column" w="100%">
        <Formik
          initialValues={{
            chatscript: liveChatSettingData?.chatscript || "",
            status: liveChatSettingData?.status || false,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Card
                  w={{ sm: "100%" }}
                  alignSelf="flex-end"
                  justifySelf="flex-end"
                >
                  <CardHeader>
                    <Text fontSize="18px" fontWeight="bold" color="#fff">
                      {t("liveChatSettings")}
                    </Text>
                  </CardHeader>
                  <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                    <Element>
                      <CardBody>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            xl: "repeat(1, 1fr)",
                          }}
                          gap="24px"
                          w={gridWidth}
                        >
                          <GridItem>
                            <FormControl>
                              <FormLabel>{t("form.script")}</FormLabel>
                              <Field
                                as={Textarea}
                                name="chatscript"
                                minHeight="315px"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <Flex>
                              <Switch
                                isChecked={Boolean(values.status)}
                                onChange={(e) => {
                                  setFieldValue("status", e.target.checked);
                                }}
                                mr={4}
                              />
                              <FormLabel>{t("form.status")}</FormLabel>
                            </Flex>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Element>
                  </Animated>
                  <Animated justify="flex-start" pt="15px" pb={6}>
                    <Button
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      width={{ base: "100%", xl: "auto" }}
                      minW={{ base: "none", xl: "195px" }}
                      borderRadius="33px"
                      type="submit"
                      fontWeight="normal"
                      fontSize="14px"
                      isLoading={isSaving}
                    >
                      {t("form.save")}
                    </Button>
                  </Animated>
                </Card>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default LiveChatSetting;

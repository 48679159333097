import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import SearchTable from "components/Tables/SearchTable";
import { useStaffSetting } from "hooks/business/system-setting/useStaffSetting";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { staffSettingColumnsData } from "variables/columns/system-setting/staffSettingColumnsData";

import ManageStaffModal from "./components/ManageStaffModal";

const StaffSetting = ({ pageName }) => {
  const { t } = useTranslation("staffSetting");
  const { staffData, isLoading, staffRolesData, handleSaveStaff } =
    useStaffSetting();

  const [modalState, setModalState] = useState({
    manageStaffModalOpen: false,
  });

  const [selectedStaff, setSelectedStaff] = useState(null);

  const handleModalOpen = (modal, staff = null) => {
    setSelectedStaff(staff);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedStaff(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <CardHeader>
          <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
            {t("staffSettings")}
          </Text>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <CardBody>
            <SearchTable
              isLoading={isLoading}
              tableData={staffData}
              columnsData={staffSettingColumnsData(handleModalOpen, t)}
              showAction={true}
              handleModalOpen={handleModalOpen}
              actionButtonText={t("buttons.addMarketing")}
              modalName={"manageStaffModal"}
              actionButtonIcon={null}
              t={t}
            />
          </CardBody>
        </Animated>
      </Card>
      <ManageStaffModal
        isOpen={modalState.manageStaffModalOpen}
        onClose={() => handleModalClose("manageStaffModal")}
        staff={selectedStaff}
        staffRolesData={staffRolesData}
        handleSubmit={handleSaveStaff}
        t={t}
      />
    </Flex>
  );
};

export default StaffSetting;

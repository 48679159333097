import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { getJackpotBackground, validateField } from "utils/CommonUtils";

const ManageJackpotModal = ({
  isOpen,
  onClose,
  jackpot,
  handleSubmit,
  handleVerify,
  isUserVerified,
  verifySuccessMessage,
  verifyUnSuccessMessage,
  t,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">
            <Flex alignItems="center" justifyContent="left">
              <Text fontSize="20px" color="#fff">
                {t("giveJackpotModal.header")}
              </Text>
            </Flex>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize="16px"
            fontWeight="bold"
            pt={4}
            pb={6}
            color="green.500"
          >
            {t("giveJackpotModal.header")}
          </Text>
          <Formik
            initialValues={{
              type: jackpot?.type,
              username: "",
              turnover: 0,
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <FormLabel>{t("giveJackpotModal.form.type")}</FormLabel>
                <Badge
                  bg={getJackpotBackground(jackpot?.type)}
                  w="77px"
                  h="28px"
                  borderRadius="15px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="normal"
                >
                  {jackpot?.type.toUpperCase()}
                </Badge>
                <Field name="username" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.username && form.touched.username}
                      isRequired
                      mt={8}
                    >
                      <FormLabel>
                        {t("giveJackpotModal.form.username.label")}
                      </FormLabel>
                      <Flex>
                        <Input
                          {...field}
                          placeholder={t(
                            "giveJackpotModal.form.username.placeholder",
                          )}
                          fontSize="sm"
                          borderTopRightRadius="0px"
                          borderBottomRightRadius="0px"
                        />
                        <Button
                          ml="-5"
                          fontSize="14px"
                          background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                          variant="solid"
                          w="113px"
                          h="42px"
                          fontWeight="normal"
                          onClick={() => {
                            handleVerify(field.value);
                          }}
                        >
                          {t("giveJackpotModal.form.username.verifyButton")}
                        </Button>
                      </Flex>
                      {isUserVerified && (
                        <Text fontSize="xs" color="green.500" mt="2">
                          {t(verifySuccessMessage)}
                        </Text>
                      )}
                      {!isUserVerified && verifyUnSuccessMessage && (
                        <Text fontSize="xs" color="red.500" mt="2">
                          {t(verifyUnSuccessMessage)}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="turnover">
                  {({ field }) => (
                    <FormControl mt={4}>
                      <FormLabel>
                        {t("giveJackpotModal.form.turnover.label")}
                      </FormLabel>
                      <NumberInput
                        value={props.values.turnOver}
                        onChange={(valueString) =>
                          props.setFieldValue("turnOver", valueString)
                        }
                        placeholder={t(
                          "giveJackpotModal.form.turnover.placeholder",
                        )}
                      >
                        <NumberInputField {...field} fontSize="sm" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    isLoading={props.isSubmitting}
                    type="submit"
                    fontWeight="normal"
                    disabled={!isUserVerified}
                    fontSize="14px"
                  >
                    {t("giveJackpotModal.form.submitButton")}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageJackpotModal;

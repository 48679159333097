import { useQuery } from "react-query";
import { fetchDailySummaryReportService } from "services/website-report/dailySummaryReportService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useDailySummaryReport = (search) => {
  const handleError = useErrorHandler();
  // const [downloadableData, setDownloadableData] = useState([]);

  // const queryKey = [
  //   QueryKeys.MARKETING_REPORT,
  //   search.createdDateFrom,
  //   search.createdDateTo,
  // ];

  const { data, isLoading } = useQuery(
    [QueryKeys.DAILY_SUMMARY_REPORT, search],
    async () => {
      const response = await fetchDailySummaryReportService(search);
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
      onSuccess: (data) => {
        // const formattedRecords = mapJsonToTableData(data, columnsData);
        // setDownloadableData(formattedRecords);
        console.log(data);
      },
    },
  );

  return {
    data,
    isLoading,
    // downloadableData,
  };
};

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ImageUploader from "components/ImageUploader/ImageUploader";
import PageHeader from "components/Layout/PageHeader";
import { Field, Form, Formik } from "formik";
import { useSeoSetting } from "hooks/business/website-setting/useSeoSetting";
import React from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

import CardHeader from "../../../components/Card/CardHeader";

const SeoSetting = ({ pageName }) => {
  const {
    seoDetail,
    handleSubmit,
    metaKeyword,
    setMetaKeyword,
    keyPress,
    isSubmitting,
  } = useSeoSetting();
  const { t } = useTranslation("seoSetting");
  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Formik
        initialValues={{
          ...seoDetail,
          pageTitle: seoDetail?.pageTitle || "",
          pageUrl: seoDetail?.pageUrl || "",
          metaTitle: seoDetail?.metaTitle || "",
          googleAnalytic: seoDetail?.googleAnalytic || "",
          metaDescription: seoDetail?.metaDescription || "",
          metaKeyword: seoDetail?.metaKeyword || [],
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Flex direction="column">
              <Card
                w={{ sm: "100%" }}
                alignSelf="flex-end"
                justifySelf="flex-end"
              >
                <CardHeader>
                  <Text fontSize="18px" fontWeight="bold" color="#fff">
                    {t(pageName, { ns: "shared" })}
                  </Text>
                </CardHeader>
                <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                  <Element id="info" name="info">
                    <CardBody>
                      <Grid
                        templateColumns={{
                          base: "1fr",
                          xl: "repeat(2, 1fr)",
                        }}
                        gap="24px"
                        w="100%"
                      >
                        <GridItem>
                          <FormControl>
                            <FormLabel>{t("fields.pageTitle")}</FormLabel>
                            <Field
                              as={Input}
                              name="pageTitle"
                              placeholder={t("placeholders.pageTitle")}
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{t("fields.pageUrl")}</FormLabel>
                            <Field
                              as={Input}
                              name="pageUrl"
                              placeholder={t("placeholders.pageUrl")}
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{t("fields.metaTitle")}</FormLabel>
                            <Field
                              as={Input}
                              name="metaTitle"
                              placeholder={t("placeholders.metaTitle")}
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{t("fields.googleAnalytics")}</FormLabel>
                            <Field
                              as={Input}
                              name="googleAnalytic"
                              placeholder={t("placeholders.googleAnalytics")}
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{t("fields.metaDescription")}</FormLabel>
                            <Field
                              name="metaDescription"
                              as={Textarea}
                              placeholder={t("placeholders.metaDescription")}
                              fontSize="sm"
                              minHeight="150px"
                              bg="#1E252C"
                              borderRadius="10px"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{t("fields.metaKeywords")}</FormLabel>
                            <Flex
                              direction="row"
                              p="12px"
                              wrap="wrap"
                              borderRadius="10px"
                              border="1px solid rgba(55, 63, 72, 1)"
                              _focus={{ borderColor: "teal.300" }}
                              minH="150px"
                              cursor="text"
                              bg="#1E252C"
                            >
                              {metaKeyword.map((keyword) => {
                                return (
                                  <Tag
                                    fontSize="sm"
                                    h="25px"
                                    mb="6px"
                                    me="6px"
                                    key={keyword.id}
                                    borderRadius="12px"
                                    // variant="outline"
                                    border="1px"
                                    backgroundColor={"#1D2229"}
                                    borderColor={"#373F48"}
                                    color={"#8C9592"}
                                  >
                                    <TagLabel w="100%">{keyword.name}</TagLabel>
                                    <TagCloseButton
                                      color="green.500"
                                      justifySelf="flex-end"
                                      onClick={() => {
                                        const newKeywords = metaKeyword.filter(
                                          (element) =>
                                            element.id !== keyword.id,
                                        );
                                        setMetaKeyword(newKeywords);
                                        setFieldValue(
                                          "metaKeyword",
                                          newKeywords,
                                        );
                                      }}
                                    />
                                  </Tag>
                                );
                              })}
                              <Input
                                border="none"
                                _focus={{
                                  boxShadow: "none",
                                  outline: "none",
                                }}
                                p="0px"
                                onKeyDown={(e) => keyPress(e, setFieldValue)}
                                fontSize="sm"
                                borderRadius="none"
                              />
                            </Flex>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>
                              {t("fields.favicon")}{" "}
                              <Box
                                as="span"
                                color="green.500"
                                fontWeight="normal"
                              >
                                {t("fields.faviconSize")}
                              </Box>
                            </FormLabel>
                            <ImageUploader
                              initialImage={values.favicon}
                              onUpload={(uri) => setFieldValue("favicon", uri)}
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </CardBody>
                  </Element>
                </Animated>
                <Flex
                  justify="flex-start"
                  pt="15px"
                  duration={0.4}
                  delay={0.8}
                  pb={6}
                >
                  <Button
                    type="submit"
                    borderRadius="33px"
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    width={{ base: "100%", xl: "auto" }}
                    minW={{ base: "none", xl: "235px" }}
                    fontWeight="normal"
                    fontSize="14px"
                    isLoading={isSubmitting}
                    mt={4}
                  >
                    {t("buttons.save")}
                  </Button>
                </Flex>
              </Card>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default SeoSetting;

import { Flex, Grid } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import PageHeader from "components/Layout/PageHeader";
import { useGameSetting } from "hooks/business/system-setting/useGameSetting";
import React from "react";
import { useTranslation } from "react-i18next";

import ProviderCard from "./components/ProviderCard";

const GameSetting = ({ pageName }) => {
  const {
    slotProviderData,
    casinoProviderData,
    cardProviderData,
    sportProviderData,
    isLoading,
    handleToggle,
  } = useGameSetting();
  const { t } = useTranslation("gameSetting");
  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <Grid templateColumns={{ base: "1fr)", xl: "repeat(2, 1fr)" }} gap={6}>
          <ProviderCard
            title="providerCard.titles.slot"
            providerData={slotProviderData}
            isLoading={isLoading}
            handleToggle={handleToggle}
            t={t}
          />
          <ProviderCard
            title="providerCard.titles.casino"
            providerData={casinoProviderData}
            isLoading={isLoading}
            handleToggle={handleToggle}
            t={t}
          />
          <ProviderCard
            title="providerCard.titles.skill"
            providerData={cardProviderData}
            isLoading={isLoading}
            handleToggle={handleToggle}
            pt={6}
            t={t}
          />
          <ProviderCard
            title="providerCard.titles.sport"
            providerData={sportProviderData}
            isLoading={isLoading}
            handleToggle={handleToggle}
            pt={6}
            t={t}
          />
        </Grid>
      </Card>
    </Flex>
  );
};

export default GameSetting;

export const MembersSearchTypes = {
  ALL: "0",
  DEPOSIT: "1",
  NOT_DEPOSIT: "2",
  FIRST_DEPOSIT: "3",
};

// need to add more
export const DepositTransactionTypes = {
  ALL: "ALL",
  NOT_SURE: "NOT_SURE",
  BLOCK_AUTO: "BLOCK_AUTO",
  REFUND_WIN_LOSE: "REFUNDWINLOSE",
};

export const JackpotTypes = {
  GRAND_JACKPOT: "Grand",
  MAJOR_JACKPOT: "Major",
  MINOR_JACKPOT: "Minor",
  MINI_JACKPOT: "Mini",
};

export const GameTypes = {
  ALL: "ALL",
  SLOT: "SLOT",
  LIVE: "LIVE",
  SPORT: "SPORT",
  CARD: "CARD",
};

export const BonusTypes = {
  FIX: "FIX",
  PERCENT: "PERCENT",
};

export const PromotionTypes = {
  ALL_DAY: "ALLDAY",
  NEW_USER: "NEWUSER",
  FIRST_TIME: "FIRSTTIME",
  GOLDEN_TIME: "GOLDTIME",
  SEVEN_DAY_IN_A_ROW: "7DAYINROW",
};

export const BankServiceTypes = {
  MANUAL: "MANUAL",
  API: "API",
  SCB_CONNECT: "SCBCONNECT",
};

export const BankTypes = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
};

export const DepositTypes = {
  BANK: "BANK",
  TRUE_WALLET: "TRUEWALLET",
  PAYMENT_GATEWAY: "PAYMENTGATEWAY",
};

export const PaymentGatewayServiceTypes = {
  VIZPAY: "vizpay",
  WILDPAY: "wildpay",
};

export const MistakeCreditTypes = {
  CUT_CREDIT: "CUT_CREDIT",
  NO_SLIP: "NO_SLIP",
  ADD_CREDIT: "ADD_CREDIT",
};

export const ProviderTypes = {
  UFABET: "UFABET",
  BETFLIX: "BETFLIX",
  AMBKING: "AMBKING",
};

import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { usePromotionReport } from "hooks/business/member-report/usePromotionReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { useTranslation } from "react-i18next";
import { QueryClientProvider, useQueryClient } from "react-query";
import { promotionReportColumnsData } from "variables/columns/member-report/promotionReportColumnsData";
import { APP_PROFILE } from "variables/system";

const PromotionReport = ({ pageName }) => {
  const { t } = useTranslation("promotionReport");
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm:ss";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { fetchData } = usePromotionReport(role);
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "14px", xl: "0px" }}
            >
              {t("promotionReport")}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={"promotionReport"}
                columnsData={promotionReportColumnsData(t)}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                isSearchable={false}
                isSum={true}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default PromotionReport;

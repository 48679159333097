export const QueryKeys = {
  DASHBOARD_SUMMARY: "dashboardSummary",
  PROFIT_GRAPH: "profitGraph",
  TOP_TRANSACTION: {
    DEPOSIT: (startDate, endDate) => [
      "topTransaction",
      "deposit",
      startDate,
      endDate,
    ],
    WITHDRAW: (startDate, endDate) => [
      "topTransaction",
      "withdraw",
      startDate,
      endDate,
    ],
  },
  MEMBERS: "members",
  AFFILIATE_REPORT: "affiliateReport",
  AFFILIATE_USER_LIST_REPORT: "affiliateUserListReport",
  COMMISSION_REPORT: "commissionReport",
  MEMBER_DEPOSIT_REPORT: "memberDepositReport",
  MEMBER_FIRST_DEPOSIT_REPORT: "memberFirstDepositReport",
  MEMBER_NOT_DEPOSIT_REPORT: "memberNotDepositReport",
  PROMOTION_REPORT: "promotionReport",
  MEMBER_RECEIVE_PROMOTION_REPORT: "receivePromotionReport",
  STAFF_CREDIT_HISTORY: "staffCreditHistoryReport",
  NEW_MEMBER_TODAY_REPORT: "newMemberTodayReport",
  MEMBER_TRANSACTION_REPORT: "memberTransactionReport",
  MEMBER_LOGIN_REPORT: "memberLoginReport.json",
  MEMBER_TURNOVER_REPORT: "memberTurnoverReport",
  MEMBER_LEVEL_UP_REPORT: "memberLevelUpReport",
  PROFIT_AND_LOSS_REPORT: "profitAndLossReport",
  DAILY_SUMMARY_REPORT: "dailySummaryReport",
  SPINNER_REPORT: "spinnerReport",
  JACKPOT_REPORT: "jackpotReport",
  MARKETING_REPORT: "marketingReport",
  STAFF_LOGIN_REPORT: "staffLoginReport",
  DEPOSIT_TRANSACTION_REPORT: "depositTransactionReport",
  PAYMENT_SUMMARY_REPORT: "paymentSummaryReport",
  WITHDRAW_TRANSACTION_REPORT: "withdrawTransactionReport",
  INVALID_TRANSACTION_REPORT: "invalidTransactionReport",
  LAST_20_DEPOSIT: "last20Deposit",
  LAST_20_WITHDRAW: "last20Withdraw",
  MEMBER_LEVEL: "memberLevel",
  MEMBER_TAG: "memberTag",
  BANK_SETTING: "bankSetting",
  BANK_LIST: "bankList",
  TRUE_WALLET_SETTING: "trueWalletSetting",
  PAYMENT_GATEWAY_SETTING: "paymentGatewaySetting",
  COMMISSION_SETTING: "commissionSetting",
  AFFILIATE_SETTING: "affiliateSetting",
  CONDITION_SETTING: "conditionSetting",
  SPINNER_SETTING: "spinnerSetting",
  STAFF_SETTING: "staffSetting",
  STAFF_ROLES: "staffRoles",
  MARKETING_SETTING: "marketingSetting",
  MARKETING_LINK: "marketingLink",
  GAME_SETTING: "gameSetting",
  PROMOTION_SETTING: "promotionSetting",
  JACKPOT_SETTING: "jackpotSetting",
  BANNER_SETTING: "bannerSetting",
  CANONICAL_SETTING: "canonicalSetting",
  FOOTER_SETTING: "footerSetting",
  FOOTER_ICONS: "footerIcons",
  LIVECHAT_SETTING: "liveChatSetting",
  POPUP_SETTING: "popupSetting",
  WEB_SETTING: "webSetting",
  ACTIVITY_SETTING: "activitySetting.json",
  SEO_SETTING: "seoSetting",
  USER_PROFILE: "userProfile",
  ADMIN_PROFILE: "adminProfile",
};

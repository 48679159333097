import { formatDate } from "../../../utils/DateformatUtils";

export const affiliateListUserReportColumnsData = () => {
  return [
    {
      Header: "ยูสเซอร์",
      accessor: "username",
    },
    {
      Header: "ยอดฝากรวม",
      accessor: "totalDeposit",
    },
    {
      Header: "วันที่ลงทะเบียน",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};

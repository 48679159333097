import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMemberTag } from "hooks/business/member-management/useMemberTag";
import { useMarketingSetting } from "hooks/business/system-setting/useMarketingSetting";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import React from "react";
import { useQuery } from "react-query";
import { fetchBanks } from "services/member-management/memberService";
import { validateField } from "utils/CommonUtils";
import { ERROR_MESSAGE } from "variables/system";

const ManageMemberModal = ({
  isOpen,
  onClose,
  member = null,
  handleSubmit,
  t,
}) => {
  const modalSize = useBreakpointValue({ base: "xl", md: "2xl" });
  const handleError = useErrorHandler();

  const { data: banks } = useQuery(
    "banks",
    async () => {
      const response = await fetchBanks("bankUser");
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const { data: memberTags } = useMemberTag();
  const { marketingStaffData } = useMarketingSetting();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent maxH="95vh">
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              {t("manageMemberModal.title")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Text
            fontSize="16px"
            fontWeight="bold"
            pt={4}
            pb={4}
            color="gray.500"
          >
            {member ? (
              <>
                {t("manageMemberModal.editMember")}{" "}
                <Text as="span" color="green.500">
                  {member.username}
                </Text>
              </>
            ) : (
              t("manageMemberModal.addMember")
            )}
          </Text>
          <Formik
            initialValues={{
              firstName: member?.firstName || "",
              lastName: member?.lastName || "",
              password: member?.password || "",
              accountNumber: member?.accountNumber || "",
              tel: member?.tel || "",
              bankName: member?.bankName || "",
              line: member?.line || "",
              isBonus: member?.isBonus || false,
              blockBonus: member?.blockBonus || false,
              depositAuto: member?.depositAuto || false,
              withdrawAuto: member?.withdrawAuto || false,
              affiliate: member?.affiliate || "",
              nationality: member?.nationality || "",
              username: member?.username || "",
              memberTagsId: member ? member.memberTagsId : "",
              memberLevelName: member ? member.memberLevelName : "",
              marketingId: member ? member.marketingId : "",
            }}
            onSubmit={(values, actions) => {
              if (!member) {
                values.username = values.tel;
              }
              handleSubmit(values, member, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="nationality" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.nationality && form.touched.nationality
                          }
                          isRequired
                        >
                          <FormLabel>
                            {t("manageMemberModal.nationality")}
                          </FormLabel>
                          <Select
                            borderRadius="33px"
                            height="42px"
                            {...field}
                            placeholder={t(
                              "manageMemberModal.selectNationality",
                            )}
                            fontSize="sm"
                          >
                            <option value="thai">ไทย</option>
                            <option value="foreign">ต่างชาติ</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem />
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="firstName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.firstName && form.touched.firstName
                          }
                          isRequired
                        >
                          <FormLabel>
                            {t("manageMemberModal.firstName")}{" "}
                            <Box
                              as="span"
                              color="green.500"
                              fontWeight="normal"
                            >
                              {t("manageMemberModal.accountNote")}
                            </Box>
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={t("manageMemberModal.firstName")}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="lastName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.lastName && form.touched.lastName
                          }
                          isRequired
                        >
                          <FormLabel>
                            {t("manageMemberModal.lastName")}{" "}
                            <Box
                              as="span"
                              color="green.500"
                              fontWeight="normal"
                            >
                              {t("manageMemberModal.accountNote")}
                            </Box>
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={t("manageMemberModal.lastName")}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="accountNumber" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.accountNumber &&
                            form.touched.accountNumber
                          }
                          isRequired
                        >
                          <FormLabel>
                            {t("manageMemberModal.bankAccountNumber")}
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={t(
                              "manageMemberModal.bankAccountNumber",
                            )}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankName && form.touched.bankName
                          }
                          isRequired
                        >
                          <FormLabel>{t("manageMemberModal.bank")}</FormLabel>
                          <Select
                            borderRadius="33px"
                            height="42px"
                            {...field}
                            placeholder={t("manageMemberModal.selectBank")}
                            fontSize="sm"
                          >
                            {banks.map((bank) => (
                              <option key={bank.id} value={bank.bankCode}>
                                {bank.bankName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="password" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          isRequired={!member}
                        >
                          <FormLabel>
                            {t("manageMemberModal.password")}
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={t("manageMemberModal.password")}
                            type="password"
                            isReadOnly={!!member}
                            backgroundColor={
                              member ? "rgba(55, 63, 72, 1)" : ""
                            }
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="tel" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.tel && form.touched.tel}
                          isRequired={!member}
                        >
                          <FormLabel>{t("manageMemberModal.phone")}</FormLabel>
                          <Input
                            {...field}
                            placeholder={t("manageMemberModal.phone")}
                            isReadOnly={!!member}
                            backgroundColor={
                              member ? "rgba(55, 63, 72, 1)" : ""
                            }
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="affiliate">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>
                            {t("manageMemberModal.affiliate")}
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder={t("manageMemberModal.affiliate")}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="marketingId">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>
                            {t("manageMemberModal.marketingAgent")}
                          </FormLabel>
                          <Select
                            borderRadius="33px"
                            height="42px"
                            {...field}
                            placeholder={t(
                              "manageMemberModal.selectMarketingAgent",
                            )}
                            fontSize="sm"
                          >
                            {marketingStaffData?.map((staff) => (
                              <option key={staff.id} value={staff.id}>
                                {staff.username}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="line">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>{t("manageMemberModal.lineId")}</FormLabel>
                          <Input
                            {...field}
                            placeholder={t("manageMemberModal.lineId")}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  {member && (
                    <>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="memberTagsId">
                          {({ field }) => (
                            <FormControl>
                              <FormLabel>
                                {t("manageMemberModal.tags")}
                              </FormLabel>
                              <Select
                                borderRadius="33px"
                                height="42px"
                                {...field}
                                placeholder={t("manageMemberModal.selectTag")}
                                fontSize="sm"
                              >
                                {memberTags.map((memberTag) => (
                                  <option
                                    key={memberTag.id}
                                    value={memberTag.id}
                                  >
                                    {memberTag.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="memberLevelName">
                          {({ field }) => (
                            <FormControl>
                              <FormLabel>
                                {t("manageMemberModal.memberLevel")}
                              </FormLabel>
                              <Input
                                {...field}
                                isReadOnly={!!member}
                                fontSize="sm"
                                backgroundColor={
                                  member ? "rgba(55, 63, 72, 1)" : ""
                                }
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem colSpan={2}>
                    <SimpleGrid columns={2} spacing={0}>
                      <Field name="isBonus">
                        {({ field }) => (
                          <Box
                            border="1px solid rgba(55, 63, 72, 1)"
                            borderTopLeftRadius="8px"
                            p={3}
                            borderBottom="none"
                            borderRight="none"
                            bg="rgba(30, 37, 44, 1)"
                          >
                            <FormControl
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FormLabel
                                htmlFor="is-bonus-enabled"
                                fontSize="16px"
                                mb="0"
                              >
                                {t("manageMemberModal.bonus")}
                              </FormLabel>
                              <Switch
                                id="is-bonus-enabled"
                                {...field}
                                isChecked={field.value}
                                ml={2}
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Field>

                      <Field name="blockBonus">
                        {({ field }) => (
                          <Box
                            border="1px solid rgba(55, 63, 72, 1)"
                            borderTopRightRadius="8px"
                            p={3}
                            borderBottom="none"
                            bg="rgba(30, 37, 44, 1)"
                          >
                            <FormControl
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FormLabel
                                htmlFor="is-no-bonus"
                                fontSize="16px"
                                mb="0"
                              >
                                {t("manageMemberModal.noBonus")}
                              </FormLabel>
                              <Switch
                                id="is-no-bonus"
                                {...field}
                                isChecked={field.value}
                                ml={2}
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Field>

                      <Field name="depositAuto">
                        {({ field }) => (
                          <Box
                            border="1px solid rgba(55, 63, 72, 1)"
                            borderBottomLeftRadius="8px"
                            p={3}
                            borderRight="none"
                            bg="rgba(30, 37, 44, 1)"
                          >
                            <FormControl
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FormLabel
                                htmlFor="is-auto-deposit"
                                fontSize="16px"
                                mb="0"
                              >
                                {t("manageMemberModal.autoDeposit")}
                              </FormLabel>
                              <Switch
                                id="is-auto-deposit"
                                {...field}
                                isChecked={field.value}
                                ml={2}
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Field>

                      <Field name="withdrawAuto">
                        {({ field }) => (
                          <Box
                            border="1px solid rgba(55, 63, 72, 1)"
                            borderBottomRightRadius="8px"
                            p={3}
                            bg="rgba(30, 37, 44, 1)"
                          >
                            <FormControl
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FormLabel
                                htmlFor="is-auto-withdraw"
                                fontSize="16px"
                                mb="0"
                              >
                                {t("manageMemberModal.autoWithdraw")}
                              </FormLabel>
                              <Switch
                                id="is-auto-withdraw"
                                {...field}
                                isChecked={field.value}
                                ml={2}
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Field>
                    </SimpleGrid>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mb={6}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    fontWeight="normal"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                    fontSize="14px"
                  >
                    {t("manageMemberModal.submit")}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageMemberModal;

import { Badge, Flex, Text } from "@chakra-ui/react";
import { getJackpotBackground } from "utils/CommonUtils";
import { formatDate } from "utils/DateformatUtils";

import { JackpotTypes } from "../../types";

export const jackpotReportColumnsData = (t) => [
  {
    Header: t("headers.type"),
    accessor: "type",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={getJackpotBackground(cell.value)}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="normal"
          color={cell.value === JackpotTypes.GRAND_JACKPOT ? "#1D2229" : "#fff"}
        >
          {cell.value.toUpperCase()}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: t("headers.username"),
    accessor: "username",
    disableSortBy: true,
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.amount"),
    accessor: "amount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: t("headers.beforeAmount"),
    accessor: "beforeAmount",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: t("headers.afterAmount"),
    accessor: "afterAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: t("headers.turnOver"),
    accessor: "turnOver",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: t("headers.admin"),
    accessor: "admin",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: t("headers.createdDate"),
    accessor: "createdDate",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => formatDate(value),
  },
];

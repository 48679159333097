import { callListApi, callapi } from "../../utils/APIUtils";
import {
  buildBasePayload,
  buildMemberTransferLogReportPayload,
} from "../../utils/PayloadUtils";

export const fetchMemberInfo = (payload) => {
  return callapi("/api/admin/webuser/getInfo", "POST", payload);
};

export const fetchMemberTransactionGraph = (payload) => {
  return callapi("/api/admin/webuser/getGraph", "POST", payload);
};

export const fetchBalanceAllProvider = (payload) => {
  return callapi("/api/coretransfer/getBalanceAllProvider", "POST", payload);
};

export const fetchMemberTop20DepositHistory = (payload) => {
  return callapi(
    "/api/admin/search/list/depositHistory/top20",
    "POST",
    payload,
  );
};

export const updateMemberTurnover = (payload) => {
  return callapi("/api/admin/clearturnover", "POST", payload);
};

export const updateMemberMinWithdraw = (payload) => {
  return callapi("/api/admin/updateminwithdraw", "POST", payload);
};

export const createWithdrawCredit = (payload) => {
  return callapi("/api/admin/withdraw", "POST", payload);
};

export const fetchMemberTransferLog = async (
  page,
  pageSize,
  search,
  sort = "A",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildMemberTransferLogReportPayload,
  );

  const response = await callListApi(
    "/api/admin/balancetransferlog",
    "POST",
    req,
  )
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.data.page.totalElements,
    results: data2.data.data,
  };

  return data;
};

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import {
  AdminDeposit,
  DepositBonusIcon,
  DepositIcon,
  MemberDepositIcon,
  MemberNotDepositIcon,
  MemberWithdrawIcon,
  NewMemberDeposit,
  NewMemberIcon,
  OldMemberDeposit,
  ProfitIcon,
  WithdrawIcon,
} from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import { Loader } from "components/Loader/Loader";
import ReportsCard from "components/ReportCards/ReportsCard";
import SimpleTable from "components/Tables/SimpleTable";
import TopTransactionCard from "components/TransactionCard/TopTransactionCard";
import { useDashboardSummary } from "hooks/business/dashboard/useDashboardSummary";
import { useLast20Deposit } from "hooks/business/dashboard/useLast20Deposit";
import { useLast20Withdraw } from "hooks/business/dashboard/useLast20Withdraw";
import { useProfitGraph } from "hooks/business/dashboard/useProfitGraph";
import { useTopDeposit } from "hooks/business/dashboard/useTopDeposit";
import { useTopWithdraw } from "hooks/business/dashboard/useTopWithdraw";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getReferenceDates } from "utils/DateformatUtils";
import {
  dashboardLastDeposit,
  dashboardLastWithdraw,
} from "variables/columns/dashboard/dashboardColumnsData";
import { APP_PROFILE, MARKETING_ROLE } from "variables/system";

import AbnormalReportCard from "./components/AbnormalReportCard";
import BigReportCard from "./components/BigReportCard";
import NormalReportsCard from "./components/NormalReportsCard";
import ResponsiveNormalReportsCard from "./components/ResonsiveNormalReportsCard";

const Dashboard = ({ pageName }) => {
  const { t } = useTranslation("dashboard");
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);

  const formattedStartDate = moment(selectedDates[0])
    .startOf("day")
    .format("yyyy-MM-DD HH:mm:ss");
  const formattedEndDate = moment(selectedDates[1])
    .endOf("day")
    .format("yyyy-MM-DD HH:mm:ss");

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { summary, isSummaryLoading } = useDashboardSummary(
    formattedStartDate,
    formattedEndDate,
    role,
  );

  const { lineChartData, isMemberLineDataLoading } = useProfitGraph(
    selectedDates,
    role,
  );

  const { lastDeposit, isLastDepositLoading } = useLast20Deposit(role);
  const { lastWithdraw, isLastWithdrawLoading } = useLast20Withdraw(role);

  const { topDeposit, isTopDepositLoading } = useTopDeposit(
    formattedStartDate,
    formattedEndDate,
    role,
  );
  const { topWithdraw, isTopWithdrawLoading } = useTopWithdraw(
    formattedStartDate,
    formattedEndDate,
    role,
  );

  return (
    <Flex flexDirection="column">
      <Flex
        justifyContent="space-between"
        alignItems="left"
        direction={{ base: "column", xl: "row" }}
      >
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <PageHeader pageName={pageName} t={t} />
        </Animated>
        <CustomRangePicker
          selectedDates={selectedDates}
          onDateChange={(dates) => {
            setSelectedDates(dates);
          }}
        />
      </Flex>
      {/* Normal Reports */}
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            xl: "repeat(2, 1fr)",
          }}
          gap={{ base: "12px", xl: "24px" }}
          mb={{ base: "12px", xl: "24px" }}
          mt={{ base: "22px", xl: "0px" }}
          display={role !== MARKETING_ROLE ? "grid" : "none"}
        >
          <BigReportCard
            style={{
              zIndex: -999,
            }}
            isDeposit={true}
            mainIcon={
              <Icon
                as={DepositIcon}
                w={{ base: "38px", xl: "38px" }}
                h={{ base: "38px", xl: "38px" }}
                color="#fff"
              />
            }
            mainAmount={summary?.deposit?.amount}
            mainCount={summary?.deposit?.count}
            firstAmount={summary?.depositMistake?.amount}
            firstCount={summary?.depositMistake?.count}
            secondAmount={summary?.depositWaitToApprove?.amount}
            secondCount={summary?.depositWaitToApprove?.count}
            isLoading={isSummaryLoading}
            isInteger={false}
            t={t}
          />
          <BigReportCard
            isDeposit={false}
            mainIcon={
              <Icon
                as={WithdrawIcon}
                w={{ base: "38px", xl: "38px" }}
                h={{ base: "38px", xl: "38px" }}
                color="#fff"
              />
            }
            mainAmount={summary?.withdraw?.amount}
            mainCount={summary?.withdraw?.count}
            firstAmount={summary?.withdrawMistake?.amount}
            firstCount={summary?.withdrawMistake?.count}
            secondAmount={summary?.withdrawWaitToApprove?.amount}
            secondCount={summary?.withdrawWaitToApprove?.count}
            isLoading={isSummaryLoading}
            isInteger={false}
            t={t}
          />
        </Grid>
      </Animated>
      {/* Abnormal Grid: Mobile version */}
      <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
          }}
          gap={{ base: "12px", xl: "24px" }}
          mb="12px"
          display={
            role !== MARKETING_ROLE ? { base: "grid", xl: "none" } : "none"
          }
        >
          <GridItem colSpan={1} rowSpan={{ base: 2, xl: 1 }}>
            <AbnormalReportCard
              mainText={"titles.newRegistration"}
              mainAmount={summary?.newRegister?.count}
              mainIcon={
                <Icon as={NewMemberIcon} w="135px" h="90px" color="#373F48" />
              }
              mainDescription={"descriptions.newMember"}
              isInteger={false}
              isLoading={isSummaryLoading}
              t={t}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <NormalReportsCard
              title={"titles.depositBonus"}
              icon={
                <Icon
                  as={DepositBonusIcon}
                  w={{ base: "20px", xl: "26px" }}
                  h={{ base: "20px", xl: "26px" }}
                  color="#8C9592"
                />
              }
              number={summary?.depositBonus?.amount}
              description={
                summary?.depositBonus?.count + " " + t("labels.entry")
              }
              isInteger={false}
              isLoading={isSummaryLoading}
              t={t}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <NormalReportsCard
              title={"titles.noDepositRegistration"}
              icon={
                <Icon
                  as={MemberNotDepositIcon}
                  w={{ base: "20px", xl: "26px" }}
                  h={{ base: "20px", xl: "26px" }}
                  color="#8C9592"
                />
              }
              number={summary?.newRegisterNoDeposit?.count}
              description={"descriptions.noDeposit"}
              isInteger={true}
              isLoading={isSummaryLoading}
              t={t}
            />
          </GridItem>
        </Grid>
        {/* Normal Grid: Remaining items */}
        <Grid
          display={role !== MARKETING_ROLE ? "grid" : "none"}
          templateColumns={{
            base: "repeat(3, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={{ base: "12px", xl: "24px" }}
          mb="24px"
        >
          <GridItem
            rowSpan={{ base: 1, xl: 2 }}
            display={{
              base: "none",
              xl: "grid",
            }}
          >
            <AbnormalReportCard
              mainText={"titles.newRegistration"}
              mainAmount={summary?.newRegister?.count}
              mainIcon={
                <Icon as={NewMemberIcon} w="135px" h="90px" color="#373F48" />
              }
              mainDescription={"descriptions.newMember"}
              isInteger={false}
              isLoading={isSummaryLoading}
              t={t}
            />
          </GridItem>
          <GridItem
            colSpan={1}
            display={{
              base: "none",
              xl: "grid",
            }}
          >
            <NormalReportsCard
              title={"titles.depositBonus"}
              icon={
                <Icon
                  as={DepositBonusIcon}
                  w={{ base: "20px", xl: "26px" }}
                  h={{ base: "20px", xl: "26px" }}
                  color="#8C9592"
                />
              }
              number={summary?.depositBonus?.amount}
              description={
                summary?.depositBonus?.count + " " + t("labels.entry")
              }
              isInteger={false}
              isLoading={isSummaryLoading}
              t={t}
            />
          </GridItem>
          <GridItem
            colSpan={1}
            display={{
              base: "none",
              xl: "grid",
            }}
          >
            <NormalReportsCard
              title={"titles.noDepositRegistration"}
              icon={
                <Icon
                  as={MemberNotDepositIcon}
                  w={{ base: "20px", xl: "26px" }}
                  h={{ base: "20px", xl: "26px" }}
                  color="#8C9592"
                />
              }
              number={summary?.newRegisterNoDeposit?.count}
              description={"descriptions.noDeposit"}
              isInteger={true}
              isLoading={isSummaryLoading}
              t={t}
            />
          </GridItem>
          {[
            {
              title: "titles.depositCount",
              icon: MemberDepositIcon,
              number: summary?.countDeposit?.count,
              description: "descriptions.uniqueDepositCount",
              isInteger: true,
            },
            {
              title: "titles.withdrawCount",
              icon: MemberWithdrawIcon,
              number: summary?.countWithdraw?.count,
              description: "descriptions.uniqueWithdrawCount",
              isInteger: true,
            },
            {
              title: "titles.profit",
              icon: ProfitIcon,
              number: summary?.profit?.amount,
              description: "descriptions.profit",
              isInteger: false,
            },
            {
              title: "titles.newMemberDeposit",
              icon: NewMemberDeposit,
              number: summary?.newRegisterDeposit?.amount,
              description: `${summary?.newRegisterDeposit?.count}  ${t(
                "labels.entry",
              )}`,
              isInteger: true,
            },
            {
              title: "titles.existingMemberDeposit",
              icon: OldMemberDeposit,
              number: summary?.oldUserDeposit?.amount,
              description: `${summary?.oldUserDeposit?.count}  ${t(
                "labels.entry",
              )}`,
              isInteger: true,
            },
            {
              title: "titles.adminManualCredit",
              icon: AdminDeposit,
              number: summary?.adminCreditDeposit?.amount,
              description: `${summary?.adminCreditDeposit?.count}  ${t(
                "labels.entry",
              )}`,
              isInteger: true,
            },
          ].map((card, index) => (
            <GridItem colSpan={1} key={index}>
              <ResponsiveNormalReportsCard
                title={card.title}
                icon={
                  <Icon
                    as={card.icon}
                    w={{ base: "20px", xl: "26px" }}
                    h={{ base: "20px", xl: "26px" }}
                    color="#8C9592"
                  />
                }
                number={card.number}
                description={card.description}
                isInteger={card.isInteger}
                isLoading={isSummaryLoading}
                t={t}
              />
            </GridItem>
          ))}
        </Grid>
      </Animated>
      {/* Marketing Reports */}
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={{ base: "12px", xl: "24px" }}
          mb="24px"
          display={role !== MARKETING_ROLE ? "none" : "grid"}
          pt={{ base: "20px", xl: "25px" }}
        >
          <GridItem colSpan={2}>
            <ReportsCard
              title={"titles.totalDeposit"}
              number={summary?.deposit?.amount}
              icon={
                <Icon
                  as={DepositIcon}
                  w={{ base: "20px", xl: "26px" }}
                  h={{ base: "20px", xl: "26px" }}
                  color="white"
                />
              }
              description={summary?.deposit?.count + " " + t("labels.entry")}
              isInteger={false}
              isLoading={isSummaryLoading}
              mode={"Deposit"}
              t={t}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <ReportsCard
              title={"titles.totalWithdraw"}
              icon={
                <Icon
                  as={WithdrawIcon}
                  w={{ base: "20px", xl: "26px" }}
                  h={{ base: "20px", xl: "26px" }}
                  color="white"
                />
              }
              number={summary?.withdraw?.amount}
              description={summary?.withdraw?.count + " " + t("labels.entry")}
              isInteger={false}
              isLoading={isSummaryLoading}
              mode={"Withdraw"}
              t={t}
            />
          </GridItem>
          <ReportsCard
            title={"titles.depositBonusAmount"}
            icon={
              <Icon
                as={DepositBonusIcon}
                w={{ base: "20px", xl: "30px" }}
                h={{ base: "20px", xl: "30px" }}
                color="#8C9592"
              />
            }
            number={summary?.depositBonus?.amount}
            description={summary?.depositBonus?.count + " " + t("labels.entry")}
            isInteger={false}
            isLoading={isSummaryLoading}
            t={t}
          />
          <ReportsCard
            title={"titles.profit"}
            icon={
              <Icon
                as={ProfitIcon}
                w={{ base: "20px", xl: "30px" }}
                h={{ base: "20px", xl: "30px" }}
                color="#8C9592"
              />
            }
            number={summary?.profit?.amount}
            description={"descriptions.profit"}
            isInteger={false}
            isLoading={isSummaryLoading}
            t={t}
          />
          <ReportsCard
            title={"titles.newRegistration"}
            number={summary?.newRegister.count}
            icon={
              <Icon
                as={NewMemberIcon}
                w={{ base: "20px", xl: "30px" }}
                h={{ base: "20px", xl: "30px" }}
                color="#8C9592"
              />
            }
            description={"descriptions.newMember"}
            isInteger={true}
            isLoading={isSummaryLoading}
            t={t}
          />
          <ReportsCard
            title={"titles.noDepositRegistration"}
            icon={
              <Icon
                as={MemberNotDepositIcon}
                w={{ base: "20px", xl: "30px" }}
                h={{ base: "20px", xl: "30px" }}
                color="#8C9592"
              />
            }
            number={summary?.newRegisterNoDeposit?.count}
            description={"descriptions.noDeposit"}
            isInteger={true}
            isLoading={isSummaryLoading}
            t={t}
          />
          <ReportsCard
            title={"titles.depositCount"}
            number={summary?.countDeposit?.count}
            icon={
              <Icon
                as={MemberDepositIcon}
                w={{ base: "20px", xl: "30px" }}
                h={{ base: "20px", xl: "30px" }}
                color="#8C9592"
              />
            }
            description={"descriptions.uniqueWithdrawCount"}
            isInteger={true}
            isLoading={isSummaryLoading}
            t={t}
          />
          <ReportsCard
            title={"titles.withdrawCount"}
            icon={
              <Icon
                as={MemberWithdrawIcon}
                w={{ base: "20px", xl: "30px" }}
                h={{ base: "20px", xl: "30px" }}
                color="#8C9592"
              />
            }
            number={summary?.countWithdraw?.count}
            description={"descriptions.uniqueWithdrawCount"}
            isInteger={true}
            isLoading={isSummaryLoading}
            t={t}
          />
        </Grid>
      </Animated>
      <Card mb="26px">
        <Grid
          templateColumns={{ sm: "1fr", xl: "1.8fr 1.2fr" }}
          gap={{ sm: "0px", xl: "24px" }}
          minH={{ sm: "375px", xl: "475px" }}
        >
          {isMemberLineDataLoading ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              height="400px"
              w="100%"
            >
              <Loader />
            </Flex>
          ) : (
            <Animated animation="fadeInUp" duration="1.0s" delay="0.1s">
              <Text fontSize="lg" fontWeight="bold" color="#fff">
                {t("labels.depositWithdrawReport")}
              </Text>
              <Text fontSize="10px" color="#8C9592" mt="4px">
                {selectedDates[0]?.toLocaleDateString("en-GB")}
                {" - "}
                {selectedDates[1]?.toLocaleDateString("en-GB")}
              </Text>
              <LineChart
                key={JSON.stringify(lineChartData?.memberLineData)}
                chartData={lineChartData?.memberLineData}
                chartOptions={lineChartData?.chartOptions}
              />
            </Animated>
          )}
          <Animated animation="fadeInUp" duration="1.0s" delay="0.1s">
            <Tabs isFitted variant="enclosed">
              <TabList>
                <Tab
                  _selected={{
                    color: "#fff",
                    background:
                      "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                    border: "none",
                  }}
                  backgroundColor="#373F48"
                  fontSize={{ base: "sm", xl: "md" }}
                  _focus={{ boxShadow: "none" }}
                  marginLeft={{ base: "0px", xl: "95px" }}
                  marginRight="5px"
                  borderTopRadius="8px"
                >
                  {t("labels.top5Deposits")}
                </Tab>
                <Tab
                  _selected={{
                    color: "#fff",
                    background:
                      "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                    border: "none",
                  }}
                  backgroundColor="#373F48"
                  fontSize={{ base: "sm", xl: "md" }}
                  _focus={{ boxShadow: "none" }}
                  marginRight={{ base: "0px", xl: "95px" }}
                  marginLeft="5px"
                  borderTopRadius="8px"
                >
                  {t("labels.top5Withdraws")}
                </Tab>
              </TabList>
              {isTopWithdrawLoading && isTopDepositLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Loader />
                </Flex>
              ) : (
                <TabPanels>
                  <TabPanel px={0}>
                    {topDeposit?.slice(0, 5).map((data, index) => (
                      <Box key={index} mt={index === 0 ? 0 : 2}>
                        <TopTransactionCard
                          index={index + 1}
                          username={data.username}
                          number={data.deposit}
                          isDeposit={true}
                        />
                      </Box>
                    ))}
                  </TabPanel>
                  <TabPanel px={0}>
                    {topWithdraw?.slice(0, 5).map((data, index) => (
                      <Box key={index} mt={index === 0 ? 0 : 2}>
                        <TopTransactionCard
                          index={index + 1}
                          username={data.username}
                          number={data.withdraw}
                          isDeposit={false}
                        />
                      </Box>
                    ))}
                  </TabPanel>
                </TabPanels>
              )}
            </Tabs>
          </Animated>
        </Grid>
      </Card>

      <Grid templateColumns={{ xl: "repeat(2, 1fr)" }} gap="24px">
        <Card>
          <CardHeader>
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                {t("labels.latestDeposit")}
              </Text>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="1.2s" delay="0.1s">
            <CardBody h="100%">
              <Box w="100%" h="100%">
                <SimpleTable
                  tableData={lastDeposit}
                  columnsData={dashboardLastDeposit(t)}
                  rowSize={10}
                  isLoading={isLastDepositLoading}
                />
              </Box>
            </CardBody>
          </Animated>
        </Card>

        <Card>
          <CardHeader pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                {t("labels.latestWithdraw")}
              </Text>
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="1.2s" delay="0.1s">
            <CardBody h="100%">
              <Box w="100%" h="100%">
                <SimpleTable
                  tableData={lastWithdraw}
                  columnsData={dashboardLastWithdraw(t)}
                  rowSize={10}
                  isLoading={isLastWithdrawLoading}
                />
              </Box>
            </CardBody>
          </Animated>
        </Card>
      </Grid>
    </Flex>
  );
};

export default Dashboard;

import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useFetchMemberHaveTurnover } from "hooks/business/member-report/useFetchMemberHaveTurnover";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { memberTurnoverReportColumnsData } from "variables/columns/member-report/memberTurnoverReportColumnsData";
import { QueryKeys } from "variables/queryKeys";

import ClearTurnoverModal from "../../shared/member-profile/components/ClearTurnoverModal";

const MemberTurnoverReport = ({ pageName }) => {
  const { t } = useTranslation("memberTurnoverReport");
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";

  const { fetchData } = useFetchMemberHaveTurnover();
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const [selectedMember, setSelectedMember] = useState(null);

  const [modalState, setModalState] = useState({
    clearTurnoverModalOpen: false,
  });

  const handleModalOpen = (modal, user = null) => {
    console.log(user);
    setSelectedMember(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMember(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} t={t} />
      </Animated>
      <Card>
        <CardHeader>
          <Flex
            direction={{ base: "column", xl: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "14px", xl: "0px" }}
            >
              {t("memberTurnover")}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTableRemote
              query={QueryKeys.MEMBER_TURNOVER_REPORT}
              columnsData={memberTurnoverReportColumnsData(handleModalOpen, t)}
              fetchData={fetchData}
              search={search}
              setSearch={setSearch}
              showExport={null}
              filename={pageName}
            />
          </CardBody>
        </Animated>
      </Card>
      <ClearTurnoverModal
        isOpen={modalState.clearTurnoverModalOpen}
        onClose={() => handleModalClose("clearTurnoverModal")}
        username={selectedMember?.username}
        onSuccess={() =>
          queryClient.invalidateQueries(QueryKeys.MEMBER_TURNOVER_REPORT)
        }
      />
    </Flex>
  );
};

export default MemberTurnoverReport;

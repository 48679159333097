import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField } from "utils/CommonUtils";

import { TransactionReceipt } from "./TransactionReceipt";

const ApproveWithdrawBlockAutoTransactionModal = ({
  isOpen,
  onClose,
  transaction,
  handleSubmit,
  t,
}) => {
  return (
    <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              {t("approveWithdraw.title")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              withdrawId: transaction?.id,
              status: "SUCCESS",
              reason: "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Text
                  fontSize="16px"
                  fontWeight="bold"
                  pt={2}
                  pb={4}
                  color="green.500"
                >
                  {t("approveWithdraw.title")}
                </Text>
                <TransactionReceipt transaction={transaction} />
                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel>{t("approveWithdraw.reasonLabel")}</FormLabel>
                      <Input
                        {...field}
                        placeholder={t("approveWithdraw.reasonPlaceholder")}
                        fontSize="sm"
                      />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    type="submit"
                    fontWeight="normal"
                    fontSize="14px"
                    isLoading={props.isSubmitting}
                  >
                    {t("approveWithdraw.submitButton")}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ApproveWithdrawBlockAutoTransactionModal;

export const selectStyles = {
  components: {
    Select: {
      baseStyle: {
        field: {
          backgroundColor: "rgba(30, 37, 44, 1)",
          borderRadius: "33px",
          border: "1px solid",
          borderColor: "rgba(55, 63, 72, 1)",
          _hover: {
            borderColor: "gray.500",
          },
          _focus: {
            borderColor: "gray.500",
            boxShadow: "none",
          },
        },
      },
      variants: {
        outline: {
          field: {
            backgroundColor: "rgba(30, 37, 44, 1)",
            borderRadius: "33px",
            border: "1px solid",
            borderColor: "rgba(55, 63, 72, 1)",
            _hover: {
              borderColor: "gray.500",
            },
            _focus: {
              borderColor: "gray.500",
              boxShadow: "none",
            },
          },
        },
      },
      defaultProps: {
        variant: "outline",
      },
    },
  },
};

import { Box, useStyleConfig } from "@chakra-ui/react";
import { forwardRef } from "react";

const MainPanel = forwardRef((props, ref) => {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("MainPanel", { variant });

  return (
    <Box ref={ref} __css={styles} {...rest}>
      {children}
    </Box>
  );
});

MainPanel.displayName = "MainPanel";

export default MainPanel;

import {
  Badge,
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Switch,
  Text,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "components/Icons/Icons";
import React from "react";

import {
  getBonusType,
  getTypeGame,
  getTypePromotion,
} from "../../../../utils/CommonUtils";

const PromotionCard = ({ promotion, onEdit, onDelete, t }) => {
  return (
    <Box borderRadius="10px" overflow="hidden" p={6} bg="#1E252C">
      <Image
        src={promotion.urlImage || "https://via.placeholder.com/1040"}
        alt={`${promotion.name} image`}
        width="100%"
        height="150px"
        objectFit="cover"
        borderRadius="10px"
      />

      <Box pt={4} pb={2}>
        <Flex justify="space-between" align="center">
          <Text fontSize="16px" fontWeight="bold" color="#fff">
            {promotion.name}
          </Text>
          <Badge
            w="95px"
            h="26px"
            alignItems="center"
            justifyContent="center"
            display="flex"
            borderRadius="28px"
            bg="#1D2229"
            border="1px solid #31AA93"
          >
            {t(getBonusType(promotion.typeBonus), { ns: "shared" })}
          </Badge>
        </Flex>
        <Text fontSize="14px" mt={2}>
          {promotion.description}
        </Text>
        <Flex wrap="wrap" mt={2}>
          <Badge
            mr={2}
            mb={2}
            h="26px"
            w="96px"
            borderRadius="28px"
            alignItems="center"
            justifyContent="center"
            display="flex"
            bg="#373F48"
          >
            {t(getTypePromotion(promotion.typePromotion))}
          </Badge>
          <Badge
            mr={2}
            mb={2}
            h="26px"
            w="96px"
            alignItems="center"
            justifyContent="center"
            display="flex"
            bg="#373F48"
            borderRadius="28px"
          >
            {t(getTypeGame(promotion.typeGame))}
          </Badge>
        </Flex>
        <Box
          border="1px solid #373F48"
          borderRadius="6px"
          px={4}
          bg="#252d35"
          mt={2}
        >
          <Flex
            justify="space-between"
            borderBottom="1px solid #373F48"
            p={3}
            mx={-4}
          >
            <Text fontWeight="medium" fontSize="sm">
              {t("form.minTopup")}
            </Text>
            <Text fontSize="sm">{promotion.minTopup}</Text>
          </Flex>
          <Flex
            justify="space-between"
            borderBottom="1px solid #373F48"
            p={3}
            mx={-4}
          >
            <Text fontWeight="medium" fontSize="sm">
              {t("form.maxTopup")}
            </Text>
            <Text fontSize="sm">{promotion.maxTopup}</Text>
          </Flex>
          <Flex
            justify="space-between"
            borderBottom="1px solid #373F48"
            p={3}
            mx={-4}
          >
            <Text fontWeight="medium" fontSize="sm">
              {t("form.maxBonus")}
            </Text>
            <Text fontSize="sm">{promotion.maxBonus}</Text>
          </Flex>
          <Flex
            justify="space-between"
            borderBottom="1px solid #373F48"
            p={3}
            mx={-4}
          >
            <Text fontWeight="medium" fontSize="sm">
              {t("form.maxReceiveBonus")}
            </Text>
            <Text fontSize="sm">{promotion.maxReceiveBonus}</Text>
          </Flex>
          <Flex
            justify="space-between"
            borderBottom="1px solid #373F48"
            p={3}
            mx={-4}
          >
            <Text fontWeight="medium" fontSize="sm">
              {t("form.turnOver")}
            </Text>
            <Text fontSize="sm">{promotion.turnOver}</Text>
          </Flex>
          <Flex
            justify="space-between"
            borderBottom="1px solid #373F48"
            p={3}
            mx={-4}
          >
            <Text fontWeight="medium" fontSize="sm">
              {t("form.maxWithdraw")}
            </Text>
            <Text fontSize="sm">{promotion.maxWithdraw}</Text>
          </Flex>
          <Flex justify="space-between" p={3} mx={-4}>
            <Text fontWeight="medium" fontSize="sm">
              {t("form.activeStatus")}
            </Text>
            <Switch isChecked={promotion.active} readOnly />
          </Flex>
        </Box>
        <Flex justify="space-between" align="center">
          <Text fontSize="14px">{t("form.manage")}</Text>
          <Flex mt={4}>
            <IconButton
              icon={<Icon as={EditIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              variant="solid"
              color="#fff"
              bg="green.500"
              size="sm"
              onClick={onEdit}
              mr={2}
            />
            <IconButton
              icon={<Icon as={DeleteIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              color="#fff"
              bg="red.500"
              variant="solid"
              onClick={onDelete}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default PromotionCard;
